<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>
<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M11.0508 13.9453V15H8.01953V13.9453H11.0508ZM8.50781 9.3125V15H7.13672V9.3125H8.50781ZM10.6602 11.5703V12.5898H8.01953V11.5703H10.6602ZM11.0625 9.3125V10.3711H8.01953V9.3125H11.0625Z"
            :fill="props.color"
        />
        <path
            d="M16.8633 9.3125V15H15.4961L13.4844 11.4609V15H12.1133V9.3125H13.4844L15.4961 12.8516V9.3125H16.8633Z"
            :fill="props.color"
        />
    </svg>
</template>

<style scoped></style>
