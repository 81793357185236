<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="9" stroke="#A1C413" stroke-width="2" />
        <path
            d="M10.1836 13.0742H11.5508C11.5326 13.4701 11.4258 13.819 11.2305 14.1211C11.0352 14.4232 10.7643 14.6589 10.418 14.8281C10.0742 14.9948 9.66797 15.0781 9.19922 15.0781C8.81901 15.0781 8.47917 15.0143 8.17969 14.8867C7.88281 14.7591 7.63021 14.5755 7.42188 14.3359C7.21615 14.0964 7.05859 13.806 6.94922 13.4648C6.84245 13.1237 6.78906 12.7396 6.78906 12.3125V12.0039C6.78906 11.5768 6.84505 11.1927 6.95703 10.8516C7.07161 10.5078 7.23307 10.2161 7.44141 9.97656C7.65234 9.73438 7.90495 9.54948 8.19922 9.42188C8.49349 9.29427 8.82031 9.23047 9.17969 9.23047C9.67448 9.23047 10.0924 9.31901 10.4336 9.49609C10.7747 9.67318 11.0391 9.91667 11.2266 10.2266C11.4141 10.5339 11.5247 10.8841 11.5586 11.2773H10.1914C10.1862 11.0638 10.1497 10.8854 10.082 10.7422C10.0143 10.5964 9.90755 10.487 9.76172 10.4141C9.61589 10.3411 9.42188 10.3047 9.17969 10.3047C9.01042 10.3047 8.86328 10.3359 8.73828 10.3984C8.61328 10.4609 8.50911 10.5599 8.42578 10.6953C8.34245 10.8307 8.27995 11.0065 8.23828 11.2227C8.19922 11.4362 8.17969 11.694 8.17969 11.9961V12.3125C8.17969 12.6146 8.19792 12.8724 8.23438 13.0859C8.27083 13.2995 8.32943 13.474 8.41016 13.6094C8.49089 13.7448 8.59505 13.8451 8.72266 13.9102C8.85286 13.9727 9.01172 14.0039 9.19922 14.0039C9.40755 14.0039 9.58333 13.9714 9.72656 13.9062C9.86979 13.8385 9.98047 13.737 10.0586 13.6016C10.1367 13.4635 10.1784 13.2878 10.1836 13.0742Z"
            fill="#A1C413"
        />
        <path
            d="M12.6992 9.3125H14.9375C15.375 9.3125 15.7552 9.3776 16.0781 9.50781C16.401 9.63802 16.6497 9.83073 16.8242 10.0859C17.0013 10.3411 17.0898 10.6562 17.0898 11.0312C17.0898 11.3568 17.0391 11.6289 16.9375 11.8477C16.8359 12.0664 16.694 12.2474 16.5117 12.3906C16.332 12.5312 16.1224 12.6471 15.8828 12.7383L15.4336 12.9922H13.5547L13.5469 11.9336H14.9375C15.112 11.9336 15.2565 11.9023 15.3711 11.8398C15.4857 11.7773 15.5716 11.6888 15.6289 11.5742C15.6888 11.457 15.7188 11.3177 15.7188 11.1562C15.7188 10.9922 15.6888 10.8516 15.6289 10.7344C15.569 10.6172 15.4805 10.5273 15.3633 10.4648C15.2487 10.4023 15.1068 10.3711 14.9375 10.3711H14.0703V15H12.6992V9.3125ZM15.8125 15L14.5547 12.4844L16.0078 12.4766L17.2812 14.9414V15H15.8125Z"
            fill="#A1C413"
        />
    </svg>
</template>
