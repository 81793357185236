<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_382)">
            <path
                d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                fill="#ADBCAA"
            />
            <path
                d="M2.21875 8.30469H4.7461"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.81641 8.30469H8.34375"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.0586 9.21094V8.28125"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.0586 8.10547C12.1914 8.10547 12.3008 8 12.3008 7.86719C12.3008 7.73437 12.1914 7.62891 12.0586 7.62891C11.9258 7.62891 11.8203 7.73437 11.8203 7.86719C11.8203 8 11.9258 8.10547 12.0586 8.10547Z"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.8555 13.7891H11.2656V13.1484H12.8555V13.7891Z"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M11.0156 18.0117H13.1016V15.1875H11.0156V18.0117Z" fill="#3F674D" />
            <path
                d="M13.1016 15.1875H11.0156V18.0117H13.1016V15.1875Z"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.0586 16.5742C12.2031 16.5742 12.3164 16.457 12.3164 16.3164C12.3164 16.1719 12.2031 16.0586 12.0586 16.0586C11.918 16.0586 11.8008 16.1719 11.8008 16.3164C11.8008 16.457 11.918 16.5742 12.0586 16.5742Z"
                stroke="#ADBCAA"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.3828 15.1367H11.7383V13.8203H12.3828V15.1367Z"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.6211 12.8437C9.83203 12.9219 7.42969 13.3945 7.42969 16.2695V18.1328C7.42969 18.1328 7.42969 18.2539 7.45312 18.4531"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.5391 12.8437C14.3281 12.9219 16.7305 13.3945 16.7305 16.2695V18.1328C16.7305 18.1328 16.7305 18.2539 16.707 18.4531"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M21.8672 8.30469H19.3398"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M18.3398 8.30469H15.8086"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M19.6094 11.4727V9.68359C19.6094 9.16406 19.0859 9.16406 19.0859 9.16406H18.6055C18.082 9.16406 18.082 9.68359 18.082 9.68359V10.3945H13.8164V10.3203C13.8164 10.3203 13.8164 9.33984 12.832 9.33984H11.2852C11.2852 9.33984 10.3008 9.33984 10.3008 10.3203V10.3945H6.00391V9.68359C6.00391 9.68359 6.00391 9.16406 5.48047 9.16406H5C5 9.16406 4.48047 9.16406 4.48047 9.68359V11.4727C4.48047 11.4727 4.48047 11.9922 5 11.9922H5.48047C5.48047 11.9922 6.00391 11.9922 6.00391 11.4727V11.4961C6.05469 11.5 6.11328 11.5039 6.17187 11.5078L10.3008 11.8242V12.1133C10.3008 12.1133 10.3008 13.0977 11.2852 13.0977H12.832C12.832 13.0977 13.8164 13.0977 13.8164 12.1133V11.8203L17.9141 11.5078C17.9727 11.5039 18.0313 11.5 18.082 11.4961V11.4727C18.082 11.9922 18.6055 11.9922 18.6055 11.9922H19.0859C19.6094 11.9922 19.6094 11.4727 19.6094 11.4727Z"
                fill="#3F674D"
            />
            <path
                d="M19.6094 11.4727V9.68359C19.6094 9.16406 19.0859 9.16406 19.0859 9.16406H18.6055C18.082 9.16406 18.082 9.68359 18.082 9.68359V10.3945H13.8164V10.3203C13.8164 10.3203 13.8164 9.33984 12.832 9.33984H11.2851C11.2851 9.33984 10.3008 9.33984 10.3008 10.3203V10.3945H6.00388V9.68359C6.00388 9.68359 6.00388 9.16406 5.48044 9.16406H4.99997C4.99997 9.16406 4.48044 9.16406 4.48044 9.68359V11.4727C4.48044 11.4727 4.48044 11.9922 4.99997 11.9922H5.48044C5.48044 11.9922 6.00388 11.9922 6.00388 11.4727V11.4961C6.05466 11.5 6.11325 11.5039 6.17185 11.5078L10.3008 11.8242V12.1133C10.3008 12.1133 10.3008 13.0977 11.2851 13.0977H12.832C12.832 13.0977 13.8164 13.0977 13.8164 12.1133V11.8203L17.914 11.5078C17.9726 11.5039 18.0312 11.5 18.082 11.4961V11.4727C18.082 11.9922 18.6055 11.9922 18.6055 11.9922H19.0859C19.6094 11.9922 19.6094 11.4727 19.6094 11.4727Z"
                stroke="#3F674D"
                stroke-width="0.489796"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_382">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
