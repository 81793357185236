<template>
    <svg
        width="140"
        height="140"
        viewBox="0 0 140 140"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle opacity="0.1" cx="70" cy="70" r="70" fill="#808080" />
        <circle cx="70" cy="70" r="60" fill="#808080" />
        <g clip-path="url(#clip0_4343_92631)">
            <path
                d="M95.1301 92.614L91.6121 76.78V69C91.6121 67.9391 91.1907 66.9217 90.4406 66.1716C89.6904 65.4214 88.673 65 87.6121 65H76.6121V46C76.6121 44.1435 75.8746 42.363 74.5619 41.0503C73.2491 39.7375 71.4686 39 69.6121 39C67.7556 39 65.9751 39.7375 64.6624 41.0503C63.3496 42.363 62.6121 44.1435 62.6121 46V65H51.6121C50.5513 65 49.5338 65.4214 48.7837 66.1716C48.0336 66.9217 47.6121 67.9391 47.6121 69V76.78L44.0941 92.613C43.9683 93.1416 43.9635 93.6918 44.0801 94.2225C44.1966 94.7532 44.4315 95.2507 44.7671 95.678C45.1112 96.0983 45.5458 96.4355 46.0384 96.6644C46.531 96.8933 47.069 97.008 47.6121 97H91.6121C92.1561 97.0134 92.6958 96.9011 93.1892 96.6718C93.6826 96.4425 94.1165 96.1023 94.4569 95.6779C94.7974 95.2535 95.0352 94.7561 95.152 94.2247C95.2688 93.6933 95.2613 93.1421 95.1301 92.614ZM66.6121 46C66.6121 45.2044 66.9282 44.4413 67.4908 43.8787C68.0534 43.3161 68.8165 43 69.6121 43C70.4078 43 71.1708 43.3161 71.7334 43.8787C72.2961 44.4413 72.6121 45.2044 72.6121 46V65H66.6121V46ZM51.6121 69H87.6121V75H51.6121V69ZM82.6121 93V87C82.6121 86.4696 82.4014 85.9609 82.0263 85.5858C81.6513 85.2107 81.1426 85 80.6121 85C80.0817 85 79.573 85.2107 79.1979 85.5858C78.8228 85.9609 78.6121 86.4696 78.6121 87V93H71.6121V87C71.6121 86.4696 71.4014 85.9609 71.0263 85.5858C70.6513 85.2107 70.1426 85 69.6121 85C69.0817 85 68.573 85.2107 68.1979 85.5858C67.8228 85.9609 67.6121 86.4696 67.6121 87V93H60.6121V87C60.6121 86.4696 60.4014 85.9609 60.0263 85.5858C59.6513 85.2107 59.1426 85 58.6121 85C58.0817 85 57.573 85.2107 57.1979 85.5858C56.8228 85.9609 56.6121 86.4696 56.6121 87V93H48.1051L51.2111 79H88.0111L91.1211 93H82.6121Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4343_92631">
                <rect width="51.224" height="58" fill="white" transform="translate(44 39)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
