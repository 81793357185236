<script setup lang="ts">
import { ref, onMounted, watchEffect } from 'vue'
import { useWindowSize } from '@vueuse/core'

const svgWidth = ref(24)
const svgHeight = ref(24)
const { width } = useWindowSize()
const isMobile = ref(479)

onMounted(() => {
    watchEffect(() => {
        if (width.value < isMobile.value) {
            svgWidth.value = 20
            svgHeight.value = 20
        }
        if (width.value > isMobile.value) {
            svgWidth.value = 24
            svgHeight.value = 24
        }
    })
})
</script>

<template>
    <svg
        :width="svgWidth"
        :height="svgHeight"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_2384_27970)">
            <path
                d="M18 19H4V5H13V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H18C19.1 21 20 20.1 20 19V10H18V19ZM10.21 15.83L8.25 13.47L5.5 17H16.5L12.96 12.29L10.21 15.83ZM20 3V0H18V3H15C15.01 3.01 15 5 15 5H18V7.99C18.01 8 20 7.99 20 7.99V5H23V3H20Z"
                fill="#798877"
            />
        </g>
        <defs>
            <clipPath id="clip0_2384_27970">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
