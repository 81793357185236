<script setup lang="ts">
export interface Props {
    strokeColor?: string
}

const props = withDefaults(defineProps<Props>(), { strokeColor: '#022D05' })
</script>

<template>
    <svg
        class="transition-default"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            class="transition-default rotate"
            d="M17.7482 9.74821L12 15.4964L6.25179 9.74822"
            :stroke="props.strokeColor"
            stroke-width="1.5"
        />
    </svg>
</template>

<style scoped lang="scss">
.transition-default {
    transition: $transition;
}
</style>
