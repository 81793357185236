<template>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.875 17.5H11.375C11.375 18.4625 10.5875 19.25 9.625 19.25C8.6625 19.25 7.875 18.4625 7.875 17.5ZM6.125 16.625H13.125L13.125 14.875L6.125 14.875L6.125 16.625ZM16.1875 8.3125C16.1875 11.655 13.86 13.44 12.8887 14L6.36125 14C5.39 13.44 3.0625 11.655 3.0625 8.3125C3.0625 4.69 6.0025 1.75 9.625 1.75C13.2475 1.75 16.1875 4.69 16.1875 8.3125ZM14.4375 8.3125C14.4375 5.66125 12.2762 3.5 9.625 3.5C6.97375 3.5 4.8125 5.66125 4.8125 8.3125C4.8125 10.4738 6.11625 11.7162 6.86875 12.25L12.3812 12.25C13.1337 11.7162 14.4375 10.4738 14.4375 8.3125ZM18.6988 6.44875L17.5 7L18.6988 7.55125L19.25 8.75L19.8012 7.55125L21 7L19.8012 6.44875L19.25 5.25L18.6988 6.44875ZM17.5 5.25L18.3225 3.4475L20.125 2.625L18.3225 1.8025L17.5 0L16.6775 1.8025L14.875 2.625L16.6775 3.4475L17.5 5.25Z"
            fill="#798877"
        />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
