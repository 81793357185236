<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
    }
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 31,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M10.6191 15.108C10.1211 15.108 9.65911 15 9.23311 14.784C8.81311 14.562 8.47411 14.229 8.21611 13.785C7.95811 13.341 7.82911 12.786 7.82911 12.12C7.82911 11.628 7.90411 11.193 8.05411 10.815C8.21011 10.431 8.42011 10.107 8.68411 9.843C8.95411 9.579 9.25711 9.381 9.59311 9.249C9.93511 9.111 10.2891 9.042 10.6551 9.042C11.0451 9.042 11.3931 9.12 11.6991 9.276C12.0111 9.426 12.2691 9.606 12.4731 9.816L11.6631 10.806C11.5191 10.68 11.3691 10.578 11.2131 10.5C11.0631 10.416 10.8891 10.374 10.6911 10.374C10.4631 10.374 10.2501 10.443 10.0521 10.581C9.86011 10.713 9.70411 10.905 9.58411 11.157C9.47011 11.409 9.41311 11.712 9.41311 12.066C9.41311 12.612 9.52711 13.035 9.75511 13.335C9.98911 13.629 10.2951 13.776 10.6731 13.776C10.9011 13.776 11.1021 13.725 11.2761 13.623C11.4561 13.521 11.6091 13.404 11.7351 13.272L12.5451 14.244C12.2991 14.532 12.0111 14.748 11.6811 14.892C11.3511 15.036 10.9971 15.108 10.6191 15.108ZM13.3005 15V13.758H14.5245V10.806H13.4445V9.852C13.7685 9.786 14.0445 9.711 14.2725 9.627C14.5005 9.537 14.7225 9.426 14.9385 9.294H16.0725V13.758H17.0985V15H13.3005Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
