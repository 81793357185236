import { db, fbAuth } from '../plugins/auth.plugin'
import { i18n } from '../i18n'

export function getUserRef() {
    const uid = fbAuth?.currentUser?.uid
    const userRef = db.collection('users').doc(uid)

    return userRef
}

export function getUserId() {
    const uid = fbAuth?.currentUser?.uid
    return uid
}

export function getUser() {
    return fbAuth?.currentUser
}

export function getAuth() {
    return fbAuth
}

export async function getUserToken() {
    const token = (await fbAuth?.currentUser?.getIdToken()) ?? undefined
    return token
}

export function getLocalizationKeyFromErrorCode(code) {
    switch (code) {
        case 'auth/user-not-found':
            return 'user_not_found'
        case 'auth/email-already-in-use':
            return 'email_in_use'
        case 'auth/invalid-credential':
            return 'enter_valid_email'
        case 'auth/wrong-password':
        case 'auth/invalid-password':
            return 'invalid_log_in_credentials'
        default:
            return i18n.global.t('oops_message')
    }
}
