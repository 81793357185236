<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16.0003" cy="15.9993" r="12.3333" stroke="#798877" stroke-width="2" />
        <path
            d="M14.7344 18.5931V19.9993H10.6927V18.5931H14.7344ZM11.3438 12.416V19.9993H9.51562V12.416H11.3438ZM14.2135 15.4264V16.7858H10.6927V15.4264H14.2135ZM14.75 12.416V13.8275H10.6927V12.416H14.75Z"
            fill="#798877"
        />
        <path
            d="M22.4844 12.416V19.9993H20.6615L17.9792 15.2806V19.9993H16.151V12.416H17.9792L20.6615 17.1348V12.416H22.4844Z"
            fill="#798877"
        />
    </svg>
</template>
