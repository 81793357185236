<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16.0003" cy="15.9993" r="12.3333" stroke="#798877" stroke-width="2" />
        <path
            d="M12.042 18.2129L13.6201 12.416H15.667L13.1097 19.9993H11.7868L12.042 18.2129ZM10.6097 12.416L12.1878 18.2129L12.4535 19.9993H11.1149L8.57324 12.416H10.6097Z"
            fill="#798877"
        />
        <path
            d="M21.0055 12.416H22.8337V17.3691C22.8337 17.9594 22.7104 18.4594 22.4639 18.8691C22.2173 19.2754 21.8719 19.5827 21.4274 19.791C20.983 19.9993 20.4604 20.1035 19.8597 20.1035C19.266 20.1035 18.7434 19.9993 18.292 19.791C17.8441 19.5827 17.4934 19.2754 17.2399 18.8691C16.9899 18.4594 16.8649 17.9594 16.8649 17.3691V12.416H18.6982V17.3691C18.6982 17.6816 18.7434 17.9368 18.8337 18.1348C18.9239 18.3292 19.0559 18.4716 19.2295 18.5618C19.4031 18.6521 19.6132 18.6973 19.8597 18.6973C20.1097 18.6973 20.3198 18.6521 20.4899 18.5618C20.66 18.4716 20.7885 18.3292 20.8753 18.1348C20.9621 17.9368 21.0055 17.6816 21.0055 17.3691V12.416Z"
            fill="#798877"
        />
    </svg>
</template>
