<template>
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5_9)">
            <mask
                id="mask0_5_9"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="12"
                height="16"
            >
                <path d="M12 16L12 0L0 0L0 16H12Z" fill="white" />
            </mask>
            <g mask="url(#mask0_5_9)">
                <path d="M7 12.8L7 0L5 0L5 12.8H7Z" fill="#798877" />
                <path d="M6 16L1 12H11L6 16Z" fill="#798877" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_5_9">
                <rect width="12" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
