import { createApp } from 'vue'

import App from './App.vue'
import { markRaw } from 'vue'
import { auth } from './plugins/auth.plugin'
import { api, photoApi } from './plugins/api.plugin'
// import { dbPlugin } from './plugins/db.plugin'
import { i18n } from './i18n'
import router from './router'
import { createPinia } from 'pinia'
import './axiosConfig'
import './assets/css/app.scss'
import GlobalComponents from './plugins/globalIcons.ts'
import Vue3Geolocation from 'vue3-geolocation'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/translucent.css'
import { createHead } from '@vueuse/head'

import { VueRecaptchaPlugin } from 'vue-recaptcha'

// Notifications
import Toast, { TYPE } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// GSAP
import './gsap'

const toastOptions = {
    toastDefaults: {
        // ToastOptions object for each type of toast
        [TYPE.ERROR]: {
            icon: {
                iconTag: 'span',
                iconClass: 'icon-toast-success',
            },
            closeButtonClassName: 'icon-toast-close',
        },
        [TYPE.SUCCESS]: {
            icon: {
                iconTag: 'span',
                iconClass: 'icon-toast-success',
            },
            closeButtonClassName: 'icon-toast-close',
        },
    },
}

const pinia = createPinia()
pinia.use(({ store }) => {
    store.$router = markRaw(router)
})

const app = createApp(App)

app.use(router)
app.use(pinia)
app.use(i18n)
app.use(auth)
app.use(Toast, toastOptions)
app.use(Vue3Geolocation)
app.use(api)
app.use(photoApi)
// app.use(dbPlugin)
app.use(GlobalComponents)
app.use(
    VueTippy,
    // optional
    {
        directive: 'tippy', // => v-tippy
        component: 'tippy', // => <tippy/>
        componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
        defaultProps: {
            placement: 'auto-end',
            theme: 'epu',
            allowHTML: true,
        },
    }
)
app.use(createHead())

app.use(VueRecaptchaPlugin, {
    v2SiteKey: import.meta.env.VITE_RECAPTCHA_SITEKEY,
    v3SiteKey: import.meta.env.VITE_RECAPTCHA_SITEKEY,
})

app.mount('#eupureApp')
