import { useToast } from 'vue-toastification'
const toast = useToast()
import { i18n } from '../i18n'
import axios from 'axios'
import { useUserStore } from '../stores/users/UserStore'
import { storeToRefs } from 'pinia'
import { environmentMode } from '../environment'
import { apiServerUrl, baseAPIUrl, basePhotonUrl, photoServerUrl, basePhotoServerUrl } from '../data/constants/urls'

// import store from "../store/store";
// import { computed } from "vue";
// const userData = computed(() => store.getters.UserData);

// TODO: Basic error handling when params are empty etc.
// TODO: Make the router push better when not logged in

export function handleNotAuthorized() {
    //
    toast.error(i18n.global.t('components.loginModal.loginRequired'))
    // app.config.globalProperties.$router.push({ name: 'home' })
}

const api = {
    install: (app) => {
        const userStore = useUserStore()
        const { userData } = storeToRefs(userStore)
        app.config.globalProperties.$saveTripToLists = async (listIds, tripId) => {
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }

            const idToken = await userData.value.getIdToken()

            try {
                const res = await axios.post(
                    `${baseAPIUrl}/trips/${tripId}/store_to_user_lists`,
                    {
                        list_ids: listIds,
                    },
                    {
                        headers: { idToken },
                    }
                )
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }
        app.config.globalProperties.$generateElevation = async (coords, tripId) => {
            try {
                const res = await axios.get(`${baseAPIUrl}/trips/${tripId}/elevations`)
                return res
            } catch (error) {
                toast.error(error.message)
                return
            }
        }

        app.config.globalProperties.$fullTextSearch = async (query, languages = []) => {
            if (!query) return

            try {
                const languageParams = languages?.map((l) => `&languages[]=${l}`)?.join('') ?? ''

                const res = await axios.get(`${baseAPIUrl}/trips/fulltextsearch?q=${query}${languageParams}&strict_languages=false`)
                return res
            } catch (error) {
                toast.error(error.message)
                return
            }
        }

        app.config.globalProperties.$addPOIToTrip = async (tripId, poiId) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }

            const idToken = await userData.value.getIdToken()

            try {
                const res = await axios.post(`${baseAPIUrl}/trips/${tripId}/add_poi`, { poi_id: poiId }, { headers: { idToken } })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$removePOIFromTrip = async (tripId, poiId) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }

            const idToken = await userData.value.getIdToken()

            try {
                const res = await axios.post(`${baseAPIUrl}/trips/${tripId}/remove_poi`, { poi_id: poiId }, { headers: { idToken } })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$getAllTrips = async (includePrivate = false, onlyMine = false, includeGeometry = false) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }
            const idToken = await userData.value.getIdToken()
            try {
                const res = await axios.get(`${baseAPIUrl}/trips?include_private=${includePrivate}&mine_only=${onlyMine}&include_geometry=${includeGeometry}`, {
                    headers: {
                        idToken,
                    },
                })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }
        app.config.globalProperties.$getUserIcon = async () => {
            const userStore = useUserStore()
            const { userData, userIcon } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }
            const idToken = await userData.value.getIdToken()
            try {
                const res = await axios.get(`${baseAPIUrl}/trips/all_publishers?icon_format=png`, {
                    headers: {
                        idToken,
                    },
                })
                // FIXMe this is only for temp displaying icon
                userIcon.value = res.data[0].iconUrl
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$getAllTripsGeojson = async (includePrivate = false, onlyMine = false) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }
            const idToken = await userData.value.getIdToken()
            try {
                const res = await axios.get(`${baseAPIUrl}/trips/geojson?include_private=${includePrivate}&mine_only=${onlyMine}`, {
                    headers: {
                        idToken,
                    },
                })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        // app.config.globalProperties.$getNearbyTrips = async (latitude, longitude, filters = []) => {
        //     let url = `${baseAPIUrl}/trips/nearbysearch?latitude=${latitude}&longitude=${longitude}`

        //     // add aditional filters
        //     for (const filter of Object.keys(filters)) {
        //         url = `${url}&${filter}=${filters[filter]}`
        //     }
        //     // const idToken = await userData.value.getIdToken();
        //     try {
        //         const res = await axios.get(url, {
        //             // headers: { idToken },
        //         })

        //         return res?.data
        //     } catch (error) {
        //         toast.error(error.message)
        //     }
        // }

        app.config.globalProperties.$photonSearch = async (searchQuery) => {
            const encodedQuery = encodeURIComponent(searchQuery)
            try {
                const res = await axios.get(`${basePhotonUrl}?q=${encodedQuery}`)
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$getPoi = async (id) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }

            const idToken = await userData.value.getIdToken()
            try {
                const res = await axios.get(`${baseAPIUrl}/pois/${id}`, {
                    headers: {
                        idToken,
                    },
                })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$getPoisByTripId = async (tripId) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            const headers = {}

            try {
                if (userData?.value?.getIdToken) {
                    const idToken = await userData.value.getIdToken()
                    headers.idToken = idToken
                }
            } catch {
                console.warn("[getPoisByTripId] token entry found but coulnd't extract")
            }

            try {
                const res = await axios.get(`${baseAPIUrl}/trips/${tripId}/pois`, {
                    headers,
                })
                return res
            } catch (error) {
                // toast.error(error.message);
                console.warn("Couldn't fetch trip pois...", error)
            }
        }

        app.config.globalProperties.$getTripById = async (tripId, geometry = false) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            const headers = {}
            if (userData && userData.value?.getIdToken) {
                const idToken = await userData.value.getIdToken()
                headers.idToken = idToken

                // console.log("[GetTripById] Successfully extracted ID token", idToken);
            } else {
                // FIXME remove or do something
                // console.warn("[GetTripById] Couldn't extract ID token, fetching anonymous trip details...");
            }

            let url = `${baseAPIUrl}/trips/${tripId}`
            if (geometry) {
                url += `?include_geometry=true`
            }

            try {
                const res = await axios.get(url, { headers })
                console.log('[GetTripById] Successfully fetched trip details', res)
                return res
            } catch (error) {
                console.error(error)
            }
        }

        app.config.globalProperties.$getTripGeojsonById = async (tripId, supressErrors = false) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            const url = `${baseAPIUrl}/trips/${tripId}/geojson`

            const headers = {}
            if (userData && userData.value?.getIdToken) {
                const idToken = await userData.value.getIdToken()
                headers.idToken = idToken
            }

            try {
                const res = await axios.get(url, { headers })
                return res.data
            } catch (e) {
                if (!supressErrors) {
                    toast.error(e)
                }
            }

            return undefined
        }

        app.config.globalProperties.$createTrip = async (tripData = {}) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                toast.error(i18n.global.t('components.loginModal.loginRequired'))
                app.config.globalProperties.$router.push({ name: 'home' })
            }
            const idToken = await userData.value.getIdToken()
            try {
                const res = await axios.post(`${baseAPIUrl}/trips`, tripData, {
                    headers: {
                        'Content-Type': 'application/json',
                        idToken,
                    },
                })
                return res
            } catch (error) {
                toast.error(error.message)
                return false
            }
        }

        app.config.globalProperties.$updateTripById = async (id, tripData = {}) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                toast.error(i18n.global.t('components.loginModal.loginRequired'))
            }
            const idToken = await userData.value.getIdToken()

            try {
                const res = await axios.put(`${baseAPIUrl}/trips/${id}`, tripData, {
                    headers: {
                        'Content-Type': 'application/json',
                        idToken,
                    },
                })
                return res
            } catch (error) {
                toast.error(error.message)
                return false
            }
        }

        app.config.globalProperties.$deleteTrip = async (tripId) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            const idToken = await userData.value.getIdToken()
            try {
                const res = await axios.delete(`${baseAPIUrl}/trips/${tripId}`, {
                    headers: { idToken },
                })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$createPOIWithTripId = async (tripId, poiData) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }
            const idToken = await userData.value.getIdToken()
            try {
                const res = await axios.post(`${baseAPIUrl}/trips/${tripId}/pois`, poiData, {
                    headers: {
                        'Content-Type': 'application/json',
                        idToken,
                    },
                })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$updatePOIById = async (poiId, poiData) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }
            const idToken = await userData.value.getIdToken()
            try {
                const res = await axios.put(`${baseAPIUrl}/pois/${poiId}`, poiData, {
                    headers: {
                        'Content-Type': 'application/json',
                        idToken,
                    },
                })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$updateContactById = async (contactId, contactData) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }
            const idToken = await userData.value.getIdToken()
            try {
                const res = await axios.put(`${baseAPIUrl}/contacts/${contactId}`, contactData, {
                    headers: {
                        'Content-Type': 'application/json',
                        idToken,
                    },
                })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$getMyPois = async () => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }
            const idToken = await userData.value.getIdToken()
            try {
                //TODO: mine_only
                const res = await axios.get(`${baseAPIUrl}/pois?mine_only=true`, {
                    headers: {
                        'Content-Type': 'application/json',
                        idToken,
                    },
                })
                return res
            } catch (error) {
                //TODO: When no POIs are present, 400 error occurs
                toast.error(error.message)
            }
        }

        // app.config.globalProperties.$getPoiTypes = async () => {
        //     // FIXME v api plugin id musí být number
        //     const userStore = useUserStore()
        //     const { userData } = storeToRefs(userStore)
        //     if (!userData.value.getIdToken) {
        //         handleNotAuthorized()
        //     }
        //     const idToken = await userData.value.getIdToken()
        //     try {
        //         const res = await axios.get(`${baseAPIUrl}/pois/poi_types`, {
        //             headers: {
        //                 idToken,
        //             },
        //         })
        //         console.log(res)
        //         return res
        //     } catch (error) {
        //         //TODO: When no POIs are present, 400 error occurs
        //         toast.error(error.message)
        //     }
        // }

        app.config.globalProperties.$deleteAccount = async () => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }
            const idToken = await userData.value.getIdToken()
            try {
                const res = await axios.post(
                    `${baseAPIUrl}/users/delete_account`,
                    {},
                    {
                        headers: {
                            idToken,
                        },
                    }
                )
                // console.log(res)
                toast.success(i18n.global.t('settings.profile.deleteAccountModal.deleted'))
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        // app.config.globalProperties.$getTripPublishers = async () => {
        //     try {
        //         const res = await axios.get(`${baseAPIUrl}/trips/all_publishers`)
        //         return res
        //     } catch (error) {
        //         toast.error(i18n.global.t('tripdetail.couldntFetch') + error.message)
        //     }
        // }

        app.config.globalProperties.$downloadGPX = async (id, fs_path) => {
            const userStore = useUserStore()
            const { userData } = storeToRefs(userStore)
            if (!userData.value.getIdToken) {
                handleNotAuthorized()
            }

            const idToken = await userData?.value?.getIdToken()
            try {
                const res = await axios.get(`${baseAPIUrl}/trips/${id}/download_gpx${fs_path}`, {
                    headers: { idToken },
                })
                return res
            } catch (error) {
                toast.error(i18n.global.t('tripdetail.couldntDownload') + error.message)
            }
        }
    },
}

const photoApi = {
    install: (app) => {
        app.config.globalProperties.$getPhotosByGroupId = async (groupId) => {
            const encodedId = encodeURIComponent(groupId)
            try {
                const res = await axios.get(`${basePhotoServerUrl}/photos?group_ids[]=${encodedId}`)
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$getPhotosByGroupIds = async (groupIds) => {
            if (groupIds.length <= 0) {
                console.error('Cannot fetch 0 groups')
                return { data: [] }
            }

            const encodedGroupIds = groupIds.map(encodeURIComponent)
            const queryGroupIds = encodedGroupIds.map((id) => `group_ids[]=${id}`)

            try {
                const res = await axios.get(`${basePhotoServerUrl}/photos?${queryGroupIds.join('&')}`)
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$getPhotosByGroups = async (groups, perGroupLimit = 5) => {
            if (groups?.length <= 0) {
                console.error('Cannot fetch 0 groups')
                return
            }

            try {
                const res = await axios.post(`${basePhotoServerUrl}/photos/find_by_groups`, {
                    groups,
                    default_groups_limit: perGroupLimit,
                })

                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$setGroupIdToPhotos = async (groupId, photoIds) => {
            try {
                const res = await axios.post(`${basePhotoServerUrl}/photos/set_group`, {
                    group_id: groupId,
                    photo_ids: photoIds,
                })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$setPhotoStatus = async (status, photoIds) => {
            try {
                const res = await axios.post(`${basePhotoServerUrl}/photos/set_status`, {
                    status: status,
                    photo_ids: photoIds,
                })

                toast.success(i18n.global.t('poiDetail.addPhotoModal.success'))
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$setPhotosOrder = async (arrayOfIds) => {
            try {
                const res = await axios.post(`${basePhotoServerUrl}/photos/set_order`, {
                    photo_ids: arrayOfIds,
                })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$updatePhotoDescription = async (photoId, description, lang) => {
            try {
                const res = await axios.post(`${basePhotoServerUrl}/photos/${photoId}/set_description`, {
                    language: lang,
                    description: description,
                })
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$uploadProfilePicture = async (photo) => {
            const formData = new FormData()
            formData.append('photo[image]', photo)
            formData.append('photo[variant_group]', 'profile')

            try {
                const res = await axios.post(`${basePhotoServerUrl}/photos`, formData)
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }

        app.config.globalProperties.$uploadPhoto = async (data) => {
            try {
                const res = await axios.post(`${basePhotoServerUrl}/photos`, data)
                return res
            } catch (error) {
                toast.error(error.message)
            }
        }
    },
}

export { api, photoApi }
