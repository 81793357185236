<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_698)">
            <path
                d="M0.0703125 12.0625C0.0703125 18.6875 5.4375 24.0547 12.0586 24.0547C18.6836 24.0547 24.0508 18.6875 24.0508 12.0625C24.0508 5.44141 18.6836 0.0742188 12.0586 0.0742188C5.4375 0.0742188 0.0703125 5.44141 0.0703125 12.0625Z"
                fill="#ADBCAA"
            />
            <mask
                id="mask0_448_698"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="7"
                y="12"
                width="9"
                height="9"
            >
                <path d="M7 12H16V21H7V12Z" fill="white" />
            </mask>
            <g mask="url(#mask0_448_698)">
                <mask
                    id="mask1_448_698"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0625C0.0703125 18.6875 5.4375 24.0547 12.0586 24.0547C18.6836 24.0547 24.0508 18.6875 24.0508 12.0625C24.0508 5.44141 18.6836 0.0742188 12.0586 0.0742188C5.4375 0.0742188 0.0703125 5.44141 0.0703125 12.0625Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_448_698)">
                    <path
                        d="M13.8281 13.5742C13.8281 13.5742 14.1172 13.3008 14.4453 13.6289L15.5352 14.7148C15.5352 14.7148 15.8594 15.043 15.8594 15.5078L15.8477 20.0234H15.3242C15.3242 20.0234 14.8633 20.0234 14.8594 19.5586L14.8477 17.3867C14.8477 17.3867 14.8477 16.9219 14.3828 16.9219H10.3437L9.57422 17.6133C9.57422 17.6133 9.22656 17.9219 9.21875 18.3828L9.18359 20.0391H8.67578C8.67578 20.0391 8.21484 20.0391 8.21484 19.5742V16.9961L8.58984 16.3398C8.58984 16.3398 8.75 16.0586 8.74609 15.7305L8.73828 14.5195C8.73828 14.5195 8.73437 14.1953 8.50391 13.9648L7.52734 12.9883L7.67578 12.8008C7.67578 12.8008 7.82422 12.6133 8.07031 12.832L8.88281 13.5586C8.88281 13.5586 9.22656 13.8672 9.69141 13.8633L13.5352 13.8516L13.8281 13.5742Z"
                        fill="#3F674D"
                    />
                    <path
                        d="M13.8281 13.5742C13.8281 13.5742 14.1172 13.3008 14.4453 13.6289L15.5352 14.7148C15.5352 14.7148 15.8594 15.043 15.8594 15.5078L15.8477 20.0234H15.3242C15.3242 20.0234 14.8633 20.0234 14.8594 19.5586L14.8477 17.3867C14.8477 17.3867 14.8477 16.9219 14.3828 16.9219H10.3438L9.57422 17.6133C9.57422 17.6133 9.22656 17.9219 9.21875 18.3828L9.1836 20.0391H8.67578C8.67578 20.0391 8.21484 20.0391 8.21484 19.5742V16.9961L8.58984 16.3398C8.58984 16.3398 8.75 16.0586 8.7461 15.7305L8.73828 14.5195C8.73828 14.5195 8.73438 14.1953 8.50391 13.9648L7.52734 12.9883L7.67578 12.8008C7.67578 12.8008 7.82422 12.6133 8.07031 12.832L8.88281 13.5586C8.88281 13.5586 9.22656 13.8672 9.69141 13.8633L13.5352 13.8516L13.8281 13.5742Z"
                        stroke="#3F674D"
                        stroke-width="0.210286"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>
            <mask
                id="mask2_448_698"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="9"
                y="8"
                width="5"
                height="6"
            >
                <path d="M9 8H14V14H9V8Z" fill="white" />
            </mask>
            <g mask="url(#mask2_448_698)">
                <mask
                    id="mask3_448_698"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0625C0.0703125 18.6875 5.4375 24.0547 12.0586 24.0547C18.6836 24.0547 24.0508 18.6875 24.0508 12.0625C24.0508 5.44141 18.6836 0.0742188 12.0586 0.0742188C5.4375 0.0742188 0.0703125 5.44141 0.0703125 12.0625Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask3_448_698)">
                    <path
                        d="M9.4375 9.34375L11.4453 12.6719C11.7422 13.1641 12.3789 13.332 12.8555 13.0469C13.3359 12.7617 13.4805 12.125 13.1836 11.6328L11.1758 8.30078L9.4375 9.34375Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask4_448_698"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="6"
                y="12"
                width="4"
                height="9"
            >
                <path d="M6 12H10V21H6V12Z" fill="white" />
            </mask>
            <g mask="url(#mask4_448_698)">
                <mask
                    id="mask5_448_698"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0625C0.0703125 18.6875 5.4375 24.0547 12.0586 24.0547C18.6836 24.0547 24.0508 18.6875 24.0508 12.0625C24.0508 5.44141 18.6836 0.0742188 12.0586 0.0742188C5.4375 0.0742188 0.0703125 5.44141 0.0703125 12.0625Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask5_448_698)">
                    <path
                        d="M6.91016 14.9688L6.91797 19.0703C6.92188 19.6641 7.39453 20.1523 7.97266 20.1562C8.54688 20.1562 9.01953 19.668 9.01953 19.0742L9.00781 14.9727C9.00781 14.957 8.98047 12.8359 8.98047 12.8203L7.15234 12.4297C7.09766 12.418 7.04688 12.3984 6.99609 12.3789C6.92578 12.5234 6.91016 14.7969 6.91016 14.9688Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask6_448_698"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="8"
                y="2"
                width="4"
                height="4"
            >
                <path d="M8 2H12V6H8V2Z" fill="white" />
            </mask>
            <g mask="url(#mask6_448_698)">
                <mask
                    id="mask7_448_698"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0625C0.0703125 18.6875 5.4375 24.0547 12.0586 24.0547C18.6836 24.0547 24.0508 18.6875 24.0508 12.0625C24.0508 5.44141 18.6836 0.0742188 12.0586 0.0742188C5.4375 0.0742188 0.0703125 5.44141 0.0703125 12.0625Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask7_448_698)">
                    <path
                        d="M10.2734 5.41797C11.0547 5.41797 11.6836 4.78906 11.6836 4.00781C11.6836 3.23047 11.0547 2.59766 10.2734 2.59766C9.49609 2.59766 8.86328 3.23047 8.86328 4.00781C8.86328 4.78906 9.49609 5.41797 10.2734 5.41797Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask8_448_698"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="6"
                y="5"
                width="6"
                height="11"
            >
                <path d="M6 5H12V16H6V5Z" fill="white" />
            </mask>
            <g mask="url(#mask8_448_698)">
                <mask
                    id="mask9_448_698"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0625C0.0703125 18.6875 5.4375 24.0547 12.0586 24.0547C18.6836 24.0547 24.0508 18.6875 24.0508 12.0625C24.0508 5.44141 18.6836 0.0742188 12.0586 0.0742188C5.4375 0.0742188 0.0703125 5.44141 0.0703125 12.0625Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask9_448_698)">
                    <path
                        d="M11.7148 9.20314L9.96484 6.28517C9.86328 6.10939 9.70703 5.96876 9.52344 5.87892C9.23828 5.73829 8.86328 5.74611 8.55859 5.80079C8.27344 5.85157 8.00391 5.98829 7.79688 6.18751C7.67578 6.30079 7.57813 6.43751 7.50781 6.58595C7.4375 6.72657 7.39063 6.87892 7.3711 7.03517L6.65625 12.4883C6.59766 12.9375 6.76563 13.3672 7.07031 13.6914C7.20703 13.8438 7.33594 14.0117 7.48438 14.1484C7.53125 14.1914 7.57422 14.2344 7.61719 14.2734C7.77344 14.418 7.92578 14.5625 8.08203 14.707C8.27344 14.8867 8.46484 15.0664 8.65234 15.2422C8.82031 15.3984 8.98828 15.5547 9.15234 15.7109C9.23438 15.7891 9.31641 15.8633 9.39844 15.9414C9.39844 15.9414 9.40234 15.9414 9.40234 15.9453L9.42578 13.1914C9.46485 13.086 9.49609 12.9805 9.51172 12.8633L9.875 10.0742L11.7148 9.20314Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <path
                d="M14.582 12.5859C14.582 12.5859 14.4102 12.875 14.6524 13.1094L16.3867 14.8086L16.836 14C16.836 14 16.9922 13.7109 17.3047 13.8086L18.5274 14.1875L19.2774 13.4258L19.086 13.1797L18.125 12.5547L17.7539 11.75C17.7539 11.75 17.6172 11.4531 17.3008 11.3711L15.9922 11.0273L14.836 10.418L15.086 11.2656C15.086 11.2656 15.1797 11.5781 15.0156 11.8594L14.582 12.5859Z"
                fill="#3F674D"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_698">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
