<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
        strokeTwo: string
    }
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 31,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.strokeTwo" />
        <path
            d="M11.75 9V14.6875H10.3828L8.37109 11.1484V14.6875H7V9H8.37109L10.3828 12.5391V9H11.75Z"
            :fill="props.color.fill"
        />
        <path
            d="M16.68 13.6328V14.6875H13.6488V13.6328H16.68ZM14.1371 9V14.6875H12.766V9H14.1371ZM16.2894 11.2578V12.2773H13.6488V11.2578H16.2894ZM16.6917 9V10.0586H13.6488V9H16.6917Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
