<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_676)">
            <path
                d="M0.03125 12.0117C0.03125 18.6367 5.39844 24.0039 12.0195 24.0039C18.6406 24.0039 24.0117 18.6367 24.0117 12.0117C24.0117 5.39062 18.6406 0.0234375 12.0195 0.0234375C5.39844 0.0234375 0.03125 5.39062 0.03125 12.0117Z"
                fill="#ADBCAA"
            />
            <path
                d="M9.49608 13.8828L7.71092 16.8711C7.4492 17.3086 7.58202 17.8711 7.99998 18.125C8.42186 18.375 8.97655 18.2266 9.23436 17.793L11.0234 14.8047C11.0312 14.793 11.3281 14.2461 11.3359 14.2383L10.1758 13.1523C10.1406 13.1172 10.1133 13.0859 10.0859 13.0469C9.97264 13.1211 9.5742 13.7578 9.49608 13.8828Z"
                fill="#3F674D"
            />
            <path
                d="M12.5312 5.99219C13.1953 5.99219 13.7305 5.45703 13.7305 4.79297C13.7305 4.13281 13.1953 3.59766 12.5312 3.59766C11.8711 3.59766 11.3359 4.13281 11.3359 4.79297C11.3359 5.45703 11.8711 5.99219 12.5312 5.99219Z"
                fill="#3F674D"
            />
            <path
                d="M15.6914 8.16406C15.6914 8.16406 15.7422 8.54297 15.8281 9.14453Z"
                fill="#3F674D"
            />
            <path
                d="M16.8672 10.0703C16.8672 9.59374 16.4687 9.20702 15.9844 9.20702H13.7578L12.2734 6.72655C12.1836 6.57811 12.0508 6.45702 11.8945 6.3828C11.6562 6.26561 11.3359 6.26952 11.0781 6.31639C10.8359 6.35936 10.6055 6.47655 10.4297 6.64452C10.3281 6.74217 10.2422 6.85545 10.1836 6.98436C10.1211 7.10155 10.0859 7.23436 10.0664 7.36717L9.46093 11.9961C9.41014 12.3789 9.55468 12.7422 9.81249 13.0195C9.92968 13.1484 10.039 13.289 10.164 13.4062C10.2031 13.4414 10.2383 13.4765 10.2773 13.5117C10.4101 13.6367 10.5391 13.7578 10.6719 13.8828C10.832 14.0312 10.9961 14.1836 11.1562 14.3359C11.2969 14.4687 11.4414 14.6015 11.582 14.7344C11.6523 14.7969 11.7187 14.8633 11.789 14.9297H11.793L12.6797 18.043C12.8164 18.5117 13.2969 18.789 13.75 18.6562C14.2031 18.5273 14.4648 18.0351 14.332 17.5664L13.4101 14.3398C13.3828 14.25 13.3437 14.168 13.293 14.0937C13.2461 13.9765 13.1758 13.8711 13.0781 13.7773L11.8125 12.5937C11.8437 12.5039 11.8711 12.414 11.8828 12.3164L12.1953 9.9453L12.5234 10.4922C12.7031 10.793 13.0273 10.9453 13.3476 10.9258C13.3633 10.9258 13.3828 10.9297 13.4023 10.9297H15.9844C16.4687 10.9297 16.8672 10.543 16.8672 10.0703Z"
                fill="#3F674D"
            />
            <path
                d="M15.7539 8.55858C16.2773 12.2812 17.0039 17.4844 17.0039 17.4844Z"
                fill="#3F674D"
            />
            <path
                d="M15.7539 8.55858C16.2773 12.2812 17.0039 17.4844 17.0039 17.4844"
                stroke="#3F674D"
                stroke-width="0.787266"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.74219 18.7109C9.16797 18.7109 14.9024 18.7109 19.0195 18.7109"
                stroke="#3F674D"
                stroke-width="0.787266"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.92188 5.68751C8.78907 5.59766 7.76173 6.41407 7.59766 7.57813C7.4336 8.7461 8.19532 9.81251 9.3086 10.0391L9.92188 5.68751Z"
                fill="#3F674D"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_676">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
