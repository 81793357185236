<script setup lang="ts">
export interface Props {
    strokeColorRoute?: string
}

const props = withDefaults(defineProps<Props>(), {
    strokeColorRoute: '#022D05',
})
</script>
<template>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_6786)">
            <path
                d="M16.625 13.2825V6.125C16.625 4.19125 15.0588 2.625 13.125 2.625C11.1912 2.625 9.625 4.19125 9.625 6.125V14.875C9.625 15.8375 8.8375 16.625 7.875 16.625C6.9125 16.625 6.125 15.8375 6.125 14.875V7.7175C7.14 7.35 7.875 6.3875 7.875 5.25C7.875 3.7975 6.7025 2.625 5.25 2.625C3.7975 2.625 2.625 3.7975 2.625 5.25C2.625 6.3875 3.36 7.35 4.375 7.7175V14.875C4.375 16.8088 5.94125 18.375 7.875 18.375C9.80875 18.375 11.375 16.8088 11.375 14.875V6.125C11.375 5.1625 12.1625 4.375 13.125 4.375C14.0875 4.375 14.875 5.1625 14.875 6.125V13.2825C13.86 13.6413 13.125 14.6038 13.125 15.75C13.125 17.2025 14.2975 18.375 15.75 18.375C17.2025 18.375 18.375 17.2025 18.375 15.75C18.375 14.6125 17.64 13.65 16.625 13.2825ZM5.25 6.125C4.76875 6.125 4.375 5.73125 4.375 5.25C4.375 4.76875 4.76875 4.375 5.25 4.375C5.73125 4.375 6.125 4.76875 6.125 5.25C6.125 5.73125 5.73125 6.125 5.25 6.125ZM15.75 16.625C15.2687 16.625 14.875 16.2313 14.875 15.75C14.875 15.2687 15.2687 14.875 15.75 14.875C16.2313 14.875 16.625 15.2687 16.625 15.75C16.625 16.2313 16.2313 16.625 15.75 16.625Z"
                :fill="props.strokeColorRoute"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_6786">
                <rect width="21" height="21" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
