<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_418)">
            <path
                d="M0.0703125 12.0625C0.0703125 18.6875 5.4375 24.0547 12.0586 24.0547C18.6836 24.0547 24.0508 18.6875 24.0508 12.0625C24.0508 5.44141 18.6836 0.0742188 12.0586 0.0742188C5.4375 0.0742188 0.0703125 5.44141 0.0703125 12.0625Z"
                fill="#ADBCAA"
            />
            <mask
                id="mask0_448_418"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="9"
            >
                <path d="M0 0H24V9H0V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_448_418)">
                <mask
                    id="mask1_448_418"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0625C0.0703125 18.6875 5.4375 24.0547 12.0586 24.0547C18.6836 24.0547 24.0508 18.6875 24.0508 12.0625C24.0508 5.44141 18.6836 0.0742188 12.0586 0.0742188C5.4375 0.0742188 0.0703125 5.44141 0.0703125 12.0625Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_448_418)">
                    <path
                        d="M24.8516 7.38671L17.5586 8.5703C17.5586 8.5703 16.3242 8.76952 15.125 8.43358L9.07812 6.75389C9.07812 6.75389 7.875 6.42186 6.64062 6.59764L2.33594 7.20702L-4.89063 6.39843C-4.89063 6.39843 -6.13281 6.26171 -5.80469 5.05858C-5.80469 5.05858 -3.83203 -3.13282 -3.53125 -3.27736C-3.23047 -3.41798 25.1719 -0.285167 25.1719 -0.285167C25.1719 -0.285167 26.4102 -0.148449 27.2344 0.789051C27.2344 0.789051 31.7969 3.99608 30.7461 4.78124C29.6992 5.56249 24.8516 7.38671 24.8516 7.38671Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <path
                d="M9.89062 13.6523C9.89062 13.6523 8.91016 13.6523 8.91016 14.6328V15.8164C8.91016 15.8164 8.91016 16.7969 9.89062 16.7969H15.5039C15.5039 16.7969 16.4805 16.7969 16.4805 15.8164V14.6328C16.4805 14.6328 16.4805 13.6523 15.5039 13.6523H9.89062Z"
                fill="#3F674D"
            />
            <path
                d="M18.0938 15.1523C18.9609 15.1523 19.668 14.4492 19.668 13.5781C19.668 12.7109 18.9609 12.0078 18.0938 12.0078C17.2266 12.0078 16.5234 12.7109 16.5234 13.5781C16.5234 14.4492 17.2266 15.1523 18.0938 15.1523Z"
                fill="#3F674D"
            />
            <path
                d="M16.1953 15.9844H19.9609"
                stroke="#3F674D"
                stroke-width="1.3871"
                stroke-linecap="round"
            />
            <path
                d="M3.47266 15.875H10.0859"
                stroke="#3F674D"
                stroke-width="1.58498"
                stroke-linecap="round"
            />
            <path
                d="M6.85938 12.4805L9.53516 14.8477"
                stroke="#3F674D"
                stroke-width="1.58498"
                stroke-linecap="round"
            />
            <path
                d="M4.16022 14.9258L6.81647 12.5351"
                stroke="#3F674D"
                stroke-width="1.58498"
                stroke-linecap="round"
            />
            <path
                d="M14.6953 4.58984C14.9766 4.58984 15.2031 4.36328 15.2031 4.08203C15.2031 3.80078 14.9766 3.57422 14.6953 3.57422C14.4141 3.57422 14.1836 3.80078 14.1836 4.08203C14.1836 4.36328 14.4141 4.58984 14.6953 4.58984Z"
                fill="#ADBCAA"
            />
            <path
                d="M10.2891 1.41016C10.0391 1.3125 9.76562 1.25781 9.47656 1.25781C8.24219 1.25781 7.24219 2.25781 7.24219 3.49219C7.24219 4.72656 8.24219 5.72656 9.47656 5.72656C9.76562 5.72656 10.0391 5.67188 10.2891 5.57422C9.45312 5.25 8.86328 4.44141 8.86328 3.49219C8.86328 2.54297 9.45312 1.73438 10.2891 1.41016Z"
                fill="#ADBCAA"
            />
            <path
                d="M18.2422 3.91406C18.4414 3.91406 18.6016 3.75391 18.6016 3.55859C18.6016 3.35937 18.4414 3.19922 18.2422 3.19922C18.0469 3.19922 17.8867 3.35937 17.8867 3.55859C17.8867 3.75391 18.0469 3.91406 18.2422 3.91406Z"
                fill="#ADBCAA"
            />
            <path
                d="M5.98438 3.85156C6.18359 3.85156 6.34375 3.69141 6.34375 3.49609C6.34375 3.29687 6.18359 3.14062 5.98438 3.14062C5.78906 3.14062 5.62891 3.29687 5.62891 3.49609C5.62891 3.69141 5.78906 3.85156 5.98438 3.85156Z"
                fill="#ADBCAA"
            />
            <path
                d="M16.8242 6.44141C17.1055 6.44141 17.332 6.21484 17.332 5.93359C17.332 5.64844 17.1055 5.42188 16.8242 5.42188C16.543 5.42188 16.3125 5.64844 16.3125 5.93359C16.3125 6.21484 16.543 6.44141 16.8242 6.44141Z"
                fill="#ADBCAA"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_418">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
