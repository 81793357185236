<template>
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="60" cy="60" r="55" fill="#E4EBE6" stroke="#F1F5F3" stroke-width="10" />
        <g clip-path="url(#clip0_10712_140420)">
            <path d="M68 45H46V49H68V45Z" fill="white" />
            <path d="M68 53H46V57H68V53Z" fill="white" />
            <path d="M59 61H46V65H59V61Z" fill="white" />
            <path
                d="M83.9887 62.9063L82.0947 61.0123C81.848 60.7651 81.5549 60.5689 81.2323 60.4351C80.9097 60.3013 80.5639 60.2324 80.2147 60.2324C79.8654 60.2324 79.5196 60.3013 79.197 60.4351C78.8744 60.5689 78.5814 60.7651 78.3347 61.0123L75.9727 63.3753L81.6257 69.0283L83.9887 66.6663C84.2359 66.4196 84.432 66.1265 84.5658 65.8039C84.6996 65.4813 84.7685 65.1355 84.7685 64.7863C84.7685 64.437 84.6996 64.0912 84.5658 63.7686C84.432 63.446 84.2359 63.153 83.9887 62.9063Z"
                fill="white"
            />
            <path
                d="M59 80.3469V85.9999H64.653L78.787 71.8669L73.133 66.2129L59 80.3469Z"
                fill="white"
            />
            <path
                d="M73.9986 86.0005C75.0595 86.0005 76.0769 85.5791 76.827 84.829C77.5772 84.0788 77.9986 83.0614 77.9986 82.0005V78.3105L70.3086 86.0005H73.9986Z"
                fill="white"
            />
            <path
                d="M40 38H74V59.69L75.506 58.184C76.2094 57.4811 77.0617 56.9453 78 56.616V38C78 36.9391 77.5786 35.9217 76.8284 35.1716C76.0783 34.4214 75.0609 34 74 34H40C38.9391 34 37.9217 34.4214 37.1716 35.1716C36.4214 35.9217 36 36.9391 36 38V82C36 83.0609 36.4214 84.0783 37.1716 84.8284C37.9217 85.5786 38.9391 86 40 86H55V82H40V38Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_10712_140420">
                <rect width="48.768" height="52" fill="white" transform="translate(36 34)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
