<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
    }
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 31,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M9.60189 12.156L9.50289 12.561H10.5829L10.4839 12.156C10.4119 11.874 10.3399 11.568 10.2679 11.238C10.1959 10.908 10.1239 10.596 10.0519 10.302H10.0159C9.95589 10.596 9.88989 10.908 9.81789 11.238C9.74589 11.568 9.67389 11.874 9.60189 12.156ZM7.35189 15L9.13389 9.15H11.0059L12.7879 15H11.1499L10.8619 13.758H9.22389L8.93589 15H7.35189ZM13.0218 15V14.118C13.4718 13.704 13.8768 13.32 14.2368 12.966C14.6028 12.612 14.8908 12.285 15.1008 11.985C15.3108 11.685 15.4158 11.406 15.4158 11.148C15.4158 10.89 15.3498 10.701 15.2178 10.581C15.0918 10.455 14.9238 10.392 14.7138 10.392C14.5278 10.392 14.3598 10.449 14.2098 10.563C14.0598 10.671 13.9158 10.794 13.7778 10.932L12.9498 10.104C13.2498 9.786 13.5498 9.555 13.8498 9.411C14.1498 9.261 14.5098 9.186 14.9298 9.186C15.3138 9.186 15.6528 9.264 15.9468 9.42C16.2408 9.57 16.4718 9.786 16.6398 10.068C16.8078 10.35 16.8918 10.68 16.8918 11.058C16.8918 11.364 16.8078 11.676 16.6398 11.994C16.4778 12.312 16.2708 12.624 16.0188 12.93C15.7668 13.23 15.5118 13.512 15.2538 13.776C15.3978 13.758 15.5598 13.743 15.7398 13.731C15.9258 13.713 16.0818 13.704 16.2078 13.704H17.1618V15H13.0218Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
