import { ref } from 'vue'

export const canUseWebP = () => {
    const webPSupported = ref(false)
    const elem = document.createElement('canvas')
    if (elem.getContext && elem.getContext('2d')) {
        // was able or not to get WebP representation
        webPSupported.value = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
        return { webPSupported }
    }
    webPSupported.value = false
    return { webPSupported }
}
