<template>
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.95031 0L0.0703125 8.88V11.71L11.7803 0H8.95031ZM16.5803 0.08L0.150312 16.51C0.240313 16.85 0.420312 17.16 0.660312 17.41C0.910312 17.65 1.22031 17.83 1.56031 17.92L18.0003 1.49C17.8103 0.8 17.2703 0.26 16.5803 0.08ZM0.0703125 2C0.0703125 0.9 0.970312 0 2.07031 0H4.07031L0.0703125 4V2ZM16.0703 18C16.6203 18 17.1203 17.78 17.4803 17.41C17.8503 17.05 18.0703 16.55 18.0703 16V14L14.0703 18H16.0703ZM9.19031 18H6.36031L18.0703 6.29V9.12L9.19031 18Z"
            fill="#798877"
        />
    </svg>
</template>
