<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16.0003" cy="15.9993" r="12.3333" stroke="#798877" stroke-width="2" />
        <path
            d="M15.5472 12.416V19.9993H13.7243L11.042 15.2806V19.9993H9.21387V12.416H11.042L13.7243 17.1348V12.416H15.5472Z"
            fill="#798877"
        />
        <path
            d="M20.8857 12.416V19.9993H19.0576V12.416H20.8857ZM23.167 12.416V13.8275H16.8285V12.416H23.167Z"
            fill="#798877"
        />
    </svg>
</template>
