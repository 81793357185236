import firebase from 'firebase/app'
import 'firebase/auth'
//TODO: When expanding - do another plugin just for Firestore handling
import 'firebase/firestore'
import 'firebase/performance'
// import { ref } from 'vue'
import { useToast } from 'vue-toastification'
const toast = useToast()

// import { removeUserDataFromStore } from './db.plugin'
import { EmailResendHoursLimit, EmailLimitStorageKey } from '@/data/constants/limits'
import { notifyAuthStateChanged } from '../router'
import { getUserCollection } from '@/hooks/getUserCollection'
// import { handlePendingPlan } from '../helpers/planning.helper'
// import { prepareUser } from '../helpers/user.helper'

import { i18n } from '../i18n'
// import { getStoredMapStyle } from '../helpers/map.helper'
import { getLocalizationKeyFromErrorCode } from '../helpers/firebase.helper'
// import { trySmartlookIdentify } from '../helpers/smartlook.helper'

import { firebaseConfig } from '../firebase'
// import { useMapsStore } from '../stores/maps/mapsStore'

import { useUserStore } from '../stores/users/UserStore'
import { useClaimsStore } from '../stores/claims/claimsStore'

// OAUTH Providers
const facebookProvider = new firebase.auth.FacebookAuthProvider()
const googleProvider = new firebase.auth.GoogleAuthProvider()
const appleProvider = new firebase.auth.OAuthProvider('apple.com')

googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile')

firebase.initializeApp(firebaseConfig)

firebase.performance()
firebase.performance().dataCollectionEnabled = true
// if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig)
// } else {
//     firebase.app() // if already initialized, use that one
// }
firebase.firestore().settings({ experimentalForceLongPolling: true, merge: true })

const fbAuth = firebase.auth()
window.auth = fbAuth

fbAuth.useDeviceLanguage()
// async function tryApplyActivationCode() {
//     const params = new URLSearchParams(window.location.search)
//     const code = params.get('oobCode')
//     if (code) {
//         // FIXMe unnec condition
//         // console.log("Extracted activation code", code);

//         try {
//             await fbAuth.applyActionCode(code)
//             await fbAuth.currentUser.reload()
//             toast.success(i18n.global.t('verifyemail.alerts.activated'))
//         } catch {
//             toast.error(i18n.global.t('verifyemail.alerts.invalidCode'))
//         }
//     }
// }
// FIXME as last part of revenue model
export async function setSubClaims(user = fbAuth.currentUser) {
    const tokenResult = await user.getIdTokenResult(true)
    const claimsStore = useClaimsStore()
    claimsStore.setPublisherClaim(tokenResult.claims.publisher ?? false)
}

const db = firebase.firestore()
let snapshotUnsubscribe = null

fbAuth.onAuthStateChanged(async (user) => {
    const userStore = useUserStore()
    const claimsStore = useClaimsStore()

    if (snapshotUnsubscribe !== null) {
        snapshotUnsubscribe()
        snapshotUnsubscribe = null
    }

    if (user) {
        await setSubClaims(user)
        userStore.isLoggedIn = true

        // IDENTIFY SMARTLOOK
        // trySmartlookIdentify(user.uid)
        // console.log('smartlook', trySmartlookIdentify(user.uid))

        if (user.uid) {
            userStore.setUserData(user)
            snapshotUnsubscribe = db
                .collection('users')
                .doc(user.uid)
                .onSnapshot(async (doc) => {
                    await setSubClaims()
                    const docData = doc.data()
                    userStore.setLastUserAccountChange(docData?.lastUserAccountChange)
                })
        }
    } else {
        userStore.isLoggedIn = false
        claimsStore.clearCustomClaims()
        userStore.userData = undefined
    }

    // notify router about login/logout
    notifyAuthStateChanged(user)
})

// Locale extractor, *there might be a better way to provide current locale
// function extractI18nLocale() {
//     return i18n.global.locale
// }

function handleAuthErrors(err) {
    const key = getLocalizationKeyFromErrorCode(err?.code)
    const code = err.code
    if (code.includes('auth/invalid-email')) {
        toast.error(i18n.global.t('firebase_auth_invalid_user_exception_message'))
    } else if (code.includes('auth/internal-error')) {
        toast.error(i18n.global.t('firebase_auth_weak_password_exception_message'))
    } else if (code.includes('auth/wrong-password')) {
        toast.error(i18n.global.t('firebase_auth_invalid_credentials_exception_message'))
    } else {
        toast.error(i18n.global.t(key), { timeout: 10000 })
    }
}

const auth = {
    install: (app) => {
        app.config.globalProperties.$resendVerificationEmail = async () => {
            try {
                const resendTimestamp = localStorage.getItem(EmailLimitStorageKey)
                if (resendTimestamp !== null) {
                    const timestamp = new Date(Number(resendTimestamp))
                    if (timestamp.getTime() > Date.now()) {
                        const remainingTime = new Date(timestamp.getTime() - Date.now())
                        toast.error(
                            `${i18n.global.t('verifyemail.code.cannotResend')} ${remainingTime.getHours()} ${i18n.global.t(
                                'hours'
                            )} ${remainingTime.getMinutes()} ${i18n.global.t('minutes')}`
                        )
                        return
                    } else {
                        localStorage.removeItem(EmailLimitStorageKey)
                    }
                }
                await fbAuth.currentUser?.sendEmailVerification()
                toast.success(i18n.global.t('verifyemail.code.verificationSend'))
                const timeToResend = Date.now() + EmailResendHoursLimit * 60 * 60 * 1000
                localStorage.setItem(EmailLimitStorageKey, timeToResend)
            } catch (e) {
                console.warn('[resendVerificationEmail]', e)
                toast.error(e.message)
            }
        }
        ;(app.config.globalProperties.$resetPassword = async (email) => {
            try {
                await firebase.auth().sendPasswordResetEmail(email)

                toast.success(i18n.global.t('verifyemail.code.resetSend'))
                return true
            } catch (e) {
                toast.error(e.message, { timeout: 10000 })
            }

            return false
        }),
            (app.config.globalProperties.$signUp = async (email, password, firstName, lastName) => {
                const userStore = useUserStore()
                try {
                    const data = await firebase.auth().createUserWithEmailAndPassword(email, password)
                    const displayName = `${firstName} ${lastName}`
                    const user = data.user

                    await user.updateProfile({
                        displayName: displayName,
                    })
                    await userStore.setUserData(user)
                    toast.success(i18n.global.t('components.loginModal.signedUp'))
                } catch (error) {
                    handleAuthErrors(error)
                }
            })

        app.config.globalProperties.$signIn = (email, password) => {
            firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(async (data) => {
                    // console.log(data);
                    const userStore = useUserStore()
                    // console.log(data.user)
                    await userStore.setUserData(data.user)
                    // window.localStorage.setItem('token', JSON.stringify(data.user.refreshToken))
                    toast.success(i18n.global.t('components.loginModal.signedIn'))
                    getUserCollection(data.user.uid)
                })
                .catch(handleAuthErrors)
        }

        app.config.globalProperties.$signOut = () => {
            firebase
                .auth()
                .signOut()
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .then(async (_) => {
                    // await removeUserDataFromStore()

                    toast.success(i18n.global.t('components.loginModal.loggedOut'))
                    // app.config.globalProperties.$router.push({ name: 'home' })
                })
                .catch(handleAuthErrors)
        }

        app.config.globalProperties.$signInWithFacebook = async () => {
            const userStore = useUserStore()
            await firebase
                .auth()
                .signInWithPopup(facebookProvider)
                .then(async (result) => {
                    /** @type {firebase.auth.OAuthCredential} */
                    // const credential = result.credential

                    // The signed-in user info.
                    const user = result.user
                    getUserCollection(user.uid)

                    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                    // const accessToken = credential.accessToken

                    // ...
                    // console.log(user.displayName)
                    // console.log(user.photoURL)
                    // console.log(result.additionalUserInfo.isNewUser)
                    // if (result.additionalUserInfo.isNewUser) {
                    //     setDefaultDataToDatabase(extractI18nLocale())
                    // }
                    await userStore.setUserData(user)
                    // authStore.commit("SET_USER_DATA", user);
                    // app.config.globalProperties.$getPreferredLangs()
                    toast.success(i18n.global.t('components.loginModal.signedIn'))
                    // console.log(result)
                })
                .catch(handleAuthErrors)
        }

        app.config.globalProperties.$signInWithGoogle = async () => {
            const userStore = useUserStore()
            await firebase
                .auth()
                .signInWithPopup(googleProvider)
                .then(async (result) => {
                    /** @type {firebase.auth.OAuthCredential} */
                    // const credential = result.credential

                    // This gives you a Google Access Token. You can use it to access the Google API.
                    // const token = credential.accessToken
                    // The signed-in user info.
                    const user = result.user
                    getUserCollection(user.uid)
                    // console.log(user.displayName)
                    // console.log(user.photoURL)
                    await userStore.setUserData(user)
                    // authStore.commit("SET_USER_DATA", user);

                    // if (result.additionalUserInfo.isNewUser) {
                    //     setDefaultDataToDatabase(extractI18nLocale())
                    // }

                    // app.config.globalProperties.$getPreferredLangs()
                    toast.success(i18n.global.t('components.loginModal.signedIn'))
                    // console.log(result)
                })
                .catch(handleAuthErrors)
        }

        app.config.globalProperties.$signInWithApple = async () => {
            const userStore = useUserStore()
            await firebase
                .auth()
                .signInWithPopup(appleProvider)
                .then(async (result) => {
                    /** @type {firebase.auth.OAuthCredential} */
                    // const credential = result.credential

                    // The signed-in user info.
                    const user = result.user
                    getUserCollection(user.uid)

                    // You can also get the Apple OAuth Access and ID Tokens.
                    // const accessToken = credential.accessToken
                    // const idToken = credential.idToken

                    // ...
                    // console.log(user.displayName)
                    // console.log(user.photoURL)
                    await userStore.setUserData(user)
                    // authStore.commit("SET_USER_DATA", user);

                    // if (result.additionalUserInfo.isNewUser) {
                    //     setDefaultDataToDatabase(extractI18nLocale())
                    // }

                    // app.config.globalProperties.$getPreferredLangs()
                    toast.success(i18n.global.t('components.loginModal.signedIn'))
                    // console.log(result)
                })
                .catch(handleAuthErrors)
        }
    },
}

// firebase helpers reexports
const FieldValue = firebase.firestore.FieldValue
const FieldPath = firebase.firestore.FieldPath

export { auth, db, fbAuth, FieldValue, FieldPath }
