<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16" cy="16" r="12" stroke="#798877" stroke-width="2" />
        <path
            d="M10.0365 20V12.828H11.6975L13.5675 16.392L14.2715 17.976H14.3155C14.2861 17.5947 14.2458 17.1693 14.1945 16.7C14.1431 16.2307 14.1175 15.7833 14.1175 15.358V12.828H15.6575V20H13.9965L12.1265 16.425L11.4225 14.863H11.3785C11.4151 15.259 11.4555 15.6843 11.4995 16.139C11.5508 16.5937 11.5765 17.0337 11.5765 17.459V20H10.0365ZM19.0349 16.37L18.8589 17.03H20.3879L20.2229 16.37C20.1275 16.0107 20.0285 15.6293 19.9259 15.226C19.8305 14.8227 19.7352 14.434 19.6399 14.06H19.5959C19.5079 14.4413 19.4162 14.8337 19.3209 15.237C19.2329 15.633 19.1375 16.0107 19.0349 16.37ZM16.4389 20L18.6829 12.828H20.6299L22.8739 20H21.1579L20.7179 18.295H18.5289L18.0889 20H16.4389Z"
            fill="#798877"
        />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
