<template>
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="mask0_5_22"
            style="mask-type: luminance"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="12"
            height="16"
        >
            <path d="M0 0L0 16H12L12 0H0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_5_22)">
            <path d="M5 3.2L5 16H7L7 3.2H5Z" fill="#798877" />
            <path d="M6 0L11 4H1L6 0Z" fill="#798877" />
        </g>
    </svg>
</template>
