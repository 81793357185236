<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
    }
}
const props = defineProps<Props>()
</script>
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 30L0 30" :stroke="props.color.stroke" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M8.69238 13.4927L10.0239 8.60156H11.751L9.59326 15H8.47705L8.69238 13.4927ZM7.48389 8.60156L8.81543 13.4927L9.03955 15H7.91016L5.76562 8.60156H7.48389Z"
            fill="white"
        />
        <path
            d="M16.1929 8.60156H17.7354V12.7808C17.7354 13.2788 17.6313 13.7007 17.4233 14.0464C17.2153 14.3892 16.9238 14.6484 16.5488 14.8242C16.1738 15 15.7329 15.0879 15.2261 15.0879C14.7251 15.0879 14.2842 15 13.9033 14.8242C13.5254 14.6484 13.2295 14.3892 13.0156 14.0464C12.8047 13.7007 12.6992 13.2788 12.6992 12.7808V8.60156H14.2461V12.7808C14.2461 13.0444 14.2842 13.2598 14.3604 13.4268C14.4365 13.5908 14.5479 13.7109 14.6943 13.7871C14.8408 13.8633 15.0181 13.9014 15.2261 13.9014C15.437 13.9014 15.6143 13.8633 15.7578 13.7871C15.9014 13.7109 16.0098 13.5908 16.083 13.4268C16.1562 13.2598 16.1929 13.0444 16.1929 12.7808V8.60156Z"
            fill="white"
        />
    </svg>
</template>
