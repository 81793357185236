<script setup lang="ts">
export interface Props {
    color?: string
}

const props = withDefaults(defineProps<Props>(), {
    color: '#022D05',
})
</script>
<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.5 2.615L19.385 0.5L11 8.885L2.615 0.5L0.5 2.615L8.885 11L0.5 19.385L2.615 21.5L11 13.115L19.385 21.5L21.5 19.385L13.115 11L21.5 2.615Z"
            :fill="props.color"
        />
    </svg>
</template>

<style scoped></style>
