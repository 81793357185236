<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
    }
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 31,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M11.2119 13.814V15.0005H7.97754V13.814H11.2119ZM8.52686 8.60205V15.0005H6.98438V8.60205H8.52686Z"
            fill="white"
        />
        <path
            d="M15.7197 12.834H17.2578C17.2373 13.2793 17.1172 13.6719 16.8975 14.0117C16.6777 14.3516 16.373 14.6167 15.9834 14.8071C15.5967 14.9946 15.1396 15.0884 14.6123 15.0884C14.1846 15.0884 13.8022 15.0166 13.4653 14.873C13.1313 14.7295 12.8472 14.5229 12.6128 14.2534C12.3813 13.9839 12.2041 13.6572 12.0811 13.2734C11.9609 12.8896 11.9009 12.4575 11.9009 11.9771V11.6299C11.9009 11.1494 11.9639 10.7173 12.0898 10.3335C12.2188 9.94678 12.4004 9.61865 12.6348 9.34912C12.8721 9.07666 13.1562 8.86865 13.4873 8.7251C13.8184 8.58154 14.186 8.50977 14.5903 8.50977C15.147 8.50977 15.6172 8.60938 16.001 8.80859C16.3848 9.00781 16.6821 9.28174 16.8931 9.63037C17.104 9.97607 17.2285 10.3701 17.2666 10.8125H15.7285C15.7227 10.5723 15.6816 10.3716 15.6055 10.2104C15.5293 10.0464 15.4092 9.92334 15.2451 9.84131C15.0811 9.75928 14.8628 9.71826 14.5903 9.71826C14.3999 9.71826 14.2344 9.75342 14.0938 9.82373C13.9531 9.89404 13.8359 10.0054 13.7422 10.1577C13.6484 10.3101 13.5781 10.5078 13.5312 10.751C13.4873 10.9912 13.4653 11.2812 13.4653 11.6211V11.9771C13.4653 12.3169 13.4858 12.6069 13.5269 12.8472C13.5679 13.0874 13.6338 13.2837 13.7246 13.436C13.8154 13.5884 13.9326 13.7012 14.0762 13.7744C14.2227 13.8447 14.4014 13.8799 14.6123 13.8799C14.8467 13.8799 15.0444 13.8433 15.2056 13.77C15.3667 13.6938 15.4912 13.5796 15.5791 13.4272C15.667 13.272 15.7139 13.0742 15.7197 12.834Z"
            fill="white"
        />
    </svg>
</template>
