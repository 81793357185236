import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes.js'
import { i18n } from './i18n'

import 'firebase/auth'
import { LoginHash, RegisterHash, AccountHash } from './hooks/authModalHook'
import { useUserStore } from './stores/users/UserStore'
let isLoggedIn = false

// Define your wildcard route

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // If only the locale parameter is changed, maintain the current scroll position
        if (to.matched.every((routeRecord, i) => routeRecord === from.matched[i])) {
            return savedPosition
        }
        if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ el: to.hash, behavior: 'smooth' })
                }, 500)
            })
        }
        if (savedPosition) {
            // return savedPosition
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ top: savedPosition.top, behavior: 'smooth' })
                }, 500)
            })
        }
        if (to.matched.some((r) => r.meta.noScroll)) {
            return { top: 0, behavior: 'smooth' }
        }
    },
})

// let triedToLogin = false

// let initializedFromUserdata = false;

async function handleAuthStateChange(user) {
    const userStore = useUserStore()
    // triedToLogin = true

    if (user) {
        isLoggedIn = true
    } else {
        isLoggedIn = false
    }
    userStore.triedToLogin = true

    // handle email verification (TMP, only optional verification)
    if (user?.emailVerified == false) {
        router.push({
            name: 'verifyemail',
            replace: router.currentRoute.value.hash == LoginHash,
        })
    }
}

export function notifyAuthStateChanged(user) {
    //force close auth modal
    if (router.currentRoute.value.hash == LoginHash || router.currentRoute.value.hash == RegisterHash || router.currentRoute.value.hash == AccountHash) {
        router.replace({ hash: '' })
    }

    // initial call
    handleAuthStateChange(user)
}

router.beforeResolve((to, from, next) => {
    const userStore = useUserStore()

    let locale = i18n.global.locale.value
    console.log('i18n locale', locale)
    console.log('to params locale', to.params.locale)

    if (i18n.global.availableLocales.indexOf(locale) == -1) {
        // if old locale cached and is not available in current run, use first available locale
        locale = i18n.global.availableLocales[0]
    }
    console.log('i18n locale after availability check', locale)

    // Check if the locale is in the URL (DO NOT CHECK FROM ROUTE PARAM, ROUTE PARAM MAY BE MISMATCHED!!!)
    const hasLocale = to.path.split('/').includes(locale)

    console.log(i18n.global.availableLocales.indexOf(to.params.locale), to.params.locale)

    // If the locale in the URL is not valid, add it and end the middleware
    if (i18n.global.availableLocales.indexOf(to.params.locale) == -1) {
        // provide locale if there is none
        if (!hasLocale) {
            const redirectTo = to.redirectedFrom?.fullPath ?? to.fullPath ?? '' // redirect to original route or to root path (empty string)
            console.log('Redirect to', redirectTo)
            next({
                path: `/${locale}${redirectTo}`,
                params: { locale },
            })
            return
        } else {
            console.log('Replace locale to', locale)
            next({ name: to.name, params: { ...to.params, locale }, replace: true })
        }
        return
    }

    // EMAIL ACTION LOCK
    if (from.name == 'emailaction' && from?.query?.mode != 'resetpassword') {
        return
    }

    // AUTH GUARD
    if (userStore.triedToLogin && to.meta.auth && !isLoggedIn) {
        next({ name: 'home' })
        return
    }

    // Redirect if user is verified and trying to access 'verifyemail' route
    if (userStore.userData?.emailVerified == true && to.name == 'verifyemail') {
        next({ name: 'home' })
        return
    }

    // Redirect if user is not logged in and trying to access certain routes
    const routesToRedirectIfNotLoggedIn = ['homelogged']
    if (routesToRedirectIfNotLoggedIn.includes(to.name) && !isLoggedIn) {
        next({ name: 'home', params: { locale } })
        return
    }

    // If the route has a "locale" parameter
    if (to.params.locale) {
        // If the locale parameter is already correct, proceed with navigation
        if (to.params.locale === locale) {
            next()
        }
        // If the locale parameter is not correct, but it's already in the URL, change locale and save to localstorage
        else {
            i18n.global.locale.value = to.params.locale
            localStorage.setItem('eupure_locale', to.params.locale)
            next()
        }
    }
    // If the route does not have a "locale" parameter, add it and redirect
    else if (to.name) {
        next({ name: to.name, params: { ...to.params, locale }, replace: true })
    }
    // If the route does not have a name, proceed with navigation
    else {
        next()
    }
})
export default router
