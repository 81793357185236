<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2969_27186)">
            <rect x="4.5" y="8.25" width="9" height="1.5" fill="#A1C413" />
            <path d="M1.5 9L5.25 5.25V12.75L1.5 9Z" fill="#A1C413" />
            <path d="M16.5 9L12.75 12.75V5.25L16.5 9Z" fill="#A1C413" />
        </g>
        <defs>
            <clipPath id="clip0_2969_27186">
                <rect width="15" height="9" fill="white" transform="translate(1.5 4.5)" />
            </clipPath>
        </defs>
    </svg>
</template>
