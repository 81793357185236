<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38394)">
            <g clip-path="url(#clip1_3632_38394)">
                <path
                    d="M105.415 38.1025L93.2069 24.3409L101.346 18.332H79.0677L57.5571 42.0566H18.3335C14.6889 42.0669 11.1967 43.4784 8.61998 45.9828C6.04325 48.4872 4.59152 51.8808 4.58203 55.4221L4.58203 72.5125H14.9436V54.284C14.9496 52.9069 15.4552 51.576 16.3713 50.5253C17.2874 49.4746 18.5555 48.7714 19.9525 48.5393V96.2487H30.6019V69.0594H64.145V96.2487H74.7944V68.0884L79.9112 53.2197C83.1243 53.7391 86.4234 53.1661 89.2524 51.5975C92.0814 50.0289 94.2672 47.5605 95.4416 44.6085L97.7001 46.0107L105.415 38.1025ZM81.7341 49.5142C81.5422 49.5142 81.3623 49.4715 81.1744 49.4599L85.056 38.1957L91.9477 42.4683C91.2023 44.5237 89.8203 46.3044 87.9916 47.566C86.1628 48.8276 83.9769 49.5082 81.7341 49.5142Z"
                    fill="#022D05"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_3632_38394">
                <rect width="110" height="110" fill="white" />
            </clipPath>
            <clipPath id="clip1_3632_38394">
                <rect
                    width="100.833"
                    height="77.9167"
                    fill="white"
                    transform="translate(4.58203 18.332)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
