<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_376)">
            <path
                d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                fill="#ADBCAA"
            />
            <path
                d="M19.3867 15.7031V11.9727C19.3867 11.9727 19.3672 10.6758 18.2852 10.2305L16.6133 6.69141C16.3281 6.08203 15.6523 6.08203 15.6523 6.08203H8.43359C7.75781 6.08203 7.46875 6.69141 7.46875 6.69141L5.76562 10.2617C5.25391 10.4805 4.69922 10.9453 4.69922 11.9727V15.7031H19.3867ZM7.23437 11.418C7.875 11.418 8.39062 11.9375 8.39062 12.5742C8.39062 13.2148 7.875 13.7344 7.23437 13.7344C6.59375 13.7344 6.07422 13.2148 6.07422 12.5742C6.07422 11.9375 6.59375 11.418 7.23437 11.418ZM16.8789 10.0703H7.17969L8.5 7.10547H15.5859L16.8789 10.0703ZM18.0117 12.5742C18.0117 13.2148 17.4922 13.7344 16.8516 13.7344C16.2109 13.7344 15.6953 13.2148 15.6953 12.5742C15.6953 11.9375 16.2109 11.418 16.8516 11.418C17.4922 11.418 18.0117 11.9375 18.0117 12.5742Z"
                fill="#3F674D"
            />
            <path
                d="M7.01562 16.8008C7.01562 16.2656 7.01562 15.5781 7.01562 15.0859"
                stroke="#3F674D"
                stroke-width="2.37617"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.1211 16.8008C17.1211 16.2656 17.1211 15.5781 17.1211 15.0859"
                stroke="#3F674D"
                stroke-width="2.37617"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_376">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
