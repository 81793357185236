<template>
    <svg
        width="140"
        height="140"
        viewBox="0 0 140 140"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle opacity="0.1" cx="70" cy="70" r="70" fill="#808080" />
        <circle cx="70" cy="70" r="60" fill="#808080" />
        <g clip-path="url(#clip0_4343_92627)">
            <path d="M86 58H54V62H86V58Z" fill="white" />
            <path d="M86 66H54V70H86V66Z" fill="white" />
            <path d="M75 74H54V78H75V74Z" fill="white" />
            <path
                d="M92 47H48C46.9391 47 45.9217 47.4214 45.1716 48.1716C44.4214 48.9217 44 49.9391 44 51V103.5L60.111 89H92C93.0609 89 94.0783 88.5786 94.8284 87.8284C95.5786 87.0783 96 86.0609 96 85V51C96 49.9391 95.5786 48.9217 94.8284 48.1716C94.0783 47.4214 93.0609 47 92 47ZM92 85H58.576L48 94.519V51H92V85Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4343_92627">
                <rect width="52" height="56.5" fill="white" transform="translate(44 47)" />
            </clipPath>
        </defs>
    </svg>
</template>
