<template>
    <svg
        width="111"
        height="110"
        viewBox="0 0 111 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38580)">
            <path
                d="M68.063 27.8548C69.8438 28.1942 71.6852 27.998 73.3544 27.291C75.0237 26.584 76.4458 25.3979 77.441 23.8827C78.4362 22.3675 78.9599 20.5912 78.9457 18.7784C78.9314 16.9657 78.3801 15.1978 77.3612 13.6984C76.3424 12.199 74.9018 11.0353 73.2217 10.3545C71.5416 9.67376 69.6973 9.50642 67.9221 9.8737C66.1469 10.241 64.5204 11.1264 63.2484 12.4179C61.9763 13.7095 61.1157 15.3492 60.7755 17.1298C60.3266 19.5177 60.8412 21.9861 62.2067 23.9957C63.5722 26.0054 65.6777 27.3928 68.063 27.8548Z"
                fill="#022D05"
            />
            <path
                d="M95.9855 85.7361C91.988 89.557 87.0254 92.2173 81.6305 93.4315L97.8463 44.1927L91.3196 42.0477L89.633 47.1765C85.9388 47.7586 79.0913 48.2307 75.7501 44.4861C73.753 41.8505 72.0053 39.0349 70.5296 36.0757C69.6865 33.8987 68.0956 32.0932 66.0421 30.9829C63.9885 29.8725 61.6066 29.5299 59.3234 30.0165L34.7476 35.2919L28.7617 50.6277L7.62797 42.0386L5.04297 48.4048L38.8817 62.1548L41.4667 55.7886L37.053 53.9919L41.343 43.1202L51.3392 41.2869L29.903 94.229H8.54922V101.562H74.8838C84.6453 101.545 94.0253 97.7699 101.078 91.0206L95.9855 85.7361ZM67.3763 50.1786C70.0517 52.4275 73.1859 54.0652 76.5599 54.9772C79.9339 55.8892 83.4664 56.0535 86.9105 55.4586L74.1367 94.2473H67.7201L75.1176 71.6057L61.3309 64.6711L67.3763 50.1786ZM50.6334 72.0457L63.0542 76.9086L57.1555 94.229H41.1138L50.6334 72.0457Z"
                fill="#022D05"
            />
        </g>
        <defs>
            <clipPath id="clip0_3632_38580">
                <rect
                    width="104.981"
                    height="101.558"
                    fill="white"
                    transform="translate(5.04297)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
