<script setup lang="ts">
interface Props {
    height?: number
    width?: number
    viewBox?: string
    color?: string
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
    viewBox: '0 0 32 32',
    color: '#798877',
})
</script>
<template>
    <svg
        :width="props.width"
        :height="props.height"
        :viewBox="props.viewBox"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.9974 5.33073H26.9307C28.2507 5.33073 29.3307 6.41073 29.3307 7.73073V24.2641C29.3307 25.5841 28.2507 26.6641 26.9307 26.6641H5.06406C3.74406 26.6641 2.66406 25.5841 2.66406 24.2641V7.73073C2.66406 6.41073 3.74406 5.33073 5.06406 5.33073H7.9974V2.66406H10.6641V5.33073H21.3307V2.66406H23.9974V5.33073ZM5.32902 23.9975H26.6623V10.6642H5.32902V23.9975Z"
            :fill="props.color"
        />
    </svg>
</template>
