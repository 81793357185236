<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
        strokeTwo: string
    }
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 31,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.strokeTwo" />
        <path
            d="M6.87359 15V9.15H8.60159C9.19559 9.15 9.71759 9.249 10.1676 9.447C10.6236 9.639 10.9776 9.948 11.2296 10.374C11.4816 10.8 11.6076 11.358 11.6076 12.048C11.6076 12.738 11.4816 13.302 11.2296 13.74C10.9776 14.172 10.6326 14.49 10.1946 14.694C9.75659 14.898 9.25559 15 8.69159 15H6.87359ZM8.42159 13.758H8.51159C8.79359 13.758 9.04859 13.71 9.27659 13.614C9.50459 13.518 9.68459 13.347 9.81659 13.101C9.95459 12.855 10.0236 12.504 10.0236 12.048C10.0236 11.592 9.95459 11.247 9.81659 11.013C9.68459 10.773 9.50459 10.611 9.27659 10.527C9.04859 10.437 8.79359 10.392 8.51159 10.392H8.42159V13.758ZM12.648 15V9.15H14.376C14.97 9.15 15.492 9.249 15.942 9.447C16.398 9.639 16.752 9.948 17.004 10.374C17.256 10.8 17.382 11.358 17.382 12.048C17.382 12.738 17.256 13.302 17.004 13.74C16.752 14.172 16.407 14.49 15.969 14.694C15.531 14.898 15.03 15 14.466 15H12.648ZM14.196 13.758H14.286C14.568 13.758 14.823 13.71 15.051 13.614C15.279 13.518 15.459 13.347 15.591 13.101C15.729 12.855 15.798 12.504 15.798 12.048C15.798 11.592 15.729 11.247 15.591 11.013C15.459 10.773 15.279 10.611 15.051 10.527C14.823 10.437 14.568 10.392 14.286 10.392H14.196V13.758Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
