<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16" cy="16" r="12" stroke="#798877" stroke-width="2" />
        <path
            d="M12.9714 16.37L12.7954 17.03H14.3244L14.1594 16.37C14.0641 16.0107 13.9651 15.6293 13.8624 15.226C13.7671 14.8227 13.6717 14.434 13.5764 14.06H13.5324C13.4444 14.4413 13.3527 14.8337 13.2574 15.237C13.1694 15.633 13.0741 16.0107 12.9714 16.37ZM10.3754 20L12.6194 12.828H14.5664L16.8104 20H15.0944L14.6544 18.295H12.4654L12.0254 20H10.3754ZM17.5171 20V18.691H19.0351V14.654H17.7261V13.653C18.1074 13.5797 18.4301 13.4917 18.6941 13.389C18.9581 13.2863 19.2147 13.1617 19.4641 13.015H20.6521V18.691H21.9611V20H17.5171Z"
            fill="#798877"
        />
    </svg>
</template>
