import { Composer, IntlDateTimeFormat, IntlNumberFormat, useI18n, VueMessageType } from 'vue-i18n'
import { ref } from 'vue'
// import { useUserStore } from '../stores/users/UserStore'
// import { usePreferenciesStore } from '../stores/preferencies/preferencies'
// import { storeToRefs } from 'pinia'
import { LocaleMessage } from '@intlify/core-base'
import { i18n } from '@/i18n'

export function useDatefnsLocalization() {
    const i18n = useI18n()
    const fallbackLanguage = 'cs'
    const activeLanguage = ref(i18n.locale.value)
    const formatAllowedLanguages = ['cs', 'en']

    formatAllowedLanguages.forEach((lang: string) => {
        if (activeLanguage.value === lang) {
            activeLanguage.value = lang
        } else {
            activeLanguage.value = fallbackLanguage
        }
    })
    // Remove quotes from activeLanguage
    activeLanguage.value = activeLanguage.value.replace(/['"]+/g, '')
    console.log('activeLanguage.value', activeLanguage.value)
    return { activeLanguage }
}

// export function useComputedLocalizationKey(options: any, prefix = 'name:') {
//     const i18n = useI18n()
//     const optionsGetter = ref(options)

//     computed(() => {
//         return getLocalizationKey(optionsGetter.value, prefix, i18n)
//     })
// }

// helper functions

// TODO: remove - obsolete functionality
export function getLocalizationKey(
    options: string[],
    prefix = 'name:',
    i18n: Composer<
        { [x: string]: LocaleMessage<VueMessageType> },
        { [x: string]: IntlDateTimeFormat },
        { [x: string]: IntlNumberFormat },
        string,
        string,
        string
    >
) {
    if (options.length <= 0) return ''

    // try to build 'mainLocaleKey' from .value property, otherwise use direct proeprty to ensure backwards compatability (someone may pass global i18n instance into this function)
    let mainLocaleKey = ''
    if (i18n.locale?.value != undefined) {
        mainLocaleKey = `${prefix}${i18n.locale.value}`
    } else {
        mainLocaleKey = `${prefix}${i18n.locale}`
    }

    if (options.includes(mainLocaleKey)) {
        return mainLocaleKey
    }
    if (i18n.fallbackLocale) {
        const fallbackKeys = Object.keys(i18n.fallbackLocale).sort((a, b) => Number(a) - Number(b))
        for (const key of fallbackKeys) {
            const localeKey = `${prefix}${key}`
            if (options.includes(localeKey)) {
                return localeKey
            }
        }
    }

    const firstFallbackKey = options.find((k: string) => k.startsWith(prefix))
    if (firstFallbackKey) {
        return firstFallbackKey
    }

    return options[0]
}

//keys = poi or trip object
//options = locale options to choose from
// export function getLocalizationKeyWithOptions(
//     keys: string | any[],
//     options: string | string[],
//     currentlySelectedLocale: any,
//     prefix = 'name:'
// ) {
//     if (!keys) return ''

//     if (keys.length <= 0) return ''

//     const mainLocaleKey = `${prefix}${currentlySelectedLocale}`
//     if (keys.includes(mainLocaleKey)) return mainLocaleKey

//     for (let key of keys) {
//         key = key.split(prefix)[1]
//         const localeKey = `${prefix}${key}`
//         if (options.includes(localeKey)) return localeKey
//     }

//     return keys[0]
// }

// export function filterPrefix(object: {}, prefix = 'name') {
//     return Object.keys(object).filter((n) => n.startsWith(prefix))
// }

// export function extractPreferredLanguages(
//     i18n: Composer<
//         { [x: string]: LocaleMessage<VueMessageType> },
//         { [x: string]: IntlDateTimeFormat },
//         { [x: string]: IntlNumberFormat },
//         string,
//         string,
//         string
//     >,
//     // store,
//     includeAnonymousFallback = true
// ) {
//     const { isLoggedIn } = storeToRefs(useUserStore())
//     const { preferedLanguages } = storeToRefs(usePreferenciesStore())
//     // const preferredLangs = computed(() => store?.getters?.PreferredLanguages);
//     // const isLoggedIn = computed(() => store?.getters?.IsLoggedIn);

//     const selectedLocale = i18n?.locale?.value
//     if (isLoggedIn) {
//         return preferedLanguages
//     }

//     const languages = ref([selectedLocale])
//     if (includeAnonymousFallback) {
//         // selectedLocale !== 'en' ? null : languages.value.push('en');
//         selectedLocale == 'en' ? null : languages.value.push('en')
//     }

//     return languages
// }

// export function getPreferredLanguagesQueryParams(
//     i18n: Composer<
//         { [x: string]: LocaleMessage<VueMessageType> },
//         { [x: string]: IntlDateTimeFormat },
//         { [x: string]: IntlNumberFormat },
//         string,
//         string,
//         string
//     >,
//     store: boolean | undefined
// ) {
//     const preferredLanguages = extractPreferredLanguages(i18n, store)

//     let params = ''
//     preferredLanguages.value.map((lang) => {
//         params += `&languages[]=${lang}`
//     })

//     return params
// }
