<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
    }
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 31,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M9.60189 12.156L9.50289 12.561H10.5829L10.4839 12.156C10.4119 11.874 10.3399 11.568 10.2679 11.238C10.1959 10.908 10.1239 10.596 10.0519 10.302H10.0159C9.95589 10.596 9.88989 10.908 9.81789 11.238C9.74589 11.568 9.67389 11.874 9.60189 12.156ZM7.35189 15L9.13389 9.15H11.0059L12.7879 15H11.1499L10.8619 13.758H9.22389L8.93589 15H7.35189ZM13.2918 15V13.758H14.5158V10.806H13.4358V9.852C13.7598 9.786 14.0358 9.711 14.2638 9.627C14.4918 9.537 14.7138 9.426 14.9298 9.294H16.0638V13.758H17.0898V15H13.2918Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
