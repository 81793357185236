<template>
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="60" cy="60" r="55" fill="#A1C413" stroke="white" stroke-width="10" />
        <g clip-path="url(#clip0_4950_26948)">
            <path
                d="M80.9418 78.8443L78.0101 65.6493V59.166C78.0101 58.282 77.6589 57.4341 77.0338 56.809C76.4087 56.1839 75.5608 55.8327 74.6768 55.8327H65.5101V39.9993C65.5101 38.4523 64.8955 36.9685 63.8016 35.8746C62.7076 34.7806 61.2239 34.166 59.6768 34.166C58.1297 34.166 56.6459 34.7806 55.552 35.8746C54.458 36.9685 53.8434 38.4523 53.8434 39.9993V55.8327H44.6768C43.7927 55.8327 42.9449 56.1839 42.3198 56.809C41.6946 57.4341 41.3434 58.282 41.3434 59.166V65.6493L38.4118 78.8435C38.307 79.284 38.3029 79.7425 38.4001 80.1847C38.4972 80.627 38.6929 81.0416 38.9726 81.3977C39.2594 81.7479 39.6215 82.0289 40.032 82.2197C40.4425 82.4104 40.8908 82.506 41.3434 82.4993H78.0101C78.4634 82.5105 78.9131 82.4169 79.3243 82.2258C79.7355 82.0347 80.097 81.7513 80.3808 81.3976C80.6645 81.0439 80.8627 80.6295 80.96 80.1866C81.0573 79.7438 81.0511 79.2844 80.9418 78.8443ZM57.1768 39.9993C57.1768 39.3363 57.4402 38.7004 57.909 38.2316C58.3778 37.7627 59.0137 37.4993 59.6768 37.4993C60.3398 37.4993 60.9757 37.7627 61.4445 38.2316C61.9134 38.7004 62.1768 39.3363 62.1768 39.9993V55.8327H57.1768V39.9993ZM44.6768 59.166H74.6768V64.166H44.6768V59.166ZM70.5101 79.166V74.166C70.5101 73.724 70.3345 73.3001 70.022 72.9875C69.7094 72.6749 69.2855 72.4993 68.8434 72.4993C68.4014 72.4993 67.9775 72.6749 67.6649 72.9875C67.3524 73.3001 67.1768 73.724 67.1768 74.166V79.166H61.3434V74.166C61.3434 73.724 61.1678 73.3001 60.8553 72.9875C60.5427 72.6749 60.1188 72.4993 59.6768 72.4993C59.2347 72.4993 58.8108 72.6749 58.4983 72.9875C58.1857 73.3001 58.0101 73.724 58.0101 74.166V79.166H52.1768V74.166C52.1768 73.724 52.0012 73.3001 51.6886 72.9875C51.3761 72.6749 50.9521 72.4993 50.5101 72.4993C50.0681 72.4993 49.6442 72.6749 49.3316 72.9875C49.019 73.3001 48.8434 73.724 48.8434 74.166V79.166H41.7543L44.3426 67.4993H75.0093L77.6009 79.166H70.5101Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4950_26948">
                <rect
                    width="42.6867"
                    height="48.3333"
                    fill="white"
                    transform="translate(38.333 34.167)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
