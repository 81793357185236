<template>
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="60" cy="60" r="59" fill="white" stroke="#E4EBE6" stroke-width="2" />
        <mask
            id="mask0_4219_77500"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="120"
            height="120"
        >
            <circle cx="60" cy="60" r="59" fill="white" stroke="#E4EBE6" stroke-width="2" />
        </mask>
        <g mask="url(#mask0_4219_77500)">
            <path
                d="M109.984 110.734C109.961 102.583 106.712 94.7723 100.948 89.0085C95.1843 83.2446 87.3736 79.9961 79.2223 79.9727H40.7618C32.6104 79.9961 24.7997 83.2446 19.0358 89.0085C13.2719 94.7723 10.0234 102.583 10 110.734L10 120H109.984V110.734Z"
                fill="#E4EBE6"
            />
            <path
                d="M59.9921 69.992C64.9358 69.992 69.7686 68.526 73.8791 65.7794C77.9897 63.0328 81.1935 59.129 83.0854 54.5616C84.9773 49.9942 85.4723 44.9683 84.5078 40.1195C83.5433 35.2708 81.1627 30.8169 77.6669 27.3212C74.1712 23.8254 69.7173 21.4448 64.8686 20.4803C60.0198 19.5158 54.994 20.0108 50.4265 21.9027C45.8591 23.7946 41.9553 26.9984 39.2087 31.109C36.4621 35.2196 34.9961 40.0523 34.9961 44.996C34.9961 51.6254 37.6296 57.9832 42.3173 62.6709C47.0049 67.3585 53.3627 69.992 59.9921 69.992Z"
                fill="#E4EBE6"
            />
        </g>
    </svg>
</template>
