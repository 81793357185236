<template>
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="60" cy="60" r="55" fill="#A1C413" stroke="white" stroke-width="10" />
        <g clip-path="url(#clip0_4950_34946)">
            <path
                d="M60.001 51.666C58.0232 51.666 56.0898 52.2525 54.4453 53.3513C52.8008 54.4501 51.5191 56.0119 50.7622 57.8392C50.0053 59.6664 49.8073 61.6771 50.1931 63.6169C50.579 65.5567 51.5314 67.3386 52.9299 68.7371C54.3284 70.1356 56.1103 71.088 58.0501 71.4739C59.9899 71.8597 62.0006 71.6617 63.8278 70.9048C65.6551 70.1479 67.2169 68.8662 68.3157 67.2217C69.4145 65.5772 70.001 63.6438 70.001 61.666C69.9981 59.0147 68.9436 56.4729 67.0689 54.5981C65.1941 52.7234 62.6523 51.6689 60.001 51.666ZM60.001 68.3327C58.6824 68.3327 57.3935 67.9417 56.2972 67.2091C55.2009 66.4766 54.3464 65.4354 53.8418 64.2172C53.3372 62.9991 53.2052 61.6586 53.4624 60.3654C53.7196 59.0722 54.3546 57.8843 55.2869 56.952C56.2193 56.0196 57.4072 55.3847 58.7004 55.1274C59.9936 54.8702 61.334 55.0022 62.5522 55.5068C63.7704 56.0114 64.8116 56.8659 65.5441 57.9622C66.2767 59.0585 66.6676 60.3475 66.6676 61.666C66.6657 63.4335 65.9626 65.1281 64.7128 66.3779C63.463 67.6277 61.7685 68.3307 60.001 68.3327Z"
                fill="white"
            />
            <path
                d="M78.334 45.8327H69.7973L65.0723 41.666H54.9307L50.204 45.8327H41.6673C40.7833 45.8327 39.9354 46.1839 39.3103 46.809C38.6852 47.4341 38.334 48.282 38.334 49.166V74.9993C38.334 75.8834 38.6852 76.7312 39.3103 77.3564C39.9354 77.9815 40.7833 78.3327 41.6673 78.3327H78.334C79.218 78.3327 80.0659 77.9815 80.691 77.3564C81.3161 76.7312 81.6673 75.8834 81.6673 74.9993V49.166C81.6673 48.282 81.3161 47.4341 80.691 46.809C80.0659 46.1839 79.218 45.8327 78.334 45.8327ZM78.334 74.9993H41.6673V49.166H51.464L56.1907 44.9993H63.8132L68.5373 49.166H78.334V74.9993Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4950_34946">
                <rect
                    width="43.3333"
                    height="36.6667"
                    fill="white"
                    transform="translate(38.333 41.667)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
