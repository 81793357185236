<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.0039 4.00391H20.2039C21.1939 4.00391 22.0039 4.81391 22.0039 5.80391V18.2039C22.0039 19.1939 21.1939 20.0039 20.2039 20.0039H3.80391C2.81391 20.0039 2.00391 19.1939 2.00391 18.2039V5.80391C2.00391 4.81391 2.81391 4.00391 3.80391 4.00391H6.00391V2.00391H8.00391V4.00391H16.0039V2.00391H18.0039V4.00391ZM4.00524 18.0027H20.0052V8.00267H4.00524V18.0027Z"
            fill="#798877"
        />
    </svg>
</template>
