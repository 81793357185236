<script setup lang="ts">
import { cookiesAllowed, allowCookies } from '../../helpers/cookies.helper'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
</script>

<template>
    <div class="cookies-dialog" v-if="cookiesAllowed == undefined">
        <p>
            {{ $t('cookies.notice') }}
            <br />
            <br />
            {{ $t('cookies.more') }}
            <router-link
                :to="{ name: 'generalPage', params: { componentName: 'dataProtection', locale } }"
                target="_blank"
                >{{ $t('router.privacyPolicy') }}</router-link
            >
        </p>

        <div class="cookies-dialog__button-wrapper">
            <button class="" @click="allowCookies(false)">
                {{ $t('cookies.decline') }}
            </button>
            <button class="" @click="allowCookies(true)">
                {{ $t('cookies.allow') }}
            </button>
        </div>
    </div>
</template>

<style lang="scss">
.cookies-dialog {
    position: fixed;
    right: 24px;
    bottom: 24px;
    background: $epu-green-dark;
    padding: 32px;
    max-width: calc(539px - 64px);
    min-height: calc(250px - 64px);

    display: grid;
    grid-auto-flow: row;
    row-gap: 28px;

    z-index: 99999;

    box-shadow: 0px 4px 12px -6px rgba(0, 0, 0, 0.5);
    color: $white;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.5px;
    @media only screen and (max-width: $sm) {
        min-width: 80%;
        max-width: 80%;
        padding: 10% 5%;
        // right: 24px;
        right: 50%;
        transform: translateX(50%);
    }

    a {
        text-decoration: none;
        color: $epu-green;
    }
    &__button-wrapper {
        display: flex;
        justify-content: flex-start;
        @media only screen and (max-width: $sm) {
            flex-direction: column;
            // align-items: center  ;
        }
        button {
            padding: 18px 33px;
            color: #000;
            text-align: center;
            font-family: Source Sans Pro;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            cursor: pointer;
            background-color: $epu-green;
            outline: none;
            border: none;
            &:first-of-type {
                margin-right: auto;
                @media only screen and (max-width: $sm) {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }
            &:hover {
                color: $white;
            }
        }
    }
}
</style>
