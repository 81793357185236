import { ref } from 'vue'
import { mapStoreEPU } from '../stores/map/mapStoreEpu'

const LOCALSTORAGE_KEY = 'cookiesAllowed'

export const cookiesAllowed = ref(
    localStorage.getItem(LOCALSTORAGE_KEY) == 'true' ? 'true' : undefined
)

export function allowCookies(status = 'true') {
    const storeMapEpu = mapStoreEPU()
    storeMapEpu.getCurrentPosition()
    cookiesAllowed.value = status
    localStorage.setItem(LOCALSTORAGE_KEY, status)
}
