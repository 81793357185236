<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_409)">
            <path
                d="M0.0703125 12.0625C0.0703125 18.6836 5.4375 24.0547 12.0586 24.0547C18.6836 24.0547 24.0508 18.6836 24.0508 12.0625C24.0508 5.44141 18.6836 0.0742188 12.0586 0.0742188C5.4375 0.0742188 0.0703125 5.44141 0.0703125 12.0625Z"
                fill="#ADBCAA"
            />
            <path
                d="M3.55077 11.9766C3.55077 11.9766 3.39843 9.82031 8.08593 8.6875C8.66015 8.55078 10.6914 8.07031 11.3086 7.71093C13.9297 6.17187 12.1484 3.90624 12.1484 3.90624"
                stroke="#3F674D"
                stroke-width="0.622695"
                stroke-linecap="round"
            />
            <path
                d="M4.90625 11.9805C4.90625 11.9805 4.52734 10.6133 9.38672 9.51172C9.96094 9.38281 11.4883 8.9414 11.8555 8.76562C15.5234 6.98827 13.4766 3.84765 13.4766 3.84765"
                stroke="#3F674D"
                stroke-width="0.622695"
                stroke-linecap="round"
            />
            <path
                d="M5.63281 14.4062C9.45704 14.4062 14.4219 14.4062 17.9805 14.4062"
                stroke="#3F674D"
                stroke-width="3.10009"
            />
            <path d="M3.55078 12.8633V15.9727" stroke="#3F674D" stroke-width="0.734695" />
            <path d="M4.78125 12.8633V15.9727" stroke="#3F674D" stroke-width="0.734695" />
            <path
                d="M18.3984 14.4062C19.2891 14.4062 20.4492 14.4062 21.2813 14.4062"
                stroke="#3F674D"
                stroke-width="3.10009"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_409">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
