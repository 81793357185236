<template>
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="60" cy="60" r="55" fill="#808080" stroke="white" stroke-width="10" />
        <g clip-path="url(#clip0_5001_27035)">
            <path d="M73.3337 49.9971H46.667V53.3304H73.3337V49.9971Z" fill="white" />
            <path d="M73.3337 56.6631H46.667V59.9964H73.3337V56.6631Z" fill="white" />
            <path d="M64.167 63.3301H46.667V66.6634H64.167V63.3301Z" fill="white" />
            <path
                d="M78.334 40.8301H41.6673C40.7833 40.8301 39.9354 41.1813 39.3103 41.8064C38.6852 42.4315 38.334 43.2794 38.334 44.1634V87.9134L51.7598 75.8301H78.334C79.218 75.8301 80.0659 75.4789 80.691 74.8538C81.3161 74.2286 81.6673 73.3808 81.6673 72.4967V44.1634C81.6673 43.2794 81.3161 42.4315 80.691 41.8064C80.0659 41.1813 79.218 40.8301 78.334 40.8301ZM78.334 72.4967H50.4807L41.6673 80.4292V44.1634H78.334V72.4967Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_5001_27035">
                <rect
                    width="43.3333"
                    height="47.0833"
                    fill="white"
                    transform="translate(38.333 40.833)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
