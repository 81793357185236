// regex match for email
export const emailRegex = new RegExp(
    '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'
)
// regex match for name length (min 3)
export const userNameRegex = /^[\p{L}0-9 ]{3,}$/u
// regex match for password length (min 6) one number, one letter, one special character
export const passwordRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
)
