<template>
    <div class="loader">
        <img src="/svgs/epu_logo.svg" alt="Logo" />

        <div class="circle">
            <svg class="circular-loader" viewBox="25 25 50 50">
                <circle
                    class="loader-path"
                    cx="50"
                    cy="50"
                    r="20"
                    fill="none"
                    stroke="#798877"
                    stroke-width="0.75"
                />
            </svg>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.loader {
    width: 100vw;
    height: 100vh;

    position: fixed !important;
    left: 0;
    top: 0;
    z-index: 99999999;
}
</style>
