<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
    }
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 31,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M9.60189 12.156L9.50289 12.561H10.5829L10.4839 12.156C10.4119 11.874 10.3399 11.568 10.2679 11.238C10.1959 10.908 10.1239 10.596 10.0519 10.302H10.0159C9.95589 10.596 9.88989 10.908 9.81789 11.238C9.74589 11.568 9.67389 11.874 9.60189 12.156ZM7.35189 15L9.13389 9.15H11.0059L12.7879 15H11.1499L10.8619 13.758H9.22389L8.93589 15H7.35189ZM14.9118 15.108C14.4498 15.108 14.0478 15.036 13.7058 14.892C13.3698 14.748 13.0938 14.55 12.8778 14.298L13.5798 13.326C13.7538 13.488 13.9398 13.62 14.1378 13.722C14.3418 13.818 14.5458 13.866 14.7498 13.866C15.0018 13.866 15.2028 13.821 15.3528 13.731C15.5028 13.641 15.5778 13.506 15.5778 13.326C15.5778 13.182 15.5418 13.059 15.4698 12.957C15.3978 12.855 15.2598 12.777 15.0558 12.723C14.8578 12.669 14.5578 12.642 14.1558 12.642V11.562C14.6358 11.562 14.9628 11.505 15.1368 11.391C15.3108 11.271 15.3978 11.112 15.3978 10.914C15.3978 10.566 15.1998 10.392 14.8038 10.392C14.6178 10.392 14.4468 10.431 14.2908 10.509C14.1408 10.587 13.9758 10.704 13.7958 10.86L13.0218 9.924C13.3038 9.69 13.5948 9.51 13.8948 9.384C14.2008 9.252 14.5278 9.186 14.8758 9.186C15.5058 9.186 16.0068 9.327 16.3788 9.609C16.7568 9.885 16.9458 10.281 16.9458 10.797C16.9458 11.349 16.6398 11.76 16.0278 12.03V12.066C16.3518 12.162 16.6158 12.324 16.8198 12.552C17.0238 12.78 17.1258 13.074 17.1258 13.434C17.1258 13.794 17.0238 14.1 16.8198 14.352C16.6158 14.598 16.3458 14.787 16.0098 14.919C15.6738 15.045 15.3078 15.108 14.9118 15.108Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
