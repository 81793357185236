<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M8.77988 11.84L8.66988 12.29H9.86988L9.75988 11.84C9.67988 11.5267 9.59988 11.1867 9.51988 10.82C9.43988 10.4533 9.35988 10.1067 9.27988 9.78H9.23988C9.17322 10.1067 9.09988 10.4533 9.01988 10.82C8.93988 11.1867 8.85988 11.5267 8.77988 11.84ZM6.27988 15L8.25988 8.5H10.3399L12.3199 15H10.4999L10.1799 13.62H8.35988L8.03988 15H6.27988ZM14.6797 15.12C14.1664 15.12 13.7197 15.04 13.3397 14.88C12.9664 14.72 12.6597 14.5 12.4197 14.22L13.1997 13.14C13.3931 13.32 13.5997 13.4667 13.8197 13.58C14.0464 13.6867 14.2731 13.74 14.4997 13.74C14.7797 13.74 15.0031 13.69 15.1697 13.59C15.3364 13.49 15.4197 13.34 15.4197 13.14C15.4197 12.98 15.3797 12.8433 15.2997 12.73C15.2197 12.6167 15.0664 12.53 14.8397 12.47C14.6197 12.41 14.2864 12.38 13.8397 12.38V11.18C14.3731 11.18 14.7364 11.1167 14.9297 10.99C15.1231 10.8567 15.2197 10.68 15.2197 10.46C15.2197 10.0733 14.9997 9.88 14.5597 9.88C14.3531 9.88 14.1631 9.92333 13.9897 10.01C13.8231 10.0967 13.6397 10.2267 13.4397 10.4L12.5797 9.36C12.8931 9.1 13.2164 8.9 13.5497 8.76C13.8897 8.61333 14.2531 8.54 14.6397 8.54C15.3397 8.54 15.8964 8.69667 16.3097 9.01C16.7297 9.31667 16.9397 9.75667 16.9397 10.33C16.9397 10.9433 16.5997 11.4 15.9197 11.7V11.74C16.2797 11.8467 16.5731 12.0267 16.7997 12.28C17.0264 12.5333 17.1397 12.86 17.1397 13.26C17.1397 13.66 17.0264 14 16.7997 14.28C16.5731 14.5533 16.2731 14.7633 15.8997 14.91C15.5264 15.05 15.1197 15.12 14.6797 15.12Z"
            :fill="props.color"
        />
    </svg>
</template>
