<template>
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.388 8.36312C15.8393 7.16312 13.6874 3.25 8.99992 3.25C4.31242 3.25 2.16054 7.16312 1.61179 8.36312C1.53813 8.52394 1.5 8.69874 1.5 8.87563C1.5 9.05251 1.53813 9.22731 1.61179 9.38812C2.16054 10.5869 4.31242 14.5 8.99992 14.5C13.6874 14.5 15.8393 10.5869 16.388 9.38688C16.4616 9.22624 16.4996 9.05166 16.4996 8.875C16.4996 8.69834 16.4616 8.52376 16.388 8.36312ZM8.99992 13.25C5.05804 13.25 3.21867 9.89625 2.74992 8.88188C3.21867 7.85375 5.05804 4.5 8.99992 4.5C12.9324 4.5 14.7724 7.83938 15.2499 8.875C14.7724 9.91062 12.9324 13.25 8.99992 13.25Z"
            fill="#A1C413"
        />
        <path
            d="M9 5.75C8.38193 5.75 7.77775 5.93328 7.26384 6.27666C6.74994 6.62004 6.3494 7.1081 6.11288 7.67911C5.87635 8.25013 5.81447 8.87847 5.93505 9.48466C6.05563 10.0908 6.35325 10.6477 6.79029 11.0847C7.22733 11.5217 7.78415 11.8194 8.39034 11.94C8.99653 12.0605 9.62487 11.9986 10.1959 11.7621C10.7669 11.5256 11.255 11.1251 11.5983 10.6112C11.9417 10.0973 12.125 9.49307 12.125 8.875C12.124 8.0465 11.7944 7.25222 11.2086 6.66639C10.6228 6.08055 9.8285 5.75099 9 5.75ZM9 10.75C8.62916 10.75 8.26665 10.64 7.95831 10.434C7.64996 10.228 7.40964 9.93514 7.26773 9.59253C7.12581 9.24992 7.08868 8.87292 7.16103 8.50921C7.23338 8.14549 7.41195 7.8114 7.67418 7.54917C7.9364 7.28695 8.27049 7.10837 8.63421 7.03603C8.99792 6.96368 9.37492 7.00081 9.71753 7.14273C10.0601 7.28464 10.353 7.52496 10.559 7.83331C10.765 8.14165 10.875 8.50416 10.875 8.875C10.875 9.37228 10.6775 9.84919 10.3258 10.2008C9.9742 10.5525 9.49728 10.75 9 10.75Z"
            fill="#A1C413"
        />
    </svg>
</template>
