<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>
<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M8.77988 11.84L8.66988 12.29H9.86988L9.75988 11.84C9.67988 11.5267 9.59988 11.1867 9.51988 10.82C9.43988 10.4533 9.35988 10.1067 9.27988 9.78H9.23988C9.17322 10.1067 9.09988 10.4533 9.01988 10.82C8.93988 11.1867 8.85988 11.5267 8.77988 11.84ZM6.27988 15L8.25988 8.5H10.3399L12.3199 15H10.4999L10.1799 13.62H8.35988L8.03988 15H6.27988ZM12.5797 15V14.02C13.0797 13.56 13.5297 13.1333 13.9297 12.74C14.3364 12.3467 14.6564 11.9833 14.8897 11.65C15.1231 11.3167 15.2397 11.0067 15.2397 10.72C15.2397 10.4333 15.1664 10.2233 15.0197 10.09C14.8797 9.95 14.6931 9.88 14.4597 9.88C14.2531 9.88 14.0664 9.94333 13.8997 10.07C13.7331 10.19 13.5731 10.3267 13.4197 10.48L12.4997 9.56C12.8331 9.20667 13.1664 8.95 13.4997 8.79C13.8331 8.62333 14.2331 8.54 14.6997 8.54C15.1264 8.54 15.5031 8.62667 15.8297 8.8C16.1564 8.96667 16.4131 9.20667 16.5997 9.52C16.7864 9.83333 16.8797 10.2 16.8797 10.62C16.8797 10.96 16.7864 11.3067 16.5997 11.66C16.4197 12.0133 16.1897 12.36 15.9097 12.7C15.6297 13.0333 15.3464 13.3467 15.0597 13.64C15.2197 13.62 15.3997 13.6033 15.5997 13.59C15.8064 13.57 15.9797 13.56 16.1197 13.56H17.1797V15H12.5797Z"
            :fill="props.color"
        />
    </svg>
</template>
