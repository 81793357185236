<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_670)">
            <path
                d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                fill="#ADBCAA"
            />
            <path
                d="M8.03125 5.22211H14C15.7812 5.22211 17.1445 5.62054 18.0938 6.40961C19.0391 7.20258 19.5156 8.32758 19.5156 9.78461C19.5156 11.2455 19.0391 12.3705 18.0938 13.1596C17.1445 13.9526 15.7812 14.3471 14 14.3471H11.625V19.1909H8.03125V5.22211ZM11.625 7.83148V11.7377H13.625C14.3203 11.7377 14.8594 11.5737 15.2344 11.2377C15.6172 10.894 15.8125 10.4096 15.8125 9.78461C15.8125 9.15961 15.6172 8.68304 15.2344 8.34711C14.8594 8.00336 14.3203 7.83148 13.625 7.83148H11.625Z"
                fill="#3F674D"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_670">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
