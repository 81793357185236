import { environmentMode } from '@/environment'

// API Constants

export const apiServerUrl = environmentMode == 'production' ? 'https://admin.eupure.eu' : 'https://staging.admin.eupure.eu'
export const baseAPIUrl = apiServerUrl + '/api/v1'

export const basePhotonUrl = apiServerUrl + '/services/photon'

export const photoServerUrl = environmentMode == 'production' ? 'https://photoserver.eupure.eu' : 'https://staging.photoserver.eupure.eu'
export const basePhotoServerUrl = photoServerUrl + '/api/v1'
