<template>
    <svg
        width="140"
        height="140"
        viewBox="0 0 140 140"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle opacity="0.1" cx="70" cy="70" r="70" fill="#A1C413" />
        <circle cx="70" cy="70" r="60" fill="#A1C413" />
        <g clip-path="url(#clip0_4343_92632)">
            <path
                d="M70 60C67.6266 60 65.3066 60.7038 63.3332 62.0224C61.3598 63.3409 59.8217 65.2151 58.9135 67.4078C58.0052 69.6005 57.7676 72.0133 58.2306 74.3411C58.6936 76.6689 59.8365 78.8071 61.5147 80.4853C63.193 82.1635 65.3312 83.3064 67.6589 83.7694C69.9867 84.2324 72.3995 83.9948 74.5922 83.0866C76.7849 82.1783 78.6591 80.6402 79.9776 78.6668C81.2962 76.6935 82 74.3734 82 72C81.9966 68.8185 80.7312 65.7682 78.4815 63.5185C76.2318 61.2688 73.1815 60.0034 70 60ZM70 80C68.4178 80 66.871 79.5308 65.5554 78.6518C64.2399 77.7727 63.2145 76.5233 62.609 75.0615C62.0035 73.5997 61.845 71.9911 62.1537 70.4393C62.4624 68.8874 63.2243 67.462 64.3432 66.3431C65.462 65.2243 66.8874 64.4624 68.4393 64.1537C69.9911 63.845 71.5997 64.0035 73.0615 64.609C74.5233 65.2145 75.7727 66.2398 76.6518 67.5554C77.5308 68.871 78 70.4177 78 72C77.9976 74.121 77.154 76.1544 75.6542 77.6542C74.1545 79.154 72.121 79.9976 70 80Z"
                fill="white"
            />
            <path
                d="M92 53H81.756L76.086 48H63.916L58.244 53H48C46.9391 53 45.9217 53.4214 45.1716 54.1716C44.4214 54.9217 44 55.9391 44 57V88C44 89.0609 44.4214 90.0783 45.1716 90.8284C45.9217 91.5786 46.9391 92 48 92H92C93.0609 92 94.0783 91.5786 94.8284 90.8284C95.5786 90.0783 96 89.0609 96 88V57C96 55.9391 95.5786 54.9217 94.8284 54.1716C94.0783 53.4214 93.0609 53 92 53ZM92 88H48V57H59.756L65.428 52H74.575L80.244 57H92V88Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4343_92632">
                <rect width="52" height="44" fill="white" transform="translate(44 48)" />
            </clipPath>
        </defs>
    </svg>
</template>
