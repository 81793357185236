<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16" cy="16" r="12" stroke="#798877" stroke-width="2" />
        <path
            d="M12.9714 16.37L12.7954 17.03H14.3244L14.1594 16.37C14.0641 16.0107 13.9651 15.6293 13.8624 15.226C13.7671 14.8227 13.6717 14.434 13.5764 14.06H13.5324C13.4444 14.4413 13.3527 14.8337 13.2574 15.237C13.1694 15.633 13.0741 16.0107 12.9714 16.37ZM10.3754 20L12.6194 12.828H14.5664L16.8104 20H15.0944L14.6544 18.295H12.4654L12.0254 20H10.3754ZM19.4201 20.132C18.8554 20.132 18.3714 20.0403 17.9681 19.857C17.5721 19.6737 17.2457 19.4353 16.9891 19.142L17.7371 18.119C17.9497 18.3243 18.1844 18.493 18.4411 18.625C18.6977 18.757 18.9727 18.823 19.2661 18.823C19.6107 18.823 19.8857 18.7533 20.0911 18.614C20.2964 18.4673 20.3991 18.262 20.3991 17.998C20.3991 17.7927 20.3477 17.6167 20.2451 17.47C20.1424 17.316 19.9554 17.1987 19.6841 17.118C19.4127 17.0373 19.0241 16.997 18.5181 16.997V15.853C19.1487 15.853 19.5814 15.7687 19.8161 15.6C20.0507 15.424 20.1681 15.1967 20.1681 14.918C20.1681 14.676 20.0947 14.489 19.9481 14.357C19.8014 14.2177 19.5887 14.148 19.3101 14.148C19.0607 14.148 18.8334 14.203 18.6281 14.313C18.4301 14.423 18.2211 14.5733 18.0011 14.764L17.1871 13.774C17.5097 13.4953 17.8507 13.279 18.2101 13.125C18.5694 12.9637 18.9617 12.883 19.3871 12.883C20.1057 12.883 20.6851 13.0517 21.1251 13.389C21.5724 13.719 21.7961 14.1883 21.7961 14.797C21.7961 15.1563 21.6934 15.4643 21.4881 15.721C21.2901 15.9777 21.0041 16.1867 20.6301 16.348V16.392C21.0334 16.5093 21.3634 16.711 21.6201 16.997C21.8841 17.2757 22.0161 17.6423 22.0161 18.097C22.0161 18.5297 21.8951 18.9 21.6531 19.208C21.4184 19.5087 21.1031 19.7397 20.7071 19.901C20.3184 20.055 19.8894 20.132 19.4201 20.132Z"
            fill="#798877"
        />
    </svg>
</template>
