<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38579)">
            <path
                d="M65.4128 25.3841C70.4754 25.3841 74.5794 21.2801 74.5794 16.2174C74.5794 11.1548 70.4754 7.05078 65.4128 7.05078C60.3501 7.05078 56.2461 11.1548 56.2461 16.2174C56.2461 21.2801 60.3501 25.3841 65.4128 25.3841Z"
                fill="#022D05"
            />
            <path
                d="M29.7123 58.4765L39.429 60.3557L45.6073 28.914L40.2906 27.8599C37.9086 27.4024 35.4423 27.9076 33.432 29.265C31.4218 30.6223 30.0315 32.7211 29.5656 35.1015L26.0823 53.114C25.8535 54.3051 26.1062 55.5382 26.7848 56.5433C27.4635 57.5485 28.5129 58.2436 29.7031 58.4765H29.7123Z"
                fill="#022D05"
            />
            <path
                d="M88.7209 41.4267H81.8459V48.815C77.269 47.0576 73.4179 43.8088 70.9146 39.5933L66.3313 32.26C65.4312 30.8421 64.1845 29.677 62.7088 28.8751C61.2332 28.0731 59.5774 27.6606 57.898 27.6767C55.9797 27.6483 54.1121 28.293 52.62 29.4988C51.1278 30.7046 50.1054 32.3952 49.7305 34.2767C49.1805 36.99 41.3521 79.1613 41.3521 79.1613L29.5638 85.5046L24.3617 76.9888L18.1055 80.8113L33.1938 105.561L39.4501 101.739L34.2984 93.2917L50.1384 84.3863L53.498 68.9267L63.123 78.0933V98.2921H52.9984V105.625H81.988V105.593H88.7209V41.4267ZM62.6646 62.0517L65.4146 48.3017C69.7734 53.2308 75.4888 56.7652 81.8459 58.4629V98.2921H72.2896V71.2183L62.6646 62.0517Z"
                fill="#022D05"
            />
        </g>
        <defs>
            <clipPath id="clip0_3632_38579">
                <rect
                    width="91.96"
                    height="101.053"
                    fill="white"
                    transform="translate(13.5234 4.58203)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
