<script setup lang="ts">
export interface Props {
    color: {
        fill: string
        stroke: string
        border: string
        strokeTwo: string
    }
    height?: number
    width?: number
}

const props = withDefaults(defineProps<Props>(), {
    height: 30,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.strokeTwo" />
        <circle cx="12" cy="12" r="11" :stroke="props.color.strokeTwo" />
        <path
            d="M6.99663 15V9.15H8.58063L9.87663 11.76L10.4346 13.074H10.4706C10.4346 12.756 10.3926 12.396 10.3446 11.994C10.2966 11.586 10.2726 11.202 10.2726 10.842V9.15H11.7486V15H10.1646L8.86863 12.381L8.31063 11.076H8.27463C8.31063 11.406 8.35263 11.772 8.40063 12.174C8.44863 12.57 8.47263 12.948 8.47263 13.308V15H6.99663ZM14.5555 12.156L14.4565 12.561H15.5365L15.4375 12.156C15.3655 11.874 15.2935 11.568 15.2215 11.238C15.1495 10.908 15.0775 10.596 15.0055 10.302H14.9695C14.9095 10.596 14.8435 10.908 14.7715 11.238C14.6995 11.568 14.6275 11.874 14.5555 12.156ZM12.3055 15L14.0875 9.15H15.9595L17.7415 15H16.1035L15.8155 13.758H14.1775L13.8895 15H12.3055Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
