import { defineStore } from 'pinia'
import { CustomClaims } from '../../types/claims/claims'

interface ClaimsState {
    customClaims: CustomClaims
}

export const useClaimsStore = defineStore({
    id: 'claimsStore',
    state: () =>
        ({
            customClaims: {
                publisher: false,
            },
        } as ClaimsState),
    actions: {
        setPublisherClaim(claims: boolean) {
            this.customClaims.publisher = claims
        },
        clearCustomClaims() {
            this.customClaims = {
                publisher: false,
            }
        },
    },
})
