export function tryDisplaySmartbanner(): boolean {
    try {
        ;(window as any).smartbanner.publish()
        return true
    } catch {
        console.warn('Could not display smart banner')
    }

    return false
}
