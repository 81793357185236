<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M8.77988 11.84L8.66988 12.29H9.86988L9.75988 11.84C9.67988 11.5267 9.59988 11.1867 9.51988 10.82C9.43988 10.4533 9.35988 10.1067 9.27988 9.78H9.23988C9.17322 10.1067 9.09988 10.4533 9.01988 10.82C8.93988 11.1867 8.85988 11.5267 8.77988 11.84ZM6.27988 15L8.25988 8.5H10.3399L12.3199 15H10.4999L10.1799 13.62H8.35988L8.03988 15H6.27988ZM12.8797 15V13.62H14.2397V10.34H13.0397V9.28C13.3997 9.20667 13.7064 9.12333 13.9597 9.03C14.2131 8.93 14.4597 8.80667 14.6997 8.66H15.9597V13.62H17.0997V15H12.8797Z"
            :fill="props.color"
        />
    </svg>
</template>
