<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38375)">
            <path
                d="M72.0339 27.5013C77.0965 27.5013 81.2005 23.3972 81.2005 18.3346C81.2005 13.272 77.0965 9.16797 72.0339 9.16797C66.9712 9.16797 62.8672 13.272 62.8672 18.3346C62.8672 23.3972 66.9712 27.5013 72.0339 27.5013Z"
                fill="#022D05"
            />
            <path
                d="M25.207 57.6367C19.7369 57.6367 14.4909 59.8097 10.623 63.6776C6.75502 67.5456 4.58203 72.7916 4.58203 78.2617C4.58203 83.7318 6.75502 88.9779 10.623 92.8458C14.4909 96.7137 19.7369 98.8867 25.207 98.8867C30.6771 98.8867 35.9232 96.7137 39.7911 92.8458C43.659 88.9779 45.832 83.7318 45.832 78.2617C45.832 72.7916 43.659 67.5456 39.7911 63.6776C35.9232 59.8097 30.6771 57.6367 25.207 57.6367ZM25.207 91.3242C21.7426 91.3242 18.4201 89.948 15.9704 87.4983C13.5208 85.0486 12.1445 81.7261 12.1445 78.2617C12.1445 74.7973 13.5208 71.4748 15.9704 69.0251C18.4201 66.5754 21.7426 65.1992 25.207 65.1992C28.6714 65.1992 31.9939 66.5754 34.4436 69.0251C36.8933 71.4748 38.2695 74.7973 38.2695 78.2617C38.2695 81.7261 36.8933 85.0486 34.4436 87.4983C31.9939 89.948 28.6714 91.3242 25.207 91.3242Z"
                fill="#022D05"
            />
            <path
                d="M86.7734 57.6377C82.6942 57.6377 78.7066 58.8474 75.3148 61.1137C71.923 63.38 69.2795 66.6012 67.7184 70.3699C66.1574 74.1386 65.7489 78.2856 66.5448 82.2865C67.3406 86.2873 69.3049 89.9623 72.1894 92.8468C75.0738 95.7313 78.7489 97.6956 82.7497 98.4914C86.7506 99.2872 90.8976 98.8788 94.6663 97.3177C98.435 95.7567 101.656 93.1131 103.923 89.7214C106.189 86.3296 107.398 82.342 107.398 78.2627C107.425 75.5467 106.91 72.8526 105.883 70.3381C104.856 67.8236 103.338 65.5392 101.418 63.6186C99.497 61.698 97.2126 60.1798 94.6981 59.1528C92.1836 58.1258 89.4895 57.6108 86.7734 57.6377ZM86.7734 91.3252C84.1899 91.3252 81.6644 90.5591 79.5163 89.1238C77.3682 87.6885 75.6939 85.6484 74.7053 83.2615C73.7166 80.8747 73.4579 78.2482 73.9619 75.7144C74.466 73.1805 75.71 70.853 77.5369 69.0261C79.3637 67.1993 81.6912 65.9552 84.2251 65.4512C86.759 64.9472 89.3854 65.2059 91.7722 66.1945C94.1591 67.1832 96.1992 68.8575 97.6345 71.0056C99.0698 73.1537 99.836 75.6792 99.836 78.2627C99.7943 81.7142 98.4047 85.0126 95.964 87.4533C93.5233 89.894 90.2249 91.2836 86.7734 91.3252Z"
                fill="#022D05"
            />
            <path
                d="M70.0367 47.8958H88.0767V39.8016H75.3672L63.3267 27.9583C61.2911 26.1919 58.6885 25.2159 55.9934 25.2083C54.7828 25.128 53.5703 25.3353 52.4551 25.8132C51.3399 26.2912 50.3536 27.0262 49.5767 27.9583L36.7434 40.7916C35.8829 41.6257 35.1967 42.6225 34.7246 43.724C34.2525 44.8255 34.004 46.0099 33.9934 47.2083C33.9131 48.4189 34.1205 49.6314 34.5984 50.7466C35.0764 51.8618 35.8114 52.8481 36.7434 53.6249L51.4101 66.4583V89.3749H60.5767V60.9583L50.4934 50.4166L61.4934 39.4166L70.0367 47.8958Z"
                fill="#022D05"
            />
        </g>
        <defs>
            <clipPath id="clip0_3632_38375">
                <rect
                    width="102.818"
                    height="89.7188"
                    fill="white"
                    transform="translate(4.58203 9.16797)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
