// Note: all routes are lazy-loaded
const Community = () => import('./views/CommunityView.vue')
const DiscoverDetail = () => import('./views/DiscoverDetail.vue')
const GeneralPage = () => import('./views/GeneralPage.vue')
const TripsMap = () => import('./views/TripsMap.vue')
const AreaDetail = () => import('./views/AreaDetail.vue')
const UserView = () => import('./views/UserView.vue')
const AwardsView = () => import('./views/AwardsView.vue')
const FollowersView = () => import('./views/FollowersView.vue')
const FolloweesView = () => import('./views/FolloweesView.vue')
const Notifications = () => import('./views/NotificationsView.vue')
const UserSettingsEPU = () => import('./views/UserSettingsEPU.vue')
const UserPersonalData = () => import('./views/UserPersonalData.vue')
const UserPasswordChange = () => import('./views/UserPasswordChange.vue')
const UserEmailPrefferencies = () => import('./views/UserEmailPrefferencies.vue')
const UserDeleteAccount = () => import('./views/UserDeleteAccount.vue')
const UserPrivacyPolicy = () => import('./views/UserPrivacyPolicy.vue')
const UserTermsOfServices = () => import('./views/UserTermsOfServices.vue')
const UserContactUs = () => import('./views/UserContactUs.vue')
const UserShare = () => import('./views/UserShare.vue')
const userSpeciesCollection = () => import('./views/UserSpeciesCollection.vue')
const PoiDetail = () => import('./views/POIDetail.vue')
const SpeciesDetail = () => import('./views/SpeciesDetail.vue')
const TripDetail = () => import('./views/TripDetail.vue')
const Home = () => import('./views/HomeView.vue')
const VerifyEmail = () => import('./views/VerifyEmail.vue')
const EmailAction = () => import('./views/EmailAction.vue')
const Events = () => import('./views/EventsView.vue')
const EventsDetail = () => import('./views/EventsDetail.vue')
const News = () => import('./views/NewsView.vue')
const AttendingsList = () => import('./views/AttendingsList.vue')

import { i18n } from './i18n'
// requiresAuth meta tag - all routes, that require authenticated user
/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
    {
        path: '/',
        redirect: `/${i18n.global.locale.value}`,
    },
    {
        path: '/:locale',
        component: Home,
        name: 'home',
        meta: { title: 'Home', scrollToTop: true },
    },
    {
        path: '/:locale/home',
        name: 'homelogged',
        redirect: { name: 'home' },
        meta: { scrollToTop: true },
    },
    {
        path: '/:locale/attendingsList/:articleId?/:eventId?',
        component: AttendingsList,
        name: 'attendingsList',
        meta: { title: 'Attendings List', auth: false },
        props: true,
    },
    {
        path: '/:locale/events',
        component: Events,
        name: 'events',
    },
    {
        path: '/:locale/events/:eventId',
        component: EventsDetail,
        name: 'eventsDetail',
        meta: { title: 'Event Detail', auth: false },
        props: true,
    },
    {
        path: '/:locale/news/:newsId?/:displayDrawer?',
        component: News,
        name: 'news',
        props: true,
    },
    {
        path: '/:locale/tripsMap/:tripsId?/a:areasId?/f:filterNum?/:showFinishedTrips?',
        component: TripsMap,
        name: 'tripsMap',
        meta: { title: 'Trips Map', auth: false },
        props: true,
    },
    {
        path: '/:locale/userProfile/:userId/:invite?',
        component: UserView,
        name: 'userProfile',
        meta: { title: 'User Detail', auth: false, noScroll: true },
        props: true,
    },
    {
        path: '/:locale/userNotifications/:userId?',
        component: Notifications,
        name: 'userNotifications',
        meta: { title: 'User Notification', auth: true, noScroll: true },
        props: true,
    },
    {
        path: '/userSettings',
        component: UserSettingsEPU,
        name: 'userSettings',
        meta: { title: 'User Settings', auth: true },
        props: true,
        children: [
            {
                path: '/:locale/userPersonalData',
                component: UserPersonalData,
                name: 'userPersonalData',
            },
            {
                path: '/:locale/userPasswordChange',
                component: UserPasswordChange,
                name: 'userPasswordChange',
            },
            {
                path: '/:locale/userEmailPreferencies',
                component: UserEmailPrefferencies,
                name: 'userEmailPreferencies',
            },
            {
                path: '/:locale/userDeleteAccount',
                component: UserDeleteAccount,
                name: 'userDeleteAccount',
            },
            {
                path: '/:locale/userPrivacyPolicy',
                component: UserPrivacyPolicy,
                name: 'userPrivacyPolicy',
            },
            {
                path: '/:locale/userTermsOfServices',
                component: UserTermsOfServices,
                name: 'userTermsOfServices',
            },
            {
                path: '/:locale/userContactUs',
                component: UserContactUs,
                name: 'userContactUs',
            },
            {
                path: '/:locale/userShare',
                component: UserShare,
                name: 'userShare',
            },
        ],
    },
    {
        path: '/:locale/userAwards/:userId?',
        component: AwardsView,
        name: 'userAwards',
        meta: { title: 'User Awards', auth: false },
        props: true,
    },
    {
        path: '/:locale/userFollowers/:userId?',
        component: FollowersView,
        name: 'userFollowers',
        meta: { title: 'User Followers', auth: false },
        props: true,
    },
    {
        path: '/:locale/userFollowees/:userId?',
        component: FolloweesView,
        name: 'userFollowees',
        meta: { title: 'User Followees', auth: false },
        props: true,
    },
    {
        path: '/:locale/trips/:tripId',
        component: TripDetail,
        name: 'tripDetail',
        meta: { title: 'Trips Detail', auth: false },
        props: true,
    },
    {
        path: '/:locale/species/:speciesId',
        component: SpeciesDetail,
        name: 'speciesDetail',
        meta: { title: 'Species Detail', auth: false },
        props: true,
    },
    {
        path: '/:locale/userSpeciesCollection/:userId',
        component: userSpeciesCollection,
        name: 'userSpeciesCollection',
        meta: { title: 'User Species Collection', auth: true },
        props: true,
    },
    {
        path: '/:locale/pois/:poiId',
        component: PoiDetail,
        name: 'poiDetail',
        meta: { title: 'Point of interest detail', auth: false },
        props: true,
    },
    {
        path: '/:locale/areas/:areaId',
        component: AreaDetail,
        name: 'areaDetail',
        meta: { title: 'Area Detail', auth: false },
        props: true,
    },
    {
        path: '/:locale/Discoverdetail',
        component: DiscoverDetail,
        name: 'discoverDetail',
        meta: { title: 'Discover Detail', noScroll: true },
        // props: true,
    },
    {
        path: '/:locale/generalPage/:componentName?',
        component: GeneralPage,
        name: 'generalPage',
        meta: { title: 'General Page', noScroll: true },
        props: true,
    },
    {
        path: '/:locale/community/:articleId?',
        component: Community,
        name: 'community',
        meta: { title: 'Community', auth: false },
        props: true,
    },
    { path: '/:locale/verifyemail', component: VerifyEmail, name: 'verifyemail' },
    // { path: '/pdf', component: PDFView, name: 'pdf' },
    { path: '/:locale/emailaction', component: EmailAction, name: 'emailaction' },
    // fallback route, first try to inject locale prefix parameter -> if already present, redirect to home (route does not exist)
    {
        path: '/:pathMatch(.*)*',
        redirect: (to) => {
            const locale = i18n.global.locale.value
            const pathLocalePrefix = to.fullPath.split('/').filter((c) => c != '')[0] ?? '' // extract first path segment

            // check if path includes locale as first parameter, if not -> inject into current path
            if (i18n.global.availableLocales.indexOf(pathLocalePrefix) == -1) {
                return {
                    ...to,
                    path: `${locale}${to.fullPath}`,
                    params: { ...to.params, locale },
                }
            }

            // if locale parameter (first route segment) already present -> redirect to home
            console.log('Fallback redirect to home', pathLocalePrefix)
            return { name: 'home', params: { locale: pathLocalePrefix } }
        },
    },
]
