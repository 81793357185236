<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M28.3337 15.9993C28.3337 22.8109 22.8118 28.3327 16.0003 28.3327C9.18881 28.3327 3.66699 22.8109 3.66699 15.9993C3.66699 9.18784 9.18881 3.66602 16.0003 3.66602C22.8118 3.66602 28.3337 9.18784 28.3337 15.9993Z"
            stroke="#798877"
            stroke-width="2"
        />
        <path
            d="M28.3337 15.9993C28.3337 22.8109 22.8118 28.3327 16.0003 28.3327C9.18881 28.3327 3.66699 22.8109 3.66699 15.9993C3.66699 9.18784 9.18881 3.66602 16.0003 3.66602C22.8118 3.66602 28.3337 9.18784 28.3337 15.9993Z"
            stroke="#798877"
            stroke-width="2"
        />
        <path
            d="M15.0309 18.5931V19.9993H11.1976V18.5931H15.0309ZM11.8486 12.416V19.9993H10.0205V12.416H11.8486Z"
            fill="#798877"
        />
        <path
            d="M20.4476 17.4316H22.2705C22.2462 17.9594 22.1038 18.4247 21.8434 18.8275C21.583 19.2303 21.2219 19.5445 20.7601 19.7702C20.3018 19.9924 19.7601 20.1035 19.1351 20.1035C18.6281 20.1035 18.175 20.0184 17.7757 19.8483C17.3799 19.6782 17.0431 19.4334 16.7653 19.1139C16.491 18.7945 16.2809 18.4073 16.1351 17.9525C15.9927 17.4976 15.9215 16.9855 15.9215 16.416V16.0046C15.9215 15.4351 15.9962 14.923 16.1455 14.4681C16.2983 14.0098 16.5136 13.6209 16.7913 13.3014C17.0726 12.9785 17.4094 12.732 17.8018 12.5618C18.1941 12.3917 18.6299 12.3066 19.109 12.3066C19.7688 12.3066 20.3261 12.4247 20.7809 12.6608C21.2358 12.8969 21.5882 13.2216 21.8382 13.6348C22.0882 14.0445 22.2358 14.5115 22.2809 15.0358H20.458C20.4511 14.7511 20.4025 14.5132 20.3122 14.3223C20.2219 14.1278 20.0795 13.982 19.8851 13.8848C19.6906 13.7875 19.432 13.7389 19.109 13.7389C18.8834 13.7389 18.6872 13.7806 18.5205 13.8639C18.3538 13.9473 18.215 14.0792 18.1038 14.2598C17.9927 14.4403 17.9094 14.6747 17.8538 14.9629C17.8018 15.2476 17.7757 15.5914 17.7757 15.9941V16.416C17.7757 16.8188 17.8 17.1625 17.8486 17.4473C17.8972 17.732 17.9754 17.9646 18.083 18.1452C18.1906 18.3257 18.3295 18.4594 18.4997 18.5462C18.6733 18.6296 18.8851 18.6712 19.1351 18.6712C19.4129 18.6712 19.6472 18.6278 19.8382 18.541C20.0292 18.4507 20.1768 18.3153 20.2809 18.1348C20.3851 17.9507 20.4406 17.7164 20.4476 17.4316Z"
            fill="#798877"
        />
    </svg>
</template>
