<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_452)">
            <path
                d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                fill="#ADBCAA"
            />
            <path
                d="M12.875 8.73438C13.5547 8.32812 13.9531 7.63281 13.9453 6.53516C15.3008 7.35547 16.2344 8.875 16.3828 10.8164C16.5898 13.5078 14.9727 15.1094 12.9922 15.8125C14.168 14.8477 14.8086 12.0117 13.2891 10.9336C13.0625 11.2422 12.9336 11.6484 12.6953 11.9453C13.1133 10.6875 12.2695 9.59375 11.2109 9.26953C11.9609 10.4336 10.5508 11.2695 10.375 12.6016C10.1992 13.9766 10.543 15.0586 11.5078 15.75C9.29687 15.2109 7.96484 13.3008 8.23437 10.6367C8.48827 8.16406 11.1875 6.83984 10.1406 3.80078C12.0781 4.36719 13.2656 6.22266 12.875 8.73438Z"
                fill="#3F674D"
            />
            <path
                d="M6.51172 19.0626C10.1445 19.0626 14.8477 19.0626 18.2266 19.0626"
                stroke="#3F674D"
                stroke-width="0.826946"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.07812 17.5586C10.9336 17.0859 14.6328 16.4766 17.2852 16.039"
                stroke="#3F674D"
                stroke-width="0.826945"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9.19922 16.5039C11.5156 16.8047 14.5156 17.1953 16.668 17.4766"
                stroke="#3F674D"
                stroke-width="0.743032"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_452">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
