<template>
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2641_22942)">
            <path
                d="M40 26.5H21.745L30.13 18.115L28 16L16 28L28 40L30.115 37.885L21.745 29.5H40V26.5Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_2641_22942">
                <rect width="36" height="36" fill="white" transform="translate(10 10)" />
            </clipPath>
        </defs>
    </svg>
</template>
