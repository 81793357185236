<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38362)">
            <g clip-path="url(#clip1_3632_38362)">
                <path
                    d="M58.1372 42.3736H29.7243L22.8557 35.3828L18.332 39.987L25.4247 47.2058V63.3026L22.6201 66.1571V91.6667H32.9074V77.5004L44.0524 66.1571H68.9423V91.6667H79.2297V52.9305L63.4953 36.9162L58.1372 42.3736Z"
                    fill="#022D05"
                />
                <path
                    d="M86.1675 21.4994C86.1675 21.4994 86.6774 14.2649 86.7083 14.143C86.7213 14.0894 86.7211 14.0333 86.7076 13.9798C86.6941 13.9263 86.6678 13.877 86.631 13.8364C86.6019 13.8065 86.5673 13.7828 86.5292 13.7667C86.4911 13.7505 86.4502 13.7422 86.4089 13.7422C86.3676 13.7422 86.3267 13.7505 86.2886 13.7667C86.2505 13.7828 86.2159 13.8065 86.1868 13.8364L83.2817 16.7931L67.5938 32.7484L81.3386 46.7377L86.4765 41.5084L89.0107 44.0877C89.5809 44.6635 90.3515 44.9865 91.1547 44.9865C91.9579 44.9865 92.7285 44.6635 93.2987 44.0877L100.832 36.4207L86.1675 21.4994Z"
                    fill="#022D05"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_3632_38362">
                <rect width="110" height="110" fill="white" />
            </clipPath>
            <clipPath id="clip1_3632_38362">
                <rect
                    width="82.5"
                    height="77.9167"
                    fill="white"
                    transform="translate(18.332 13.75)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
