export default class Detector {
    static platform(): undefined | 'android' | 'ios' {
        const maxTouchPoints = window.navigator.maxTouchPoints
        const userAgent = window.navigator.userAgent

        if (/Android/i.test(userAgent)) {
            return 'android'
            // maxTouchPoints is the only effective method to detect iPad iOS 13+
            // FMI https://developer.apple.com/forums/thread/119186
        } else if (
            (!(window as any).MSStream &&
                !/X11|Linux|Windows/i.test(userAgent) &&
                maxTouchPoints &&
                maxTouchPoints > 0) ||
            /iPhone|iPad|iPod/i.test(userAgent)
        ) {
            return 'ios'
        }
    }

    static userAgentMatchesRegex(regexString: string) {
        return new RegExp(regexString).test(window.navigator.userAgent)
    }
}
