<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>
<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M9.03125 13.6602L10.2148 9.3125H11.75L9.83203 15H8.83984L9.03125 13.6602ZM7.95703 9.3125L9.14062 13.6602L9.33984 15H8.33594L6.42969 9.3125H7.95703Z"
            :fill="props.color"
        />
        <path
            d="M15.7539 9.3125H17.125V13.0273C17.125 13.4701 17.0326 13.8451 16.8477 14.1523C16.6628 14.457 16.4036 14.6875 16.0703 14.8438C15.737 15 15.3451 15.0781 14.8945 15.0781C14.4492 15.0781 14.0573 15 13.7188 14.8438C13.3828 14.6875 13.1198 14.457 12.9297 14.1523C12.7422 13.8451 12.6484 13.4701 12.6484 13.0273V9.3125H14.0234V13.0273C14.0234 13.2617 14.0573 13.4531 14.125 13.6016C14.1927 13.7474 14.2917 13.8542 14.4219 13.9219C14.5521 13.9896 14.7096 14.0234 14.8945 14.0234C15.082 14.0234 15.2396 13.9896 15.3672 13.9219C15.4948 13.8542 15.5911 13.7474 15.6562 13.6016C15.7214 13.4531 15.7539 13.2617 15.7539 13.0273V9.3125Z"
            :fill="props.color"
        />
    </svg>
</template>

<style scoped></style>
