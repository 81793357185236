<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_839)">
            <path
                d="M18.8164 23.0586H5.19531C2.85547 23.0586 0.960938 21.1641 0.960938 18.8242V5.20313C0.960938 2.86719 2.85547 0.972656 5.19531 0.972656H18.8164C21.1524 0.972656 23.0508 2.86719 23.0508 5.20313V18.8242C23.0508 21.1641 21.1524 23.0586 18.8164 23.0586Z"
                stroke="#E41F25"
                stroke-width="1.88883"
                stroke-miterlimit="22.926"
            />
            <path
                d="M2.04688 21.9727L21.9649 2.05469"
                stroke="#E41F25"
                stroke-width="1.84064"
                stroke-miterlimit="22.926"
            />
            <path
                d="M9.27344 12.7148L13.0391 10.832L12.2266 10.0195L8.46094 11.9023L9.27344 12.7148Z"
                fill="#2B2B2A"
            />
            <path
                d="M10.3516 8.14063L9.52734 7.31641L7.06641 9.77344L10.3516 8.14063Z"
                fill="#2B2B2A"
            />
            <path
                d="M5.66406 10.7188L10.1055 6.27734L4.45312 5.07031L5.66406 10.7188Z"
                fill="#2B2B2A"
            />
            <path
                d="M8.22266 11.6602L11.9883 9.77734L10.5898 8.38281L6.82422 10.2656L8.22266 11.6602Z"
                fill="#2B2B2A"
            />
            <path
                d="M10.9102 14.3516L14.125 12.7461L14.1367 12.7383L14.5391 12.332L13.2852 11.0703H13.2812L9.51172 12.9531L10.9102 14.3516Z"
                fill="#2B2B2A"
            />
            <path
                d="M16.332 17.4023C16.4531 17.5312 16.6641 17.5312 16.7852 17.4023C16.8516 17.3437 16.8828 17.2578 16.8828 17.1758C16.8828 17.0898 16.8516 17.0117 16.7852 16.9453L13.5859 13.7461L13.1289 14.1992L16.332 17.4023Z"
                fill="#2B2B2A"
            />
            <path
                d="M12.8125 13.7578H12.8047L11.1484 14.5898L11.7148 15.1562L12.9023 13.9727V13.9648L13.3555 13.5117L13.4102 13.4609L13.3359 13.5L12.8125 13.7578Z"
                fill="#2B2B2A"
            />
            <path
                d="M17.461 15.0547L17.0586 16.2656C17 16.4336 17.0938 16.6172 17.2656 16.6758C17.4336 16.7344 17.6172 16.6406 17.6758 16.4727L18.0781 15.2617C18.0899 15.2266 18.0938 15.1914 18.0938 15.1562C18.0938 15.0195 18.0078 14.8945 17.875 14.8477C17.7031 14.793 17.5195 14.8828 17.461 15.0547Z"
                fill="#2B2B2A"
            />
            <path
                d="M18.4649 17.3711C18.3086 17.4531 18.2422 17.6484 18.3203 17.8086C18.4024 17.9687 18.5977 18.0312 18.7578 17.9531L19.8984 17.3828C20.0117 17.3242 20.0781 17.2109 20.0781 17.0898C20.0781 17.043 20.0664 16.9922 20.043 16.9453C19.9649 16.7852 19.7695 16.7227 19.6094 16.8008L18.4649 17.3711Z"
                fill="#2B2B2A"
            />
            <path
                d="M17.3711 19.2266C17.3711 19.1094 17.3047 18.9961 17.1914 18.9375C17.0313 18.8555 16.8359 18.9219 16.7578 19.082L16.1875 20.2227C16.1055 20.3828 16.1719 20.5781 16.332 20.6602C16.4922 20.7383 16.6875 20.6758 16.7656 20.5156L17.3359 19.3711C17.3594 19.3281 17.3711 19.2773 17.3711 19.2266Z"
                fill="#2B2B2A"
            />
            <path
                d="M16.0508 17.8555C15.9805 17.6914 15.7891 17.6133 15.625 17.6836L14.2109 18.2891C14.0469 18.3594 13.9687 18.5508 14.043 18.7148C14.1133 18.8789 14.3047 18.957 14.4687 18.8867L15.8789 18.2813C16.0039 18.2266 16.0781 18.1094 16.0781 17.9805C16.0781 17.9375 16.0703 17.8945 16.0508 17.8555Z"
                fill="#2B2B2A"
            />
            <path
                d="M16.3867 19.0195C16.4492 18.957 16.4805 18.8711 16.4805 18.7891C16.4805 18.707 16.4492 18.625 16.3867 18.5586C16.2578 18.4336 16.0547 18.4336 15.9258 18.5586L14.918 19.5703C14.7891 19.6953 14.7891 19.9023 14.918 20.0273C15.043 20.1562 15.25 20.1562 15.375 20.0273L16.3867 19.0195Z"
                fill="#2B2B2A"
            />
            <path
                d="M17.9453 16.543C17.8164 16.668 17.8164 16.875 17.9453 17C18.0703 17.1289 18.2773 17.1289 18.4023 17L19.4141 15.9922C19.4766 15.9297 19.5078 15.8477 19.5078 15.7617C19.5078 15.6797 19.4766 15.5977 19.4141 15.5312C19.2852 15.4062 19.0781 15.4062 18.9531 15.5312L17.9453 16.543Z"
                fill="#2B2B2A"
            />
            <path
                d="M17.7422 18.8164L18.75 19.8242C18.8789 19.9531 19.082 19.9531 19.2109 19.8242C19.2734 19.7617 19.3047 19.6797 19.3047 19.5977C19.3047 19.5117 19.2734 19.4297 19.2109 19.3672L18.2031 18.3594C18.0742 18.2305 17.8711 18.2305 17.7422 18.3594C17.6172 18.4844 17.6172 18.6914 17.7422 18.8164Z"
                fill="#2B2B2A"
            />
            <path
                d="M4.99219 17.7031L4.35938 16.8906L4.9375 16.0391L3.97266 16.3867L3.33984 15.5742L3.375 16.6016L2.40625 16.9531L3.39453 17.2383L3.42578 18.2656L4.00391 17.4141L4.99219 17.7031Z"
                fill="#2B2B2A"
            />
            <path
                d="M6.03906 15.1641L6.60547 16.0195L6.65234 14.9922L7.64062 14.7188L6.67969 14.3594L6.72266 13.332L6.08203 14.1367L5.12109 13.7773L5.6875 14.6328L5.04688 15.4375L6.03906 15.1641Z"
                fill="#2B2B2A"
            />
            <path
                d="M11.0664 15.2422L10.1211 15.4727L9.60547 14.6484L9.53516 15.6172L8.58984 15.8516L9.49219 16.2187L9.42188 17.1875L10.0508 16.4453L10.9531 16.8125L10.4375 15.9844L11.0664 15.2422Z"
                fill="#2B2B2A"
            />
            <path
                d="M8.53125 18.7773L7.58984 17.8398L8.19531 16.6602L7.01172 17.2617L6.07031 16.3242L6.28125 17.6328L5.09766 18.2344L6.40625 18.4414L6.61719 19.7539L7.21875 18.5703L8.53125 18.7773Z"
                fill="#2B2B2A"
            />
            <path
                d="M4.06249 18.0742C3.98045 18.0195 3.86717 18.043 3.81249 18.125C3.7578 18.2109 3.77733 18.3203 3.86327 18.3789C3.87889 18.3906 5.66795 19.6133 5.90623 21.9922C5.91795 22.0859 5.99608 22.1562 6.08592 22.1562C6.09373 22.1562 6.09764 22.1562 6.10545 22.1523C6.20702 22.1445 6.27733 22.0547 6.26952 21.957C6.0117 19.4023 4.14061 18.1289 4.06249 18.0742Z"
                fill="#2B2B2A"
            />
            <path
                d="M9.73829 18.0742C9.65626 18.1289 7.78907 19.4023 7.53126 21.957C7.52345 22.0547 7.59376 22.1445 7.69532 22.1523C7.69923 22.1562 7.70704 22.1562 7.71095 22.1562C7.8047 22.1562 7.88282 22.0859 7.89454 21.9922C8.13282 19.6133 9.92189 18.3906 9.93751 18.3789C10.0234 18.3203 10.043 18.2109 9.98829 18.125C9.93361 18.043 9.82032 18.0195 9.73829 18.0742Z"
                fill="#2B2B2A"
            />
            <path
                d="M6.78907 20.0508C6.69142 20.0781 6.63673 20.1797 6.66407 20.2734C6.66407 20.2852 6.90626 21.1328 6.90626 21.9727C6.90626 22.0742 6.98829 22.1563 7.08595 22.1563C7.18751 22.1563 7.26954 22.0742 7.26954 21.9727C7.26954 21.0859 7.02345 20.2109 7.01173 20.1758C6.98438 20.0781 6.88673 20.0234 6.78907 20.0508Z"
                fill="#2B2B2A"
            />
            <path
                d="M12.6211 4.66406L12.2187 5.48438L12.8047 6.00391L11.9687 5.98828L11.5664 6.80859L11.4531 5.98047L10.6172 5.96484L11.3828 5.46875L11.2695 4.64062L11.8555 5.16406L12.6211 4.66406Z"
                fill="#2B2B2A"
            />
            <path
                d="M15.4688 3.21484L14.8477 3.90234L15.2109 4.61719L14.4648 4.32812L13.8477 5.01172L14.0078 4.14844L13.2656 3.85938L14.1055 3.61328L14.2656 2.75L14.625 3.46094L15.4688 3.21484Z"
                fill="#2B2B2A"
            />
            <path
                d="M18.6289 3.26172L17.8125 3.625L17.7734 4.48438L17.3047 3.84766L16.4883 4.21484L17.0156 3.45703L16.5469 2.82422L17.3438 2.99219L17.8711 2.23438L17.8359 3.09375L18.6289 3.26172Z"
                fill="#2B2B2A"
            />
            <path
                d="M20.9023 6.86328L20.1758 6.54688L19.5352 7.21484L19.7227 6.35156L18.9961 6.03516L19.8398 5.81641L20.0312 4.95312L20.3633 5.68359L21.207 5.46875L20.5703 6.13672L20.9023 6.86328Z"
                fill="#2B2B2A"
            />
            <path
                d="M20.3555 10.5078L19.9805 9.80469L19.1406 10.0664L19.75 9.37109L19.375 8.66797L20.125 8.94141L20.7344 8.24219L20.5898 9.10937L21.3398 9.38281L20.5 9.64453L20.3555 10.5078Z"
                fill="#2B2B2A"
            />
            <path
                d="M18.2109 12.8047L18.043 11.9961L17.2031 12.0352L17.9414 11.4961L17.7773 10.6836L18.4023 11.1602L19.1406 10.6211L18.7852 11.457L19.4062 11.9336L18.5664 11.9727L18.2109 12.8047Z"
                fill="#2B2B2A"
            />
            <path
                d="M12.6172 6.55469L12.832 8.71094L11.7422 6.98047L12.6172 6.55469Z"
                fill="#2B2B2A"
            />
            <path
                d="M16.8438 11.793L15.2188 10.5898L17.3008 10.918L16.8438 11.793Z"
                fill="#2B2B2A"
            />
            <path d="M15.0586 5.125L14.543 7.64453L14.1406 5.25781L15.0586 5.125Z" fill="#2B2B2A" />
            <path
                d="M17.3828 4.71094L15.9844 7.58594L16.5156 4.55469L17.3828 4.71094Z"
                fill="#2B2B2A"
            />
            <path
                d="M19.1992 7.15234L16.6328 8.32031L18.8008 6.39062L19.1992 7.15234Z"
                fill="#2B2B2A"
            />
            <path
                d="M18.9609 9.78516L16.3906 9.47266L19.0586 8.85156L18.9609 9.78516Z"
                fill="#2B2B2A"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_839">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
