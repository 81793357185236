<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>
<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M11.6602 9.3125V15H10.293L8.28125 11.4609V15H6.91016V9.3125H8.28125L10.293 12.8516V9.3125H11.6602Z"
            :fill="color"
        />
        <path
            d="M15.6641 9.3125V15H14.293V9.3125H15.6641ZM17.375 9.3125V10.3711H12.6211V9.3125H17.375Z"
            :fill="props.color"
        />
    </svg>
</template>

<style scoped></style>
