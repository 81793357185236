<script setup lang="ts">
import Loader from './views/LoaderView.vue'
import CookiesDialog from './components/dialogs/CookiesDialog.vue'
import AppBanner from './components/banners/AppBanner.vue'
import { onMounted, watch } from 'vue'
import { useUserStore } from './stores/users/UserStore'
import { mapStoreEPU } from './stores/map/mapStoreEpu'
import { usePhotoServerStore } from './stores/photoserver/photoserverStore'
import { setDefaultOptions } from 'date-fns'
import { storeToRefs } from 'pinia'
import { cs, enUS, sk, de } from 'date-fns/locale'
import { canUseWebP } from './hooks/canUseWebp'
import { Head } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { cookiesAllowed } from './helpers/cookies.helper'
// import { useRecaptchaProvider } from 'vue-recaptcha'

import 'smartbanner.js/dist/smartbanner.min.css'
import 'smartbanner.js/dist/smartbanner.min.js'

const userStore = useUserStore()
const storePhotoServer = usePhotoServerStore()
const { isWebpSupported } = storeToRefs(storePhotoServer)
const { locale } = useI18n()

const localizeDateFns = (currentLocale: string) => {
    switch (currentLocale) {
        case 'cs':
            return cs
        case 'en':
            return enUS
        case 'sk':
            return sk
        case 'de':
            return de
        default:
            return cs
    }
}

watch(
    locale,
    (newLocale) => {
        setDefaultOptions({ locale: localizeDateFns(newLocale) })
    },
    { immediate: true }
)
// console.log(enUS, sk, de)
const storeMapEpu = mapStoreEPU()
const { webPSupported } = canUseWebP()
onMounted(() => {
    if (cookiesAllowed) {
        storeMapEpu.getCurrentPosition()
    }
    // /This is used for fetching corect photos format from photoserver
    isWebpSupported.value = webPSupported.value
})
// userStore.signOut()

// for some reason, iOS uses pagehide event, instead of beforeunload
// window.addEventListener('beforeunload', reloadedPage)
// window.addEventListener('pagehide', reloadedPage)

// function reloadedPage() {
//     document.cookie = 'smartbanner_exited=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
// }
</script>

<template>
    <Head>
        <title>{{ $t('head.title') }}</title>
        <meta name="description" :content="$t('head.description')" />

        <!-- Social -->
        <!-- <meta property="og:title" content="Eupure " />
        <meta
            property="og:description"
            content="EPU je inovativní aplikace navržená k omezení overturismu v české přírodě. Díky zábavnému a interaktivnímu koncept sbírání virtuálních druhů rostlin a živočichů motivuje uživatele k prozkoumávání méně frekventovaných tras a podporuje jejich vášeň pro přírodu. Aplikace také vytváří silnou komunitu, kde mohou turisté sdílet své zážitky, komentovat trasy, přidávat fotky, organizovat akce či získávat cenné rady od ostatních cestovatelů. Ve spolupráci se všemi českými národními parky a chráněnými krajinnými oblastmi aplikace informuje uživatele o pravidlech chování v chráněných územích a podporuje tak udržitelný turismus a ochranu našeho přírodního dědictví. Díky využívání umělé inteligence EPU dokáže předpovídat návštěvnost jednotlivých tras či obsazenost parkovišť a stává se tak ideálním pomocníkem při plánování vašich výletů. "
        /> -->
        <!-- <meta property="og:image" :content="mainImage" /> -->

        <!-- Twitter -->
        <!-- <meta name="twitter:title" content="Eupure" />
        <meta
            name="twitter:description"
            content="EPU je inovativní aplikace navržená k omezení overturismu v české přírodě. Díky zábavnému a interaktivnímu koncept sbírání virtuálních druhů rostlin a živočichů motivuje uživatele k prozkoumávání méně frekventovaných tras a podporuje jejich vášeň pro přírodu. Aplikace také vytváří silnou komunitu, kde mohou turisté sdílet své zážitky, komentovat trasy, přidávat fotky, organizovat akce či získávat cenné rady od ostatních cestovatelů. Ve spolupráci se všemi českými národními parky a chráněnými krajinnými oblastmi aplikace informuje uživatele o pravidlech chování v chráněných územích a podporuje tak udržitelný turismus a ochranu našeho přírodního dědictví. Díky využívání umělé inteligence EPU dokáže předpovídat návštěvnost jednotlivých tras či obsazenost parkovišť a stává se tak ideálním pomocníkem při plánování vašich výletů. "
        /> -->
        <!-- <meta name="twitter:image" :content="mainImage" /> -->
        <!-- <meta name="twitter:card" content="summary_large_image" /> -->
    </Head>
    <transition name="slide-fade">
        <loader v-show="!userStore.triedToLogin" style="transition-delay: 0.75s" />
    </transition>

    <router-view v-if="userStore.triedToLogin" />
    <CookiesDialog />
    <AppBanner />
</template>

<style lang="scss">
.grecaptcha-badge {
    display: none !important;
}
html {
    // TODO overwrites scorll behavior properly, some kind of vue bundler causes to inejct scroll behavior smooth
    scroll-behavior: auto;
}

.hover {
    cursor: pointer;
}

.blur {
    filter: blur(5px);

    user-select: none !important;
    pointer-events: none !important;

    * {
        user-select: none !important;
        pointer-events: none !important;
    }
}

// global avatar style
.avatar > img {
    border-radius: 999px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    opacity: 0;
}

#planen-map {
    overflow-x: hidden !important;
}

// SLIDERS
.slider-connect {
    background: #e30613 !important;
}

// map styles
#planen-map .map-field {
    padding-top: 135px !important;
}

.relative {
    position: relative;
}

// MAP LOADER STYLES
.map-loader {
    height: 4px;
    width: 100%;

    // position: relative !important;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 9;

    background: rgba(227, 6, 19, 0.2);

    opacity: 1;
    transition: opacity 200ms linear;

    &.hidden {
        opacity: 0;
        pointer-events: none;
        user-select: none;
    }

    overflow: hidden;

    &-inner {
        background: #e30613;
        &:before {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }
        &:after {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 1.15s;
        }
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}
</style>

<style lang="scss">
.fancybox__container {
    z-index: 99999 !important;
}

.dropdown-slider {
    #slider {
        margin-top: 10px;
    }
}

// GPS button disabled style
.gps-btn {
    &.disabled {
        pointer-events: none !important;
        cursor: auto !important;

        img {
            opacity: 0.6 !important;
        }
    }
}

// smartbanner zindex fix
.smartbanner {
    z-index: 999999;
    margin-top: 0;
}
</style>
