<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
    }
}
const props = defineProps<Props>()
</script>
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M9.99072 12.832H11.5288C11.5083 13.2773 11.3882 13.6699 11.1685 14.0098C10.9487 14.3496 10.644 14.6147 10.2544 14.8052C9.86768 14.9927 9.41064 15.0864 8.8833 15.0864C8.45557 15.0864 8.07324 15.0146 7.73633 14.8711C7.40234 14.7275 7.11816 14.521 6.88379 14.2515C6.65234 13.9819 6.4751 13.6553 6.35205 13.2715C6.23193 12.8877 6.17188 12.4556 6.17188 11.9751V11.6279C6.17188 11.1475 6.23486 10.7153 6.36084 10.3315C6.48975 9.94482 6.67139 9.6167 6.90576 9.34717C7.14307 9.07471 7.42725 8.8667 7.7583 8.72314C8.08936 8.57959 8.45703 8.50781 8.86133 8.50781C9.41797 8.50781 9.88818 8.60742 10.272 8.80664C10.6558 9.00586 10.9531 9.27979 11.1641 9.62842C11.375 9.97412 11.4995 10.3682 11.5376 10.8105H9.99951C9.99365 10.5703 9.95264 10.3696 9.87646 10.2085C9.80029 10.0444 9.68018 9.92139 9.51611 9.83936C9.35205 9.75732 9.13379 9.71631 8.86133 9.71631C8.6709 9.71631 8.50537 9.75146 8.36475 9.82178C8.22412 9.89209 8.10693 10.0034 8.01318 10.1558C7.91943 10.3081 7.84912 10.5059 7.80225 10.749C7.7583 10.9893 7.73633 11.2793 7.73633 11.6191V11.9751C7.73633 12.3149 7.75684 12.605 7.79785 12.8452C7.83887 13.0854 7.90479 13.2817 7.99561 13.4341C8.08643 13.5864 8.20361 13.6992 8.34717 13.7725C8.49365 13.8428 8.67236 13.8779 8.8833 13.8779C9.11768 13.8779 9.31543 13.8413 9.47656 13.7681C9.6377 13.6919 9.76221 13.5776 9.8501 13.4253C9.93799 13.27 9.98486 13.0723 9.99072 12.832Z"
            :fill="props.color.fill"
        />
        <path
            d="M12.7583 8.6001H15.2764C15.7686 8.6001 16.1963 8.67334 16.5596 8.81982C16.9229 8.96631 17.2026 9.18311 17.3989 9.47021C17.5981 9.75732 17.6978 10.1118 17.6978 10.5337C17.6978 10.8999 17.6406 11.2061 17.5264 11.4521C17.4121 11.6982 17.2524 11.9019 17.0474 12.063C16.8452 12.2212 16.6094 12.3516 16.3398 12.4541L15.8345 12.7397H13.7207L13.7119 11.5488H15.2764C15.4727 11.5488 15.6353 11.5137 15.7642 11.4434C15.8931 11.373 15.9897 11.2734 16.0542 11.1445C16.1216 11.0127 16.1553 10.856 16.1553 10.6743C16.1553 10.4897 16.1216 10.3315 16.0542 10.1997C15.9868 10.0679 15.8872 9.9668 15.7554 9.89648C15.6265 9.82617 15.4668 9.79102 15.2764 9.79102H14.3008V14.9985H12.7583V8.6001ZM16.2607 14.9985L14.8457 12.1685L16.4805 12.1597L17.9131 14.9326V14.9985H16.2607Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
