<template>
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="60" cy="60" r="55" fill="#808080" stroke="white" stroke-width="10" />
        <g clip-path="url(#clip0_5001_57852)">
            <path d="M66.6663 47.4971H48.333V50.8304H66.6663V47.4971Z" fill="white" />
            <path d="M66.6663 54.1631H48.333V57.4964H66.6663V54.1631Z" fill="white" />
            <path d="M59.1663 60.8301H48.333V64.1634H59.1663V60.8301Z" fill="white" />
            <path
                d="M79.9896 62.4186L78.4112 60.8403C78.2057 60.6343 77.9615 60.4709 77.6926 60.3593C77.4238 60.2478 77.1356 60.1904 76.8446 60.1904C76.5535 60.1904 76.2653 60.2478 75.9965 60.3593C75.7277 60.4709 75.4835 60.6343 75.2779 60.8403L73.3096 62.8095L78.0204 67.5203L79.9896 65.552C80.1956 65.3464 80.359 65.1022 80.4705 64.8334C80.582 64.5645 80.6394 64.2763 80.6394 63.9853C80.6394 63.6943 80.582 63.4061 80.4705 63.1373C80.359 62.8684 80.1956 62.6242 79.9896 62.4186Z"
                fill="white"
            />
            <path
                d="M59.167 76.9522V81.663H63.8778L75.6562 69.8855L70.9445 65.1738L59.167 76.9522Z"
                fill="white"
            />
            <path
                d="M71.6658 81.6642C72.5499 81.6642 73.3977 81.313 74.0228 80.6879C74.648 80.0628 74.9992 79.2149 74.9992 78.3309V75.2559L68.5908 81.6642H71.6658Z"
                fill="white"
            />
            <path
                d="M43.3333 41.6634H71.6667V59.7384L72.9217 58.4834C73.5078 57.8977 74.2181 57.4511 75 57.1767V41.6634C75 40.7794 74.6488 39.9315 74.0237 39.3064C73.3986 38.6813 72.5507 38.3301 71.6667 38.3301H43.3333C42.4493 38.3301 41.6014 38.6813 40.9763 39.3064C40.3512 39.9315 40 40.7794 40 41.6634V78.3301C40 79.2141 40.3512 80.062 40.9763 80.6871C41.6014 81.3122 42.4493 81.6634 43.3333 81.6634H55.8333V78.3301H43.3333V41.6634Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_5001_57852">
                <rect
                    width="40.64"
                    height="43.3333"
                    fill="white"
                    transform="translate(40 38.333)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
