<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.332 54.9987C18.332 34.7587 34.7587 18.332 54.9987 18.332C75.2387 18.332 91.6654 34.7587 91.6654 54.9987C91.6654 75.2387 75.2387 91.6654 54.9987 91.6654C34.7587 91.6654 18.332 75.2387 18.332 54.9987ZM32.082 59.582H77.9154V50.4154H32.082V59.582Z"
            fill="#022D05"
        />
    </svg>
</template>
