import { createI18n } from 'vue-i18n'
import { AppLanguages, FallbackLanguage } from '@/data/constants/locales'

import messages from './i18n/messages'

function getDefaultLanguage() {
    const browserLanguage = window.navigator.language
    if (browserLanguage) {
        return AppLanguages.find((l) => browserLanguage.includes(l)) ?? FallbackLanguage
    }

    return FallbackLanguage
}

function getLocalStorageLocale() {
    const locale = localStorage.getItem('eupure_locale')
    if (locale != undefined && AppLanguages.includes(locale)) {
        return locale
    }

    return undefined
}

export function getAppLocale() {
    return getLocalStorageLocale() ?? getDefaultLanguage()
}

function csPluralizationRule(choice: number, choicesLength: number) {
    if (choice === 0) {
        return 0
    }

    if (choice === 1) {
        return Math.min(choicesLength, 1)
    }

    if (choice >= 2 && choice <= 4) {
        return Math.min(choicesLength, 2)
    }

    return Math.min(choicesLength, 3)
}

export const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale: getAppLocale(), // set locale; try to retreive from localstorage
    fallbackLocale: FallbackLanguage, // set fallback locale
    messages: {
        cs: {
            ...messages.cs,
            ...messages.csShared,
        },
        // --- UNCOMMENT TO ENABLE EN LOCALIZATION ---
        // en: {
        //     ...messages.en,
        //     ...messages.enShared,
        // },
        // --- UNCOMMENT TO ENABLE DE LOCALIZATION ---
        // de: {
        //     ...messages.de,
        //     ...messages.deShared,
        // },
        // --- UNCOMMENT TO ENABLE SK LOCALIZATION ---
        // sk: {
        //     ...messages.sk,
        //     ...messages.skShared,
        // },
    },
    warnHtmlMessage: false,
    pluralizationRules: {
        cs: csPluralizationRule,
    },
    pluralRules: {
        cs: csPluralizationRule,
    },
})
