<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
            fill="#A1C413"
        />
        <rect x="10.75" y="7" width="2" height="6" fill="#A1C413" />
        <rect
            x="10.75"
            y="13"
            width="2"
            height="6"
            transform="rotate(-60 10.75 13)"
            fill="#A1C413"
        />
    </svg>
</template>
