<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_880)">
            <path
                d="M18.8164 23.0586H5.19531C2.85547 23.0586 0.960938 21.1641 0.960938 18.8242V5.20313C0.960938 2.86719 2.85547 0.972656 5.19531 0.972656H18.8164C21.1524 0.972656 23.0508 2.86719 23.0508 5.20313V18.8242C23.0508 21.1641 21.1524 23.0586 18.8164 23.0586Z"
                stroke="#E41F25"
                stroke-width="1.88883"
                stroke-miterlimit="22.926"
            />
            <path
                d="M2.04688 21.9727L21.9649 2.05859"
                stroke="#E41F25"
                stroke-width="1.84064"
                stroke-miterlimit="22.926"
            />
            <path
                d="M19.582 14.4961L19.6328 13.8203L16.7461 13.6953L16.7109 14.2031L19.582 14.4961Z"
                fill="#2B2B2A"
            />
            <path
                d="M16.3086 15.5664L18.9492 16.7383L19.207 16.1094L16.5 15.0977L16.3086 15.5664Z"
                fill="#2B2B2A"
            />
            <path
                d="M17.5742 10.0391C16.9609 9.25 16.5117 9 16.0273 8.39453C15.9805 8.33594 15.6406 8.15625 15.6016 8.08984C15.5 7.92578 15.6797 7.59375 15.6641 7.42968C15.5938 6.66015 15.1289 5.86328 14.1719 4.99218C13.3711 4.26953 11.4883 3.69922 9.95313 3.83593C6.01563 4.1914 4.37891 6.89843 4.37891 9.63281C4.37891 12.3672 6.92188 13.6953 6.45313 17.1562C7.98047 18.1562 11.4805 20.2148 11.5117 20.2148C12.0234 19.7031 12.2227 19.5078 12.7227 19.0078C12.9063 18.8242 13.1563 18.793 13.8672 18.9687C15.0508 19.2578 15.6641 18.3125 15.5039 17.8125C15.4531 17.6523 15.4414 17.3711 15.4844 17.1914C15.5 17.1211 15.5469 17.0312 15.5781 17C15.6562 16.9297 15.6719 16.8477 15.668 16.7773C15.6641 16.6953 15.6211 16.5937 15.5078 16.582C15.4453 16.5742 15.3906 16.5625 15.3359 16.5469C14.0078 16.1445 15.4258 12.7461 16.6016 12.2266C16.6875 12.1875 16.9062 12.0664 16.8438 11.8945C16.7969 11.7656 16.7344 11.6719 16.668 11.4844C16.5859 11.2656 16.6797 11.2031 17.2422 10.8984C17.7695 10.6172 17.8867 10.4414 17.5742 10.0391Z"
                fill="#2B2B2A"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_880">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
