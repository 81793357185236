<script setup lang="ts">
import { ref, onMounted, watchEffect } from 'vue'
import { useWindowSize } from '@vueuse/core'

const svgWidth = ref(24)
const svgHeight = ref(24)
const { width } = useWindowSize()
const isMobile = ref(479)

onMounted(() => {
    watchEffect(() => {
        if (width.value < isMobile.value) {
            svgWidth.value = 20
            svgHeight.value = 20
        }
        if (width.value > isMobile.value) {
            svgWidth.value = 24
            svgHeight.value = 24
        }
    })
})
</script>

<template>
    <svg
        :width="svgWidth"
        :height="svgHeight"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_2384_27969)">
            <path
                d="M17 16.08C16.24 16.08 15.56 16.38 15.04 16.85L7.91 12.7C7.96 12.47 8 12.24 8 12C8 11.76 7.96 11.53 7.91 11.3L14.96 7.19C15.5 7.69 16.21 8 17 8C18.66 8 20 6.66 20 5C20 3.34 18.66 2 17 2C15.34 2 14 3.34 14 5C14 5.24 14.04 5.47 14.09 5.7L7.04 9.81C6.5 9.31 5.79 9 5 9C3.34 9 2 10.34 2 12C2 13.66 3.34 15 5 15C5.79 15 6.5 14.69 7.04 14.19L14.16 18.35C14.11 18.56 14.08 18.78 14.08 19C14.08 20.61 15.39 21.92 17 21.92C18.61 21.92 19.92 20.61 19.92 19C19.92 17.39 18.61 16.08 17 16.08ZM17 4C17.55 4 18 4.45 18 5C18 5.55 17.55 6 17 6C16.45 6 16 5.55 16 5C16 4.45 16.45 4 17 4ZM5 13C4.45 13 4 12.55 4 12C4 11.45 4.45 11 5 11C5.55 11 6 11.45 6 12C6 12.55 5.55 13 5 13ZM17 20.02C16.45 20.02 16 19.57 16 19.02C16 18.47 16.45 18.02 17 18.02C17.55 18.02 18 18.47 18 19.02C18 19.57 17.55 20.02 17 20.02Z"
                fill="#798877"
            />
        </g>
        <defs>
            <clipPath id="clip0_2384_27969">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
