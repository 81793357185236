<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_586)">
            <path
                d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                fill="#ADBCAA"
            />
            <mask
                id="mask0_448_586"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="11"
                y="4"
                width="4"
                height="4"
            >
                <path d="M11 4H15V8H11V4Z" fill="white" />
            </mask>
            <g mask="url(#mask0_448_586)">
                <mask
                    id="mask1_448_586"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_448_586)">
                    <path
                        d="M13.0156 7.22656C13.6563 7.22656 14.1797 6.70312 14.1797 6.0625C14.1797 5.41797 13.6563 4.89844 13.0156 4.89844C12.3711 4.89844 11.8516 5.41797 11.8516 6.0625C11.8516 6.70312 12.3711 7.22656 13.0156 7.22656Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask2_448_586"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="8"
                y="3"
                width="2"
                height="6"
            >
                <path d="M8 3H10V9H8V3Z" fill="white" />
            </mask>
            <g mask="url(#mask2_448_586)">
                <mask
                    id="mask3_448_586"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask3_448_586)">
                    <path
                        d="M9.41016 4.52735C9.42969 4.19532 9.17969 3.90235 8.85547 3.88282C8.53516 3.86328 8.25391 4.1211 8.23438 4.45313L8.08594 7.42188C8.06641 7.75391 8.31641 8.04688 8.64063 8.06641C8.96094 8.08594 9.24219 7.83203 9.26172 7.4961L9.41016 4.52735Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask4_448_586"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="15"
                y="5"
                width="2"
                height="5"
            >
                <path d="M15 5H17V10H15V5Z" fill="white" />
            </mask>
            <g mask="url(#mask4_448_586)">
                <mask
                    id="mask5_448_586"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask5_448_586)">
                    <path
                        d="M16.4062 6.17579C16.3984 5.84376 16.125 5.57423 15.8008 5.58204C15.4766 5.58595 15.2187 5.8672 15.2266 6.19923L15.3281 9.00392C15.3359 9.33986 15.6055 9.60939 15.9297 9.60157C16.2539 9.59376 16.5117 9.31642 16.5039 8.98048L16.4062 6.17579Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask6_448_586"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="10"
                y="7"
                width="5"
                height="7"
            >
                <path d="M10 7H15V14H10V7Z" fill="white" />
            </mask>
            <g mask="url(#mask6_448_586)">
                <mask
                    id="mask7_448_586"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask7_448_586)">
                    <path
                        d="M10.4141 12.1797L13.9531 13.4922L14.4805 8.41016L10.9492 7.83594L10.4141 12.1797Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask8_448_586"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="8"
                y="7"
                width="9"
                height="3"
            >
                <path d="M8 7H17V10H8V7Z" fill="white" />
            </mask>
            <g mask="url(#mask8_448_586)">
                <mask
                    id="mask9_448_586"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask9_448_586)">
                    <path
                        d="M8.84765 7.02734C8.51953 6.96094 8.19922 7.16406 8.13281 7.48437C8.0664 7.80078 8.28125 8.11328 8.60937 8.17969L15.7773 9.60156C16.1055 9.66797 16.4258 9.46484 16.4922 9.14453C16.5547 8.82812 16.3398 8.51562 16.0117 8.44922L8.84765 7.02734Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask10_448_586"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="6"
                y="11"
                width="3"
                height="7"
            >
                <path d="M6 11H9V18H6V11Z" fill="white" />
            </mask>
            <g mask="url(#mask10_448_586)">
                <mask
                    id="mask11_448_586"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask11_448_586)">
                    <path
                        d="M7.84766 16.3555C7.81641 16.7969 7.44141 17.1367 7.01173 17.1055C6.58204 17.0781 6.25782 16.6914 6.28907 16.25L6.60938 12.3164C6.64454 11.8711 7.01954 11.5352 7.44923 11.5625C7.87891 11.5938 8.20313 11.9766 8.17188 12.4219L7.84766 16.3555Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask12_448_586"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="6"
                y="11"
                width="8"
                height="4"
            >
                <path d="M6 11H14V15H6V11Z" fill="white" />
            </mask>
            <g mask="url(#mask12_448_586)">
                <mask
                    id="mask13_448_586"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask13_448_586)">
                    <path
                        d="M13.2344 12.6094C13.6719 12.6914 13.9649 13.1055 13.8867 13.5274C13.8086 13.9492 13.3906 14.2305 12.9531 14.1484L7.28907 13.0352C6.85157 12.9531 6.5586 12.5391 6.63673 12.1172C6.71095 11.6953 7.13282 11.4141 7.57032 11.4961L13.2344 12.6094Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask14_448_586"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="12"
                y="12"
                width="4"
                height="10"
            >
                <path d="M12 12H16V22H12V12Z" fill="white" />
            </mask>
            <g mask="url(#mask14_448_586)">
                <mask
                    id="mask15_448_586"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask15_448_586)">
                    <path
                        d="M14.5274 17.0273C14.5274 17.0234 14.5274 17.0234 14.5274 17.0195L14.0078 13.1094C13.9414 12.668 13.543 12.3594 13.1172 12.4219C12.6914 12.4844 12.3945 12.8984 12.461 13.3359L12.9375 16.9336C12.9375 16.9375 12.9375 16.9414 12.9375 16.9414L13.457 20.8555C13.5195 21.293 13.918 21.6015 14.3438 21.539C14.7695 21.4765 15.0664 21.0664 15.0039 20.6289L14.5274 17.0273Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask16_448_586"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="24"
            >
                <path d="M0 0H20V24H0V0Z" fill="white" />
            </mask>
            <g mask="url(#mask16_448_586)">
                <mask
                    id="mask17_448_586"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask17_448_586)">
                    <path
                        d="M15.4219 -1.97267C15.4219 -1.97267 13.7539 3.29687 11.6836 4.9375C9.61327 6.58203 6.92576 9.44532 10.4453 16.4727C13.9609 23.5039 -3.51175 30.7227 -3.51175 30.7227"
                        stroke="#3F674D"
                        stroke-width="0.745797"
                    />
                </g>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_448_586">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
