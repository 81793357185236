<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M96.25 72.4154V63.707L61.5132 41.9362V17.9883C61.5132 14.3743 58.6039 11.457 55 11.457C51.3961 11.457 48.4868 14.3743 48.4868 17.9883V41.9362L13.75 63.707V72.4154L48.4868 61.5299V85.4779L39.8026 92.0091V98.5404L55 94.1862L70.1974 98.5404V92.0091L61.5132 85.4779V61.5299L96.25 72.4154Z"
            fill="#022D05"
        />
    </svg>
</template>
