<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38414)">
            <path
                d="M45.8359 82.5013V67.183H64.1693V82.5013H91.6693L58.1392 26.2191L66.6864 11.7892L61.9579 9.16797L55.0013 20.9239L48.0448 9.16797L43.3217 11.7892L51.8635 26.2191L18.3359 82.5013H45.8359Z"
                fill="#022D05"
            />
            <path d="M100.835 91.668H9.16797V100.825H100.835V91.668Z" fill="#022D05" />
        </g>
        <defs>
            <clipPath id="clip0_3632_38414">
                <rect width="110" height="110" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
