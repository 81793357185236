<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
    }
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 31,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M9.98682 12.834H11.5249C11.5044 13.2793 11.3843 13.6719 11.1646 14.0117C10.9448 14.3516 10.6401 14.6167 10.2505 14.8071C9.86377 14.9946 9.40674 15.0884 8.87939 15.0884C8.45166 15.0884 8.06934 15.0166 7.73242 14.873C7.39844 14.7295 7.11426 14.5229 6.87988 14.2534C6.64844 13.9839 6.47119 13.6572 6.34814 13.2734C6.22803 12.8896 6.16797 12.4575 6.16797 11.9771V11.6299C6.16797 11.1494 6.23096 10.7173 6.35693 10.3335C6.48584 9.94678 6.66748 9.61865 6.90186 9.34912C7.13916 9.07666 7.42334 8.86865 7.75439 8.7251C8.08545 8.58154 8.45312 8.50977 8.85742 8.50977C9.41406 8.50977 9.88428 8.60938 10.2681 8.80859C10.6519 9.00781 10.9492 9.28174 11.1602 9.63037C11.3711 9.97607 11.4956 10.3701 11.5337 10.8125H9.99561C9.98975 10.5723 9.94873 10.3716 9.87256 10.2104C9.79639 10.0464 9.67627 9.92334 9.51221 9.84131C9.34814 9.75928 9.12988 9.71826 8.85742 9.71826C8.66699 9.71826 8.50146 9.75342 8.36084 9.82373C8.22021 9.89404 8.10303 10.0054 8.00928 10.1577C7.91553 10.3101 7.84521 10.5078 7.79834 10.751C7.75439 10.9912 7.73242 11.2812 7.73242 11.6211V11.9771C7.73242 12.3169 7.75293 12.6069 7.79395 12.8472C7.83496 13.0874 7.90088 13.2837 7.9917 13.436C8.08252 13.5884 8.19971 13.7012 8.34326 13.7744C8.48975 13.8447 8.66846 13.8799 8.87939 13.8799C9.11377 13.8799 9.31152 13.8433 9.47266 13.77C9.63379 13.6938 9.7583 13.5796 9.84619 13.4272C9.93408 13.272 9.98096 13.0742 9.98682 12.834Z"
            :fill="props.color.fill"
        />
        <path
            d="M12.7544 8.60205H15.2725C15.7646 8.60205 16.1924 8.67529 16.5557 8.82178C16.9189 8.96826 17.1987 9.18506 17.395 9.47217C17.5942 9.75928 17.6938 10.1138 17.6938 10.5356C17.6938 10.9019 17.6367 11.208 17.5225 11.4541C17.4082 11.7002 17.2485 11.9038 17.0435 12.0649C16.8413 12.2231 16.6055 12.3535 16.3359 12.4561L15.8306 12.7417H13.7168L13.708 11.5508H15.2725C15.4688 11.5508 15.6313 11.5156 15.7603 11.4453C15.8892 11.375 15.9858 11.2754 16.0503 11.1465C16.1177 11.0146 16.1514 10.8579 16.1514 10.6763C16.1514 10.4917 16.1177 10.3335 16.0503 10.2017C15.9829 10.0698 15.8833 9.96875 15.7515 9.89844C15.6226 9.82812 15.4629 9.79297 15.2725 9.79297H14.2969V15.0005H12.7544V8.60205ZM16.2568 15.0005L14.8418 12.1704L16.4766 12.1616L17.9092 14.9346V15.0005H16.2568Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
