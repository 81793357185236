<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_686)">
            <path
                d="M0.0703125 12.0625C0.0703125 18.6875 5.4375 24.0547 12.0586 24.0547C18.6836 24.0547 24.0508 18.6875 24.0508 12.0625C24.0508 5.44141 18.6836 0.0742188 12.0586 0.0742188C5.4375 0.0742188 0.0703125 5.44141 0.0703125 12.0625Z"
                fill="#ADBCAA"
            />
            <path
                d="M12.1172 6.69531L4.58594 16.0508H10.5469L12.1172 11.8789L13.832 16.0508H19.6484L12.1172 6.69531Z"
                fill="#3F674D"
            />
            <path
                d="M12.1172 6.6953L4.58594 16.0508H10.5469L12.1172 11.8789L13.832 16.0508H19.6485L12.1172 6.6953Z"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.1172 6.95702V12.0469"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.2031 8.01953C12.3477 6.96094 11.6055 6.04297 11.1953 5.53125"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.0469 8.01953C11.9023 6.96094 12.6406 6.04297 13.0547 5.53125"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.5547 16.0508L20.8164 14.5312"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M3.56641 16.0508L3.30469 14.5312"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M3.69531 16.0508C5.44922 16.0508 7.72266 16.0508 9.35548 16.0508"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14.1094 16.0508C16.1094 16.0508 18.6992 16.0508 20.5547 16.0508"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_686">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
