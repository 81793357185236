// Your web app's Firebase configuration

import { environmentMode } from './environment'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
function buildConfig() {
    switch (environmentMode) {
        case 'production':
            return {
                apiKey: 'AIzaSyDvmsNSfg-Kp5NMpJNAEvD2DQBDCALwpy4',
                authDomain: 'eupure.firebaseapp.com',
                projectId: 'eupure',
                storageBucket: 'eupure.appspot.com',
                messagingSenderId: '480599704426',
                appId: '1:480599704426:web:3e5d638f1195e647465b13',
                measurementId: 'G-K0YYCZ2CMP',
            }
        case 'staging':
            return {
                apiKey: 'AIzaSyAiOV-o-SFH5q0QOXluHKQ7MZaONDU-0sE',
                authDomain: 'eupure-staging.firebaseapp.com',
                projectId: 'eupure-staging',
                storageBucket: 'eupure-staging.appspot.com',
                messagingSenderId: '268204679648',
                appId: '1:268204679648:web:ca4f50ccd49e07e6578b85',
                measurementId: 'G-4QN49HXTT5',
            }
        default:
            return {} // if unknown environment is set, return empty config
    }
}

export const firebaseConfig = buildConfig()
