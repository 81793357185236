<script setup lang="ts">
export interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 24,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18 4H20.2C21.19 4 22 4.81 22 5.8V18.2C22 19.19 21.19 20 20.2 20H3.8C2.81 20 2 19.19 2 18.2V5.8C2 4.81 2.81 4 3.8 4H6V2H8V4H16V2H18V4ZM4 18H20V8H4V18Z"
            fill="#A1C413"
        />
    </svg>
</template>
