<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_805)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.03125 0.00390625H18.9766C21.75 0.00390625 24.0156 2.27344 24.0156 5.04297V18.9883C24.0156 21.7578 21.75 24.0273 18.9766 24.0273H5.03125C2.26172 24.0273 -0.00390625 21.7578 -0.00390625 18.9883V5.04297C-0.00390625 2.27344 2.26172 0.00390625 5.03125 0.00390625ZM2.22266 20.4922L20.4805 2.23047C20.0352 1.99219 19.5195 1.85156 18.9766 1.85156H5.03125C3.27734 1.85156 1.84375 3.28906 1.84375 5.04297V18.9883C1.84375 19.5313 1.98047 20.043 2.22266 20.4922ZM21.7891 3.53906L3.52734 21.8008C3.97656 22.0391 4.48828 22.1797 5.03125 22.1797H18.9766C20.7344 22.1797 22.168 20.7422 22.168 18.9883V5.04297C22.168 4.5 22.0313 3.98828 21.7891 3.53906Z"
                fill="#E41F25"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.3359 14.2539C18.3477 14.2539 18.4531 14.2344 18.4648 14.2344C18.8477 14.1563 19.2461 13.8945 19.4922 13.6055C19.7695 13.2773 19.918 12.8789 19.9844 12.457C20.0586 11.9648 20.0195 11.4414 19.9258 10.9414C19.668 11.2305 19.3438 11.4609 19.0039 11.6406C18.5156 11.9063 17.9648 12.0859 17.4219 12.207C17.1719 12.2617 16.8984 12.3164 16.6406 12.3398L16.625 12.3438H11.7969L11.4453 11.7891H16.5977C16.832 11.7656 17.082 11.7148 17.3086 11.6641C17.7969 11.5547 18.3008 11.3906 18.7422 11.1523C19.1758 10.9219 19.5977 10.5938 19.7891 10.1289L20.0977 9.38281L20.3125 10.1602C20.5195 10.918 20.6484 11.7578 20.5313 12.5391C20.4531 13.0625 20.2617 13.5586 19.9141 13.9648C19.7031 14.2109 19.4219 14.4336 19.1094 14.5859C19.1094 14.5859 18.6055 14.4922 18.3359 14.2539ZM9.70704 12.3438H7.49219L7.48047 12.3398C7.22266 12.3164 6.94532 12.2617 6.69532 12.207C6.15235 12.0859 5.60157 11.9063 5.11329 11.6406C4.77344 11.4609 4.44922 11.2305 4.19532 10.9414C4.02344 11.8672 4.02344 12.9258 4.65626 13.6367C4.69532 13.6836 4.74219 13.7266 4.78516 13.7695C4.54297 13.7383 4.08985 13.8164 4.08985 13.8164C3.33594 12.7852 3.48829 11.332 3.8086 10.1602L4.01954 9.38281L4.33204 10.1289C4.3711 10.2227 4.41797 10.3125 4.47657 10.3984C4.69532 10.7227 5.03126 10.9688 5.37501 11.1523C5.81641 11.3906 6.32032 11.5547 6.8086 11.6641C7.03516 11.7148 7.28516 11.7656 7.51954 11.7891H9.35547L9.70704 12.3438Z"
                fill="#2B2B2A"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.0938 16.4063C20.8164 16.5859 20.4063 16.7305 20.0899 16.7969C19.2969 16.9649 18.5117 16.832 17.8203 16.4102C17.25 16.0664 16.6211 15.9531 15.9727 16.0859C15.7891 16.125 15.5586 16.1953 15.3594 16.2852L15.0742 15.8086C15.3164 15.6914 15.6016 15.6016 15.8359 15.5508C16.6289 15.3789 17.4102 15.5156 18.1055 15.9375C18.6758 16.2813 19.3047 16.3945 19.9531 16.2578C20.2149 16.207 20.5625 16.0899 20.7891 15.9414L21.0195 15.7891L21.3242 16.2539L21.0938 16.4063ZM10.9609 16.0664C10.6563 16.0156 10.3359 16.0195 10.0156 16.0859C9.75391 16.1406 9.40625 16.2578 9.17969 16.4063C8.90235 16.5859 8.49219 16.7305 8.17578 16.7969C7.38281 16.9649 6.59766 16.832 5.90235 16.4102C5.33594 16.0664 4.70703 15.9531 4.05859 16.0859C3.79688 16.1406 3.44922 16.2578 3.22266 16.4063L2.98828 16.5586L2.6875 16.0938L2.91797 15.9414C3.19141 15.7617 3.60156 15.6172 3.92188 15.5508C4.71484 15.3789 5.49609 15.5156 6.19141 15.9375C6.76172 16.2813 7.39063 16.3945 8.03907 16.2578C8.30078 16.207 8.64844 16.0899 8.875 15.9414C9.15235 15.7617 9.5586 15.6172 9.87891 15.5508C10.1602 15.4883 10.4453 15.4688 10.7188 15.4844C10.75 15.668 10.8125 15.8477 10.9219 16.0078L10.9609 16.0664Z"
                fill="#2B2B2A"
                stroke="#2B2B2A"
                stroke-width="0.0140996"
                stroke-miterlimit="22.926"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.9922 17.4141L13.0898 17.9922L11.4883 15.625C11.1563 15.1367 11.7148 14.2148 11.7148 14.2148C11.7148 14.2148 8.21875 8.74219 7.91797 8.26953C7.61719 7.80078 7.52734 8.23438 7.26953 7.85547L6.88672 7.30469L7.80859 6.71484L8.14844 7.29297C8.37891 7.69141 7.94922 7.58594 8.25 8.05859C8.55078 8.53125 12.0469 14.0039 12.0469 14.0039C12.0469 14.0039 13.1172 13.8828 13.4219 14.3906L14.8945 16.8359L13.9922 17.4141ZM11.0742 14.2422C10.4375 13.2461 8.23047 9.78906 7.45703 8.57812C7.17578 8.55078 6.99219 8.42969 6.8125 8.17188L6.10156 7.14453L7.99219 5.9375L8.625 7.01172C8.78516 7.28516 8.81641 7.50391 8.72266 7.76953C9.49609 8.98047 11.7031 12.4375 12.3398 13.4336C12.9297 13.4336 13.5938 13.6016 13.8945 14.1016L15.6484 17.0117L12.9297 18.75L11.0273 15.9336C10.6992 15.4492 10.8281 14.7773 11.0742 14.2422Z"
                fill="#2B2B2A"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.0938 14.9219C20.8164 15.1016 20.4063 15.2461 20.0899 15.3125C19.2969 15.4805 18.5117 15.3477 17.8203 14.9258C17.25 14.582 16.6211 14.4688 15.9727 14.6016C15.7109 14.6563 15.3633 14.7734 15.1367 14.9219C15.0039 15.0078 14.8438 15.0859 14.6758 15.1484L14.3867 14.6641C14.5469 14.6094 14.707 14.5391 14.832 14.457C15.1094 14.2774 15.5195 14.1328 15.8359 14.0664C16.6289 13.8945 17.4102 14.0313 18.1055 14.4531C18.6758 14.7969 19.3047 14.9102 19.9531 14.7734C20.2149 14.7227 20.5625 14.6055 20.7891 14.457L21.0195 14.3047L21.3242 14.7695L21.0938 14.9219ZM10.8125 14.5625C10.5508 14.5352 10.2852 14.5469 10.0156 14.6016C9.75391 14.6563 9.40625 14.7734 9.17969 14.9219C8.90235 15.1016 8.49219 15.2461 8.17578 15.3125C7.38281 15.4805 6.59766 15.3477 5.90235 14.9258C5.33594 14.582 4.70703 14.4688 4.05859 14.6016C3.79688 14.6563 3.44922 14.7734 3.22266 14.9219L2.98828 15.0742L2.6875 14.6094L2.91797 14.457C3.19141 14.2774 3.60156 14.1328 3.92188 14.0664C4.71484 13.8945 5.49609 14.0313 6.19141 14.4531C6.76172 14.7969 7.39063 14.9102 8.03907 14.7734C8.30078 14.7227 8.64844 14.6055 8.875 14.457C9.15235 14.2774 9.5586 14.1328 9.87891 14.0664C10.1797 14 10.4766 13.9805 10.7695 14.0039L10.9297 14.2539C10.8867 14.3516 10.8477 14.457 10.8125 14.5625Z"
                fill="#2B2B2A"
                stroke="#2B2B2A"
                stroke-width="0.0140996"
                stroke-miterlimit="22.926"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_805">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
