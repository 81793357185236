<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
    }
}
const props = defineProps<Props>()
</script>

<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M11.2471 13.8135V15H7.83691V13.8135H11.2471ZM8.38623 8.60156V15H6.84375V8.60156H8.38623ZM10.8076 11.1416V12.2886H7.83691V11.1416H10.8076ZM11.2603 8.60156V9.79248H7.83691V8.60156H11.2603Z"
            :fill="props.color.fill"
        />
        <path
            d="M13.7202 8.60156L14.7661 10.7285L15.812 8.60156H17.5698L15.7856 11.7744L17.6182 15H15.8428L14.7661 12.8335L13.6895 15H11.9097L13.7466 11.7744L11.958 8.60156H13.7202Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
