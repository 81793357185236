<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16.0003" cy="15.9993" r="12.3333" stroke="#798877" stroke-width="2" />
        <path
            d="M13.5778 17.4316H15.4007C15.3764 17.9594 15.234 18.4247 14.9736 18.8275C14.7132 19.2303 14.3521 19.5445 13.8903 19.7702C13.432 19.9924 12.8903 20.1035 12.2653 20.1035C11.7584 20.1035 11.3052 20.0184 10.9059 19.8483C10.5101 19.6782 10.1733 19.4334 9.89551 19.1139C9.6212 18.7945 9.41113 18.4073 9.2653 17.9525C9.12294 17.4976 9.05176 16.9855 9.05176 16.416V16.0046C9.05176 15.4351 9.12641 14.923 9.27572 14.4681C9.42849 14.0098 9.64377 13.6209 9.92155 13.3014C10.2028 12.9785 10.5396 12.732 10.932 12.5618C11.3243 12.3917 11.7601 12.3066 12.2393 12.3066C12.899 12.3066 13.4563 12.4247 13.9111 12.6608C14.366 12.8969 14.7184 13.2216 14.9684 13.6348C15.2184 14.0445 15.366 14.5115 15.4111 15.0358H13.5882C13.5813 14.7511 13.5327 14.5132 13.4424 14.3223C13.3521 14.1278 13.2097 13.982 13.0153 13.8848C12.8209 13.7875 12.5622 13.7389 12.2393 13.7389C12.0136 13.7389 11.8174 13.7806 11.6507 13.8639C11.484 13.9473 11.3452 14.0792 11.234 14.2598C11.1229 14.4403 11.0396 14.6747 10.984 14.9629C10.932 15.2476 10.9059 15.5914 10.9059 15.9941V16.416C10.9059 16.8188 10.9302 17.1625 10.9788 17.4473C11.0275 17.732 11.1056 17.9646 11.2132 18.1452C11.3209 18.3257 11.4597 18.4594 11.6299 18.5462C11.8035 18.6296 12.0153 18.6712 12.2653 18.6712C12.5431 18.6712 12.7775 18.6278 12.9684 18.541C13.1594 18.4507 13.307 18.3153 13.4111 18.1348C13.5153 17.9507 13.5709 17.7164 13.5778 17.4316Z"
            fill="#798877"
        />
        <path
            d="M16.932 12.416H19.9163C20.4997 12.416 21.0066 12.5028 21.4372 12.6764C21.8677 12.85 22.1993 13.107 22.432 13.4473C22.6681 13.7875 22.7861 14.2077 22.7861 14.7077C22.7861 15.1417 22.7184 15.5046 22.583 15.7962C22.4476 16.0879 22.2584 16.3292 22.0153 16.5202C21.7757 16.7077 21.4962 16.8622 21.1768 16.9837L20.5778 17.3223H18.0726L18.0622 15.9108H19.9163C20.149 15.9108 20.3417 15.8691 20.4945 15.7858C20.6472 15.7025 20.7618 15.5844 20.8382 15.4316C20.9181 15.2754 20.958 15.0896 20.958 14.8743C20.958 14.6556 20.9181 14.4681 20.8382 14.3118C20.7584 14.1556 20.6403 14.0358 20.484 13.9525C20.3313 13.8691 20.142 13.8275 19.9163 13.8275H18.7601V19.9993H16.932V12.416ZM21.083 19.9993L19.4059 16.6452L21.3434 16.6348L23.0413 19.9212V19.9993H21.083Z"
            fill="#798877"
        />
    </svg>
</template>
