<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M54.9987 18.332C34.7587 18.332 18.332 34.7587 18.332 54.9987C18.332 75.2387 34.7587 91.6654 54.9987 91.6654C75.2387 91.6654 91.6654 75.2387 91.6654 54.9987C91.6654 34.7587 75.2387 18.332 54.9987 18.332ZM50.4154 77.9154V50.4154H59.582V77.9154H50.4154ZM50.4154 32.082V41.2487H59.582V32.082H50.4154Z"
            fill="#022D05"
        />
    </svg>
</template>
