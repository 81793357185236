import en from '../assets/locales/en.json'
import cs from '../assets/locales/cs.json'
import de from '../assets/locales/de.json'
import sk from '../assets/locales/sk.json'
import csShared from '../assets/locales/shared/cs.json'
import enShared from '../assets/locales/shared/en.json'
import deShared from '../assets/locales/shared/de.json'
import skShared from '../assets/locales/shared/sk.json'

const messages = {
    cs,
    en,
    de,
    sk,
    csShared,
    enShared,
    deShared,
    skShared,
}

export default messages
