import { i18n } from '../i18n'
import { getLocalizationKey } from '@/hooks/localizationHook'
import type { PhotoInterface } from '../types/photos/photoInterface'

export function getPhotoDescription(photo: PhotoInterface): string {
    const entityName = photo?.entity_name || ''
    const author = photo?.author || ''
    let sourceType = photo?.source_type || ''
    const license = photo?.license || ''

    let description = ''
    // console.log(photo)
    if (photo?.description?.cs || photo?.description?.en) {
        const key = getLocalizationKey(Object.keys(photo?.description), '', i18n.global)
        description = photo.description[key]
        // add break line before description
        description = description.replace(/<p>/g, '<p><br />')
    }

    let displayedString = description

    if (entityName) {
        displayedString = `${entityName}\n${displayedString}`
        // add p tag and class author-caption to entityName
        displayedString = displayedString.replace(entityName, `<p class="entity-caption">${entityName}</p>`)
    }

    if (author) {
        displayedString += `\n${author}`
        // add p tag and class author-caption to author
        displayedString = displayedString.replace(author, `<p class="author-caption">${author}</p>`)
    }

    // if (sourceType !== 'user' && sourceType) {
    //     displayedString += `${sourceType}`
    //     // add p tag and class author-caption to author
    //     displayedString = displayedString.replace(
    //         sourceType,
    //         `<p class="author-caption">, ${sourceType} </p>`
    //     )
    // }

    if (sourceType !== 'user' && sourceType) {
        sourceType = sourceType.replace(sourceType, `<p class="author-caption">, ${sourceType} </p>`)

        displayedString += sourceType
    }

    if (sourceType === 'user' && author) {
        sourceType = `${i18n.global.t('photo_source_type_user')}`
        displayedString += sourceType
        // add p tag and class author-caption to author
        displayedString = displayedString.replace(sourceType, `<p class="author-caption">, ${sourceType} </p>`)
    }

    if (license) {
        displayedString += `${license}` // Added space before ${license}
        displayedString = displayedString.replace(
            `${license}`, // Added space before ${license}
            `<p class="author-caption">(${license})</p>`
        )
    }

    // wrapp all p tags which have class author-caption in div
    const matches = displayedString.match(/<p class="author-caption">.*?<\/p>/gs)
    if (matches) {
        const wrappedString = `<div class="author-caption-wrapper">${matches.join('')}</div>`
        displayedString = displayedString.replace(/<p class="author-caption">.*?<\/p>/gs, '')
        displayedString += wrappedString
    }

    return displayedString
}

export function changePhotoDescAncher(photos: PhotoInterface[]) {
    if (!photos) return
    photos.map((photo) => {
        if (!photo.description) return
        if (Array.isArray(photo.description)) {
            photo.description.map((lang) => {
                return lang.replace(/<a /g, '<a target="_blank" ')
            })
        } else {
            photo.description = {
                cs: photo.description.cs?.replace(/<a /g, '<a target="_blank" '),
                en: photo.description.en?.replace(/<a /g, '<a target="_blank" '),
            }
        }
        return photo
    })
    return photos
}
