import { computed } from 'vue-demi'
import { useRoute, useRouter } from 'vue-router'

export const LoginHash = '#login'
export const RegisterHash = '#register'
export const AccountHash = '#account'
export const PasswordResetHash = '#passwordreset'

export function useAuthModal() {
    const router = useRouter()
    const route = useRoute()
    function showLogin() {
        router.push({ ...route, hash: LoginHash })
    }

    function showRegister() {
        router.push({ ...route, hash: RegisterHash })
    }

    function showAccount() {
        router.push({ ...route, hash: AccountHash })
    }

    function showPasswordReset() {
        router.push({ ...route, hash: PasswordResetHash })
    }

    function closeAuthModal() {
        router.push({ ...route, hash: '' })
        // router.push({ ...route, hash: '', params: { noScroll: false } })
        // const routePath = route.path
        // router.push({ path: routePath, hash: '', params: { noScroll: false } })
    }

    const showLoginModal = computed(() => route.hash == '#login')
    const showRegisterModal = computed(() => route.hash == '#register')
    const showAccountModal = computed(() => route.hash == '#account')
    const showPasswordResetModal = computed(() => route.hash == '#passwordreset')
    const showModal = computed(
        () =>
            showLoginModal.value ||
            showRegisterModal.value ||
            showAccountModal.value ||
            showPasswordResetModal.value
    )

    return {
        showLogin,
        showRegister,
        showAccount,
        showPasswordReset,

        showLoginModal,
        showRegisterModal,
        showAccountModal,
        showPasswordResetModal,

        showModal,

        closeAuthModal,
    }
}

// helper functions

export function isAuthHash(hash) {
    return [LoginHash, RegisterHash, AccountHash, PasswordResetHash].includes(hash)
}
