<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>
<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16.0003" cy="15.9993" r="12.3333" stroke="#798877" stroke-width="2" />
        <path
            d="M13.8438 18.5931V19.9993H9.80208V18.5931H13.8438ZM10.4531 12.416V19.9993H8.625V12.416H10.4531ZM13.3229 15.4264V16.7858H9.80208V15.4264H13.3229ZM13.8594 12.416V13.8275H9.80208V12.416H13.8594Z"
            fill="#798877"
        />
        <path
            d="M17.0104 19.3118L18.5208 12.416H19.4219L19.9115 13.0046L18.3021 19.9993H17.3073L17.0104 19.3118ZM16.6094 12.416L17.8854 19.3639L17.5573 19.9993H16.4063L14.7969 12.416H16.6094ZM20.7396 19.3275L22.0052 12.416H23.8125L22.2031 19.9993H21.0573L20.7396 19.3275ZM20.0729 12.416L21.6198 19.3535L21.3073 19.9993H20.3125L18.6667 12.9941L19.1771 12.416H20.0729Z"
            fill="#798877"
        />
    </svg>
</template>
