<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38315)">
            <g clip-path="url(#clip1_3632_38315)">
                <path
                    d="M59.6706 23.8333C64.7332 23.8333 68.8372 19.7293 68.8372 14.6667C68.8372 9.60406 64.7332 5.5 59.6706 5.5C54.608 5.5 50.5039 9.60406 50.5039 14.6667C50.5039 19.7293 54.608 23.8333 59.6706 23.8333Z"
                    fill="#022D05"
                />
                <path
                    d="M39.9619 27.4985L34.9202 26.5819C29.8785 25.6652 24.8369 28.8735 23.9202 33.9152L20.7119 51.7902C20.2535 54.0819 21.6285 56.8319 24.3785 57.2902L34.0035 59.1235L39.9619 27.4985Z"
                    fill="#022D05"
                />
                <path
                    d="M78.0039 40.332V48.582C72.5039 47.207 67.9206 43.5404 65.1706 38.4987L60.5872 31.1654C58.7539 28.4154 55.5456 26.582 52.3372 26.582C48.2122 26.582 45.0039 29.332 44.0872 32.9987C43.1706 37.1237 29.8789 104.499 29.8789 104.499H39.5039L47.7539 67.832L57.3789 76.9987V104.499H66.5456V70.1237L56.9206 60.957L59.6706 47.207C64.2539 52.707 70.6706 56.3737 78.0039 57.7487V104.499H84.8789V40.332H78.0039Z"
                    fill="#022D05"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_3632_38315">
                <rect width="110" height="110" fill="white" />
            </clipPath>
            <clipPath id="clip1_3632_38315">
                <rect width="110" height="110" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
