<script setup lang="ts">
export interface Props {
    stroke: string
}

const props = withDefaults(defineProps<Props>(), {
    stroke: 'black',
})
</script>
<template>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 0L5 10" :stroke="props.stroke" stroke-width="1.5" />
        <path d="M0 5L10 5" :stroke="props.stroke" stroke-width="1.5" />
    </svg>
</template>

<style scoped lang="scss">
path {
    transition: all 0.2s ease-in-out;
}
</style>
