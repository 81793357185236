<template>
    <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="60" cy="60" r="60" fill="white" />
        <mask
            id="mask0_4383_98174"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="10"
            y="10"
            width="100"
            height="100"
        >
            <circle
                cx="60"
                cy="60"
                r="49.5833"
                fill="white"
                stroke="#E4EBE6"
                stroke-width="0.833333"
            />
        </mask>
        <g mask="url(#mask0_4383_98174)">
            <path
                d="M76.5427 67.8467C78.6872 69.99 79.8958 72.8941 79.9052 75.9248V77.9019H40.0948V75.9245C40.1042 72.8939 41.3129 69.99 43.4573 67.8467C45.6016 65.7033 48.5075 64.4949 51.5405 64.4854H68.4595C71.4925 64.4949 74.3984 65.7033 76.5427 67.8467Z"
                stroke="#E4EBE6"
                stroke-width="4.18954"
            />
            <path
                d="M64.9555 54.3998C63.491 55.3781 61.7692 55.9003 60.0078 55.9003C57.6458 55.9003 55.3807 54.9623 53.7107 53.2926C52.0407 51.623 51.1026 49.3586 51.1026 46.9976C51.1026 45.2369 51.6248 43.5157 52.6033 42.0516C53.5817 40.5876 54.9725 39.4464 56.5998 38.7725C58.227 38.0986 60.0177 37.9223 61.7452 38.2659C63.4728 38.6094 65.0595 39.4574 66.3049 40.7025C67.5503 41.9476 68.3984 43.5339 68.7419 45.2608C69.0855 46.9877 68.9092 48.7776 68.2352 50.4043C67.5613 52.0311 66.4199 53.4215 64.9555 54.3998Z"
                stroke="#E4EBE6"
                stroke-width="4.18954"
            />
        </g>
        <circle cx="60" cy="60" r="55" stroke="#F1F5F3" stroke-width="10" />
    </svg>
</template>
