<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_812)">
            <path
                d="M18.8164 23.0586H5.19531C2.85547 23.0586 0.960938 21.1641 0.960938 18.8242V5.20313C0.960938 2.86719 2.85547 0.972656 5.19531 0.972656H18.8164C21.1524 0.972656 23.0508 2.86719 23.0508 5.20313V18.8242C23.0508 21.1641 21.1524 23.0586 18.8164 23.0586Z"
                stroke="#E41F25"
                stroke-width="1.88883"
                stroke-miterlimit="22.926"
            />
            <path
                d="M2.04688 21.9727L21.9649 2.05469"
                stroke="#E41F25"
                stroke-width="1.84064"
                stroke-miterlimit="22.926"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.35156 12.1094C6.36328 12.1094 7.18359 11.2891 7.18359 10.2812C7.18359 9.27344 6.36328 8.44922 5.35156 8.44922C4.34375 8.44922 3.52344 9.27344 3.52344 10.2812C3.52344 11.2891 4.34375 12.1094 5.35156 12.1094Z"
                fill="#2B2B2A"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.6602 7.48438H15.9922C16.3477 7.48438 16.6406 7.77344 16.6406 8.12891C16.6406 8.48828 16.3477 8.77734 15.9922 8.77734H12.2031C11.7539 9.21875 11.3008 9.66406 10.8516 10.1055C12.7695 10.8203 14.6914 11.5352 16.6094 12.25C16.1289 12.2031 15.5859 12.1641 15.082 12.2422C14.1172 12.3945 12.8203 13.2344 11.8008 13.1602C11.0625 13.1016 9.98828 12.4531 8.99219 12.2969C8.12109 12.1641 7.40625 12.2695 6.41406 12.6328C8.16016 10.918 9.91016 9.19922 11.6602 7.48438Z"
                fill="#2B2B2A"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.64844 14.8906C3.39453 14.8906 3.1875 14.6836 3.1875 14.4297C3.1875 14.1758 3.39453 13.9688 3.64844 13.9688C4.24609 13.9688 4.89062 13.6875 5.53125 13.4102C6.26953 13.0898 7.00391 12.7695 7.78516 12.7695C8.56641 12.7695 9.30469 13.0898 10.0391 13.4102C10.6836 13.6875 11.3242 13.9688 11.9219 13.9688C12.5195 13.9688 13.1641 13.6875 13.8086 13.4102C14.543 13.0898 15.2773 12.7695 16.0586 12.7695C16.8437 12.7695 17.5781 13.0898 18.3125 13.4102C18.957 13.6875 19.6016 13.9688 20.1953 13.9688C20.4531 13.9688 20.6602 14.1758 20.6602 14.4297C20.6602 14.6836 20.4531 14.8906 20.1953 14.8906C19.4141 14.8906 18.6797 14.5703 17.9453 14.2539C17.3008 13.9727 16.6562 13.6914 16.0586 13.6914C15.4648 13.6914 14.8203 13.9727 14.1758 14.2539C13.4414 14.5703 12.7031 14.8906 11.9219 14.8906C11.1406 14.8906 10.4062 14.5703 9.67188 14.2539C9.02734 13.9727 8.38281 13.6914 7.78516 13.6914C7.1875 13.6914 6.54297 13.9727 5.89844 14.2539C5.16406 14.5703 4.42969 14.8906 3.64844 14.8906Z"
                fill="#2B2B2A"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.64844 16.6797C3.39453 16.6797 3.1875 16.4727 3.1875 16.2188C3.1875 15.9648 3.39453 15.7578 3.64844 15.7578C4.24609 15.7578 4.89062 15.4766 5.53125 15.1953C6.26953 14.8789 7.00391 14.5586 7.78516 14.5586C8.56641 14.5586 9.30469 14.8789 10.0391 15.1953C10.6836 15.4766 11.3242 15.7578 11.9219 15.7578C12.5195 15.7578 13.1641 15.4766 13.8086 15.1953C14.543 14.8789 15.2773 14.5586 16.0586 14.5586C16.8437 14.5586 17.5781 14.8789 18.3125 15.1953C18.957 15.4766 19.6016 15.7578 20.1953 15.7578C20.4531 15.7578 20.6602 15.9648 20.6602 16.2188C20.6602 16.4727 20.4531 16.6797 20.1953 16.6797C19.4141 16.6797 18.6797 16.3594 17.9453 16.0391C17.3008 15.7617 16.6562 15.4805 16.0586 15.4805C15.4648 15.4805 14.8203 15.7617 14.1758 16.0391C13.4414 16.3594 12.7031 16.6797 11.9219 16.6797C11.1406 16.6797 10.4062 16.3594 9.67188 16.0391C9.02734 15.7617 8.38281 15.4805 7.78516 15.4805C7.1875 15.4805 6.54297 15.7617 5.89844 16.0391C5.16406 16.3594 4.42969 16.6797 3.64844 16.6797Z"
                fill="#2B2B2A"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_812">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
