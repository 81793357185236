<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>
<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16.0003" cy="15.9993" r="12.3333" stroke="#798877" stroke-width="2" />
        <path
            d="M15.0732 18.5931V19.9993H11.0316V18.5931H15.0732ZM11.6826 12.416V19.9993H9.85449V12.416H11.6826ZM14.5524 15.4264V16.7858H11.0316V15.4264H14.5524ZM15.0889 12.416V13.8275H11.0316V12.416H15.0889Z"
            fill="#798877"
        />
        <path
            d="M18.0785 12.416L19.318 14.9368L20.5576 12.416H22.6409L20.5264 16.1764L22.6982 19.9993H20.5941L19.318 17.4316L18.042 19.9993H15.9326L18.1097 16.1764L15.9899 12.416H18.0785Z"
            fill="#798877"
        />
    </svg>
</template>
