<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3634_38354)">
            <g clip-path="url(#clip1_3634_38354)">
                <path
                    d="M25.3423 35.2148L20.625 40.1034L31.2083 51.0796C29.2527 53.1076 27.6171 55.442 26.3613 57.9974C24.4162 63.1462 24.0033 68.7814 25.1757 74.176C26.3481 79.5706 29.052 84.4771 32.9388 88.2627C36.5475 91.373 40.7644 93.6334 45.2981 94.8876C49.8318 96.1417 54.574 96.3596 59.1969 95.5264C63.0448 94.9211 66.7317 93.5018 70.0284 91.3565L79.1813 100.849L83.8986 95.9568L25.3423 35.2148Z"
                    fill="#022D05"
                />
                <path
                    d="M82.9516 75.3682C85.1038 69.1692 85.4314 62.4467 83.8928 56.0543C82.3542 49.6618 79.0188 43.8877 74.3101 39.4649C75.8966 43.239 76.517 47.3738 76.1112 51.4694C75.7055 55.565 74.2874 59.4826 71.9941 62.8432C70.5674 64.957 68.9443 65.422 67.8178 64.6764C66.6913 63.9308 66.3096 62.8662 67.2434 60.3797C69.9919 53.3744 70.4861 45.6361 68.6527 38.316C66.8193 30.9959 62.7561 24.4854 57.0678 19.7531C53.9128 16.9994 50.2408 14.9552 46.2844 13.75C48.2804 17.9838 48.9882 22.7469 48.3138 27.4074C47.6394 32.068 45.6147 36.4058 42.5084 39.8454C42.1378 40.2643 41.5338 40.837 40.8594 41.4826L79.7425 81.8094C81.0624 79.8067 82.1401 77.6434 82.9516 75.3682Z"
                    fill="#022D05"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_3634_38354">
                <rect width="110" height="110" fill="white" />
            </clipPath>
            <clipPath id="clip1_3634_38354">
                <rect
                    width="64.1667"
                    height="87.0833"
                    fill="white"
                    transform="translate(20.625 13.75)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
