<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
    }
}
const props = defineProps<Props>()
</script>
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M11.6484 8.60156V15H10.1104L7.84717 11.0186V15H6.30469V8.60156H7.84717L10.1104 12.583V8.60156H11.6484Z"
            :fill="props.color.fill"
        />
        <path
            d="M16.0903 8.60156V15H14.5479V8.60156H16.0903ZM18.0151 8.60156V9.79248H12.667V8.60156H18.0151Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
