<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
    }
}
const props = defineProps<Props>()
</script>

<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M10.2119 13.8135V15H6.80176V13.8135H10.2119ZM7.35107 8.60156V15H5.80859V8.60156H7.35107ZM9.77246 11.1416V12.2886H6.80176V11.1416H9.77246ZM10.2251 8.60156V9.79248H6.80176V8.60156H10.2251Z"
            :fill="props.color.fill"
        />
        <path
            d="M12.8213 14.4199L14.0957 8.60156H14.856L15.269 9.09814L13.9111 15H13.0718L12.8213 14.4199ZM12.4829 8.60156L13.5596 14.4639L13.2827 15H12.3115L10.9536 8.60156H12.4829ZM15.9678 14.4331L17.0356 8.60156H18.5605L17.2026 15H16.2358L15.9678 14.4331ZM15.4053 8.60156L16.7104 14.4551L16.4468 15H15.6074L14.2188 9.08936L14.6494 8.60156H15.4053Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
