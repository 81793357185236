<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_441)">
            <path
                d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                fill="#ADBCAA"
            />
            <path
                d="M8.08594 9.78906L8.69531 17.4258C8.69531 17.4258 8.79687 18.6172 9.99609 18.6172H13.9687C13.9687 18.6172 15.168 18.6172 15.2695 17.4258L15.875 9.78906H8.08594Z"
                fill="#3F674D"
            />
            <path
                d="M8.08594 9.78905L8.69531 17.4258C8.69531 17.4258 8.79688 18.6172 9.9961 18.6172H13.9688C13.9688 18.6172 15.168 18.6172 15.2695 17.4258L15.875 9.78905H8.08594Z"
                stroke="#3F674D"
                stroke-width="0.698123"
                stroke-linecap="round"
            />
            <path
                d="M16.2539 7.92579L16.25 7.89454C16.25 7.89454 16.1289 6.84376 15.0781 6.96486L8.63671 7.69923C8.63671 7.69923 7.58593 7.81642 7.70312 8.87111L7.70703 8.89845L16.2539 7.92579Z"
                fill="#3F674D"
            />
            <path
                d="M16.2539 7.92578L16.25 7.89453C16.25 7.89453 16.1289 6.84375 15.0781 6.96484L8.63672 7.69922C8.63672 7.69922 7.58593 7.8164 7.70312 8.87109L7.70703 8.89844L16.2539 7.92578Z"
                stroke="#3F674D"
                stroke-width="0.698123"
                stroke-linecap="round"
            />
            <path
                d="M10.4258 6.52344L10.3672 5.98828C10.3672 5.98828 10.3047 5.44141 10.6992 5.39453L13 5.13281C13 5.13281 13.3984 5.08984 13.4609 5.63672L13.5117 6.10156C13.5117 6.10156 13.5156 6.13281 13.5156 6.17187"
                stroke="#3F674D"
                stroke-width="0.734695"
                stroke-linecap="round"
            />
            <path
                d="M12.043 11.0195V16.9961"
                stroke="#ADBCAA"
                stroke-width="0.734695"
                stroke-linecap="round"
            />
            <path
                d="M14.2227 11.0234L13.7812 16.9883"
                stroke="#ADBCAA"
                stroke-width="0.734695"
                stroke-linecap="round"
            />
            <path
                d="M9.83984 11.0234L10.2813 16.9883"
                stroke="#ADBCAA"
                stroke-width="0.734695"
                stroke-linecap="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_441">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
