<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>
<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M10.3828 13.9453V15H7.35156V13.9453H10.3828ZM7.83984 9.3125V15H6.46875V9.3125H7.83984ZM9.99219 11.5703V12.5898H7.35156V11.5703H9.99219ZM10.3945 9.3125V10.3711H7.35156V9.3125H10.3945Z"
            :fill="props.color"
        />
        <path
            d="M12.7578 14.4844L13.8906 9.3125H14.5664L14.9336 9.75391L13.7266 15H12.9805L12.7578 14.4844ZM12.457 9.3125L13.4141 14.5234L13.168 15H12.3047L11.0977 9.3125H12.457ZM15.5547 14.4961L16.5039 9.3125H17.8594L16.6523 15H15.793L15.5547 14.4961ZM15.0547 9.3125L16.2148 14.5156L15.9805 15H15.2344L14 9.74609L14.3828 9.3125H15.0547Z"
            :fill="props.color"
        />
    </svg>
</template>

<style lang="scss" scoped></style>
