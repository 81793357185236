<script setup lang="ts">
export interface Props {
    strokeColor?: string
}

const props = withDefaults(defineProps<Props>(), {
    strokeColor: '#022D05',
})
</script>

<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 12L20 12" :stroke="props.strokeColor" stroke-width="2" />
    </svg>
</template>
