export const facebookLink = 'https://www.facebook.com/eupure.eu'
export const instagramLink = 'https://www.instagram.com/eupure.eu'
export const twitterLink = 'https://twitter.com/eupure_eu'

export const apleStoreLink = 'https://eupure.eu/ios'
export const googlePlayLink = 'https://eupure.eu/android'

export const appstoreDirectLink = 'https://apps.apple.com/us/app/epu/id6450456064'
export const googlePlayDirectLink = 'https://play.google.com/store/apps/details?id=cz.algodos.epu'

export const appUrl = 'https://eupure.eu'
export const canonicalUrlBase = appUrl
