<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M9.91012 15.12C9.35678 15.12 8.84345 15 8.37012 14.76C7.90345 14.5133 7.52678 14.1433 7.24012 13.65C6.95345 13.1567 6.81012 12.54 6.81012 11.8C6.81012 11.2533 6.89345 10.77 7.06012 10.35C7.23345 9.92333 7.46678 9.56333 7.76012 9.27C8.06012 8.97667 8.39678 8.75667 8.77012 8.61C9.15012 8.45667 9.54345 8.38 9.95012 8.38C10.3835 8.38 10.7701 8.46667 11.1101 8.64C11.4568 8.80667 11.7435 9.00667 11.9701 9.24L11.0701 10.34C10.9101 10.2 10.7435 10.0867 10.5701 10C10.4035 9.90667 10.2101 9.86 9.99012 9.86C9.73678 9.86 9.50012 9.93667 9.28012 10.09C9.06678 10.2367 8.89345 10.45 8.76012 10.73C8.63345 11.01 8.57012 11.3467 8.57012 11.74C8.57012 12.3467 8.69678 12.8167 8.95012 13.15C9.21012 13.4767 9.55012 13.64 9.97012 13.64C10.2235 13.64 10.4468 13.5833 10.6401 13.47C10.8401 13.3567 11.0101 13.2267 11.1501 13.08L12.0501 14.16C11.7768 14.48 11.4568 14.72 11.0901 14.88C10.7235 15.04 10.3301 15.12 9.91012 15.12ZM12.5895 15V14.02C13.0895 13.56 13.5395 13.1333 13.9395 12.74C14.3462 12.3467 14.6662 11.9833 14.8995 11.65C15.1328 11.3167 15.2495 11.0067 15.2495 10.72C15.2495 10.4333 15.1762 10.2233 15.0295 10.09C14.8895 9.95 14.7028 9.88 14.4695 9.88C14.2628 9.88 14.0762 9.94333 13.9095 10.07C13.7428 10.19 13.5828 10.3267 13.4295 10.48L12.5095 9.56C12.8428 9.20667 13.1762 8.95 13.5095 8.79C13.8428 8.62333 14.2428 8.54 14.7095 8.54C15.1362 8.54 15.5128 8.62667 15.8395 8.8C16.1662 8.96667 16.4228 9.20667 16.6095 9.52C16.7962 9.83333 16.8895 10.2 16.8895 10.62C16.8895 10.96 16.7962 11.3067 16.6095 11.66C16.4295 12.0133 16.1995 12.36 15.9195 12.7C15.6395 13.0333 15.3562 13.3467 15.0695 13.64C15.2295 13.62 15.4095 13.6033 15.6095 13.59C15.8162 13.57 15.9895 13.56 16.1295 13.56H17.1895V15H12.5895Z"
            :fill="props.color"
        />
    </svg>
</template>
