<script setup lang="ts">
interface Props {
    color?: string
    rotate?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    color: '#022D05',
    rotate: false,
})
</script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="rotate ? 'rotate' : ''"
    >
        <path
            d="M19.7482 8.74821L12 16.4964L4.25179 8.74821"
            :stroke="props.color"
            stroke-width="2"
        />
    </svg>
</template>

<style scoped lang="scss">
svg {
    transition: all 0.3s ease-in-out;
    path {
        transition: all 0.3s ease-in-out;
    }
}
.rotate {
    transform: rotate(-180deg);
}
</style>
