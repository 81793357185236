<template>
    <svg
        width="110"
        height="111"
        viewBox="0 0 110 111"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38385)">
            <path
                d="M53.4847 28.7989C55.2836 29.0232 57.1086 28.7093 58.729 27.8968C60.3494 27.0843 61.6924 25.8098 62.5883 24.2344C63.4842 22.659 63.8926 20.8534 63.762 19.046C63.6314 17.2386 62.9676 15.5104 61.8545 14.0801C60.7415 12.6497 59.2291 11.5814 57.5087 11.0101C55.7883 10.4389 53.9371 10.3904 52.1891 10.8708C50.4412 11.3512 48.8749 12.3389 47.6885 13.709C46.502 15.0791 45.7486 16.7701 45.5234 18.5682C45.3702 19.7631 45.4548 20.9765 45.7725 22.1386C46.0903 23.3007 46.6348 24.3885 47.3747 25.3393C48.1147 26.2902 49.0354 27.0854 50.084 27.6791C51.1326 28.2729 52.2884 28.6535 53.4847 28.7989Z"
                fill="#022D05"
            />
            <path
                d="M105.415 76.3708V69.0403H74.9041L70.5133 60.6513L89.3049 25.2813L83.2366 22.0742L77.9154 32.0804H42.9308V32.1263C42.8254 32.1263 42.7291 32.085 42.6191 32.0896C40.6503 32.1799 38.7396 32.785 37.0779 33.8444C35.3902 34.9016 34.076 36.4605 33.3195 38.3023L25.6379 57.1968C24.8286 59.1118 24.6676 61.2388 25.1795 63.2537C25.6044 65.2467 26.6033 67.0718 28.0533 68.5042C28.2615 68.6969 28.4819 68.876 28.7133 69.0403H4.58203V76.3708H54.3754L50.1724 84.2695L43.7191 88.7045L35.9274 103.393L48.3941 110.009L56.1858 95.3478L56.2454 87.5133L62.1579 76.3937L105.415 76.3708ZM66.6495 53.275L62.5474 45.4404L46.0062 55.7994L52.7345 40.167H73.6162L66.6495 53.275ZM47.1245 69.0403C52.3129 65.4025 59.3941 59.3868 59.3941 59.3868L61.3191 63.2995L58.2666 69.0403H47.1245Z"
                fill="#022D05"
            />
        </g>
        <defs>
            <clipPath id="clip0_3632_38385">
                <rect
                    width="100.833"
                    height="99.4621"
                    fill="white"
                    transform="translate(4.58203 10.543)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
