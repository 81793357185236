<script setup lang="ts">
interface Props {
    color: {
        fill: string
        stroke: string
        border: string
    }
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 31,
    width: 24,
})
</script>
<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M24 30L-2.38419e-07 30" :stroke="props.color.border" stroke-width="2" />
        <circle cx="12" cy="12" r="11" :fill="props.color.stroke" />
        <path
            d="M10.9619 13.8135V15H7.55176V13.8135H10.9619ZM8.10107 8.60156V15H6.55859V8.60156H8.10107ZM10.5225 11.1416V12.2886H7.55176V11.1416H10.5225ZM10.9751 8.60156V9.79248H7.55176V8.60156H10.9751Z"
            :fill="props.color.fill"
        />
        <path
            d="M17.4385 8.60156V15H15.9004L13.6372 11.0186V15H12.0947V8.60156H13.6372L15.9004 12.583V8.60156H17.4385Z"
            :fill="props.color.fill"
        />
    </svg>
</template>
