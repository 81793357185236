<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38449)">
            <path
                d="M53.9908 30.6031C55.7676 30.2423 57.3974 29.3628 58.6743 28.0757C59.9511 26.7886 60.8177 25.1518 61.1643 23.3723C61.5109 21.5927 61.322 19.7503 60.6215 18.0781C59.921 16.4059 58.7404 14.979 57.229 13.9777C55.7175 12.9765 53.9431 12.446 52.1301 12.4532C50.3171 12.4604 48.547 13.0051 47.0436 14.0184C45.5402 15.0316 44.371 16.4679 43.6839 18.1457C42.9967 19.8234 42.8226 21.6672 43.1833 23.444C43.6738 25.8229 45.0857 27.911 47.1106 29.2524C49.1356 30.5938 51.6091 31.0794 53.9908 30.6031Z"
                fill="#022D05"
            />
            <path
                d="M64.8984 52.9742V56.1825C79.5651 47.7217 89.6943 25.1075 92.9209 7.35625L90.2214 6.875C87.2009 23.4529 78.0709 44.3667 64.8984 52.9742Z"
                fill="#022D05"
            />
            <path d="M59.3008 106.224H62.0462V73.9297L59.3008 74.4843V106.224Z" fill="#022D05" />
            <path
                d="M61.8062 59.8844V44.0444L80.4925 16.3519L73.7917 11.8281L58.6667 34.2865C54.7367 33.7788 50.7432 34.1354 46.9654 35.3315L32.3171 40.3319L29.0262 28.2319L20.625 30.7848L26.3954 51.4098L43.6379 45.7081V70.3298L29.4754 100.979L38.7658 105.654L55.0962 72.154L70.29 68.996L74.4471 86.2431L83.3067 84.4098L77.3483 57.139L61.8062 59.8844Z"
                fill="#022D05"
            />
        </g>
        <defs>
            <clipPath id="clip0_3632_38449">
                <rect
                    width="72.2975"
                    height="99.3483"
                    fill="white"
                    transform="translate(20.625 6.875)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
