<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38448)">
            <path
                d="M59.2295 18.332H32.082V91.6654H47.5949V67.2209H59.2295C72.0663 67.2209 82.4987 56.2617 82.4987 42.7765C82.4987 29.2913 72.0663 18.332 59.2295 18.332ZM60.0051 50.9246H47.5949V34.6283H60.0051C64.2711 34.6283 67.7615 38.295 67.7615 42.7765C67.7615 47.258 64.2711 50.9246 60.0051 50.9246Z"
                fill="#022D05"
            />
        </g>
        <defs>
            <clipPath id="clip0_3632_38448">
                <rect width="110" height="110" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
