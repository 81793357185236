<template>
    <svg
        width="140"
        height="140"
        viewBox="0 0 140 140"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle opacity="0.1" cx="70" cy="70" r="70" fill="#808080" />
        <circle cx="70" cy="70" r="60" fill="#808080" />
        <g clip-path="url(#clip0_4343_92628)">
            <path d="M78 55H56V59H78V55Z" fill="white" />
            <path d="M78 63H56V67H78V63Z" fill="white" />
            <path d="M69 71H56V75H69V71Z" fill="white" />
            <path
                d="M93.9877 72.9063L92.0937 71.0123C91.847 70.7651 91.5539 70.5689 91.2313 70.4351C90.9088 70.3013 90.5629 70.2324 90.2137 70.2324C89.8644 70.2324 89.5186 70.3013 89.196 70.4351C88.8734 70.5689 88.5804 70.7651 88.3337 71.0123L85.9717 73.3753L91.6247 79.0283L93.9877 76.6663C94.2349 76.4196 94.431 76.1265 94.5648 75.8039C94.6987 75.4813 94.7675 75.1355 94.7675 74.7863C94.7675 74.437 94.6987 74.0912 94.5648 73.7686C94.431 73.446 94.2349 73.153 93.9877 72.9063Z"
                fill="white"
            />
            <path
                d="M69 90.3469V95.9999H74.653L88.787 81.8669L83.133 76.2129L69 90.3469Z"
                fill="white"
            />
            <path
                d="M83.9996 95.9996C85.0604 95.9996 86.0778 95.5781 86.828 94.828C87.5781 94.0778 87.9996 93.0604 87.9996 91.9996V88.3096L80.3096 95.9996H83.9996Z"
                fill="white"
            />
            <path
                d="M50 48H84V69.69L85.506 68.184C86.2094 67.4811 87.0617 66.9453 88 66.616V48C88 46.9391 87.5786 45.9217 86.8284 45.1716C86.0783 44.4214 85.0609 44 84 44H50C48.9391 44 47.9217 44.4214 47.1716 45.1716C46.4214 45.9217 46 46.9391 46 48V92C46 93.0609 46.4214 94.0783 47.1716 94.8284C47.9217 95.5786 48.9391 96 50 96H65V92H50V48Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4343_92628">
                <rect width="48.768" height="52" fill="white" transform="translate(46 44)" />
            </clipPath>
        </defs>
    </svg>
</template>
