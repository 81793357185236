<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16" cy="16" r="12" stroke="#798877" stroke-width="2" />
        <path
            d="M12.9714 16.37L12.7954 17.03H14.3244L14.1594 16.37C14.0641 16.0107 13.9651 15.6293 13.8624 15.226C13.7671 14.8227 13.6717 14.434 13.5764 14.06H13.5324C13.4444 14.4413 13.3527 14.8337 13.2574 15.237C13.1694 15.633 13.0741 16.0107 12.9714 16.37ZM10.3754 20L12.6194 12.828H14.5664L16.8104 20H15.0944L14.6544 18.295H12.4654L12.0254 20H10.3754ZM17.1541 20V19.076C17.7481 18.5113 18.2724 18.0017 18.7271 17.547C19.1891 17.085 19.5484 16.6633 19.8051 16.282C20.0617 15.8933 20.1901 15.534 20.1901 15.204C20.1901 14.8593 20.0984 14.599 19.9151 14.423C19.7391 14.2397 19.5007 14.148 19.2001 14.148C18.9507 14.148 18.7234 14.2213 18.5181 14.368C18.3201 14.5073 18.1331 14.6723 17.9571 14.863L17.0771 13.994C17.4217 13.6273 17.7737 13.3523 18.1331 13.169C18.4924 12.9783 18.9214 12.883 19.4201 12.883C19.8821 12.883 20.2854 12.9747 20.6301 13.158C20.9747 13.3413 21.2424 13.6017 21.4331 13.939C21.6311 14.269 21.7301 14.6577 21.7301 15.105C21.7301 15.4937 21.6201 15.8933 21.4001 16.304C21.1874 16.7073 20.9124 17.1143 20.5751 17.525C20.2377 17.9283 19.8784 18.328 19.4971 18.724C19.6731 18.702 19.8711 18.6837 20.0911 18.669C20.3111 18.647 20.5017 18.636 20.6631 18.636H22.0711V20H17.1541Z"
            fill="#798877"
        />
    </svg>
</template>
