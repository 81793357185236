// available app languages
export const AppLanguages = [
    'cs',
    // 'en',
    // 'de',
    // 'sk'
]

// default fallback language in case of internal app error
export const FallbackLanguage = 'cs'
// language codes translation map
export const LanguageCodes = {
    cs: 'Czech',
    // en: 'English',
    // de: 'German',
    // sk: 'Slovak'
}
export const FallbackLocation = [10.451526, 51.165691]
