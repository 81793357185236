<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16" cy="16" r="12" stroke="#798877" stroke-width="2" />
        <path
            d="M14.2321 20.132C13.6307 20.132 13.0771 19.9927 12.5711 19.714C12.0651 19.4353 11.6581 19.0247 11.3501 18.482C11.0494 17.932 10.8991 17.2573 10.8991 16.458C10.8991 15.666 11.0531 14.9913 11.3611 14.434C11.6764 13.8693 12.0907 13.4403 12.6041 13.147C13.1247 12.8463 13.6857 12.696 14.2871 12.696C14.7491 12.696 15.1634 12.7913 15.5301 12.982C15.8967 13.1653 16.2011 13.3817 16.4431 13.631L15.5851 14.665C15.4017 14.4963 15.2074 14.3607 15.0021 14.258C14.8041 14.148 14.5767 14.093 14.3201 14.093C13.9974 14.093 13.7004 14.1847 13.4291 14.368C13.1651 14.5513 12.9524 14.8153 12.7911 15.16C12.6371 15.5047 12.5601 15.919 12.5601 16.403C12.5601 17.1363 12.7177 17.7083 13.0331 18.119C13.3484 18.5297 13.7701 18.735 14.2981 18.735C14.5914 18.735 14.8517 18.669 15.0791 18.537C15.3137 18.405 15.5191 18.2473 15.6951 18.064L16.5531 19.076C15.9517 19.78 15.1781 20.132 14.2321 20.132ZM17.2024 20V19.076C17.7964 18.5113 18.3207 18.0017 18.7754 17.547C19.2374 17.085 19.5967 16.6633 19.8534 16.282C20.1101 15.8933 20.2384 15.534 20.2384 15.204C20.2384 14.8593 20.1467 14.599 19.9634 14.423C19.7874 14.2397 19.5491 14.148 19.2484 14.148C18.9991 14.148 18.7717 14.2213 18.5664 14.368C18.3684 14.5073 18.1814 14.6723 18.0054 14.863L17.1254 13.994C17.4701 13.6273 17.8221 13.3523 18.1814 13.169C18.5407 12.9783 18.9697 12.883 19.4684 12.883C19.9304 12.883 20.3337 12.9747 20.6784 13.158C21.0231 13.3413 21.2907 13.6017 21.4814 13.939C21.6794 14.269 21.7784 14.6577 21.7784 15.105C21.7784 15.4937 21.6684 15.8933 21.4484 16.304C21.2357 16.7073 20.9607 17.1143 20.6234 17.525C20.2861 17.9283 19.9267 18.328 19.5454 18.724C19.7214 18.702 19.9194 18.6837 20.1394 18.669C20.3594 18.647 20.5501 18.636 20.7114 18.636H22.1194V20H17.2024Z"
            fill="#798877"
        />
    </svg>
</template>
