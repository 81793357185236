import { defineStore } from 'pinia'
import { FallbackLocation } from '@/data/constants/locales'
// import { getDefaultTripsUserFilter } from '../../helpers/filtersHelper'
// import { removeUserDataFromStore } from '@/plugins/db.plugin'
import { useToast } from 'vue-toastification'
import { i18n } from '../../i18n'
import { emailRegex, userNameRegex } from '@/utils/epu/regex'
import axios from 'axios'
import firebase from 'firebase/app'
import type { UsersStoreInterface } from '../../types/storesInterfaces/userStoreInterface'
import { getActivePinia } from 'pinia'
import type { Store, Pinia } from 'pinia'
import type { UserData } from '@/types/user/user'
import { baseAPIUrl } from '@/data/constants/urls'
import { setGoogleTagManagerUserEmail, setGoogleTagManagerUserId } from '@/helpers/gtm.helper'

const baseUrl = baseAPIUrl
axios.defaults.baseURL = baseUrl
const toast = useToast()

interface ExtendedPinia extends Pinia {
    _s: Map<string, Store>
}

// TODO types
export const useUserStore = defineStore({
    id: 'userStore',
    state: (): UsersStoreInterface => ({
        isLoggedIn: false,
        userData: undefined,
        userLocation: {
            locations: { 0: FallbackLocation } as Record<string, unknown>,
            zoom: 12,
            initialized: false,
        },
        triedToLogin: false,
        // userOfferings: undefined,
        currency: undefined,
        lastUserAccountChange: undefined,
        userLists: [] as string[],
        // userFilter: getDefaultTripsUserFilter(),
        userIcon: undefined,
        userReauthenticated: false,
        searchedUsers: undefined,
        searchAreas: undefined,
        loadingUsersQuery: false,
        error: undefined,
    }),
    getters: {
        isUserLoggedIn(state) {
            if (state.userData) {
                return true
            } else {
                return false
            }
        },
        getUserLocation(state) {
            const sortedKeys = Object.keys(state.userLocation.locations).sort()
            return state.userLocation.locations[sortedKeys[sortedKeys.length - 1]]
        },
        getUserGeoLocation: (state) => {
            const sortedKeys = Object.keys(state.userLocation.locations)
                .sort()
                .filter((k) => k <= '3') // 3 is geolocation limit
            return state.userLocation.locations[sortedKeys[sortedKeys.length - 1]]
        },
        async getUserToken(state) {
            if (state.userData) {
                const token = await state.userData.getIdToken()
                window.localStorage.setItem('token', JSON.stringify(token))
                return
            }
        },
    },
    actions: {
        async setUserData(userData: UserData) {
            return new Promise((resolve, reject) => {
                try {
                    this.userData = undefined

                    const uid = userData.uid
                    const email = userData.email
                    setGoogleTagManagerUserId(uid)
                    setGoogleTagManagerUserEmail(email)

                    console.log('USER DATA CHANGE', userData, uid, email)

                    resolve((this.userData = userData))
                } catch (error) {
                    reject(error)
                }
            })
        },
        setUserLists(userLists: string[]) {
            this.userLists = userLists
        },
        setLastUserAccountChange(lastUserAccountChange) {
            this.lastUserAccountChange = lastUserAccountChange
        },
        async signOut() {
            // const resetStore: Record<string, () => void> = {}
            firebase
                .auth()
                .signOut()
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .then(async (_) => {
                    // await removeUserDataFromStore()
                    this.userData = undefined
                    toast.success(i18n.global.t('componentsEPU.loginModal.loggedOut'))

                    //reset stores
                    const pinia = getActivePinia() as ExtendedPinia
                    if (!pinia) {
                        throw new Error('There is no active Pinia instance')
                    }
                    pinia._s.forEach((store) => store.$reset())

                    setTimeout(() => {
                        this.$router.push({ name: 'home' })
                    }, 500)
                })
                .catch((error) => {
                    toast.error(error.message)
                })
        },
        async updateUserProfile(email: string, name: string, password: string) {
            console.log(email, name, password)
            this.userReauthenticated = false
            // regex match for email
            if (!emailRegex.test(email)) {
                toast.error(i18n.global.t('generic.regex.invalidEmail'))
                return
            }
            // regex match for name length (min 3)
            if (!userNameRegex.test(name)) {
                toast.error(i18n.global.t('generic.regex.invalidUserName'))
                return
            }
            // Check if provider is password
            // if (this.userData?.providerData) {
            //     this.userData.providerData.forEach((data) => {
            //         if (data.providerId !== 'password') {
            //             toast.error(i18n.global.t('componentsEPU.user.personalData.noProvider'))
            //             return
            //         }
            //     })
            // }
            // get user credential and reauthenticate
            const user = firebase.auth().currentUser
            const credentials = firebase.auth.EmailAuthProvider.credential(email, password)
            if (user !== null) {
                await user
                    .reauthenticateWithCredential(credentials)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .then(async (_) => {
                        this.userReauthenticated = true
                        toast.success(i18n.global.t('componentsEPU.user.personalData.userAuthenticated'))
                        await user.updateEmail(email)
                        await user.updatePassword(password)
                        await user.updateProfile({
                            displayName: name,
                        })
                        toast.success(i18n.global.t('componentsEPU.user.personalData.userProfileUpdated'))
                    })
                    .catch((error) => {
                        toast.error(error.message)
                    })
            }
        },
        async updateUserProfileName(name: string) {
            if (!userNameRegex.test(name)) {
                toast.error(i18n.global.t('generic.regex.invalidUserName'))
                return
            }
            const user = firebase.auth().currentUser
            if (user !== null) {
                ;(async () => {
                    try {
                        await user.updateProfile({
                            displayName: name,
                        })
                        toast.success(i18n.global.t('componentsEPU.user.personalData.userProfileUpdated'))
                    } catch (error) {
                        toast.error(error.message)
                    }
                })()
            }
        },
        async resetUserPassword(email: string) {
            // get user credential and reauthenticate
            const user = firebase.auth()
            if (user !== null) {
                await user
                    .sendPasswordResetEmail(email)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .then(async (_) => {
                        toast.success(i18n.global.t('componentsEPU.user.personalData.userResetPassword'))
                    })
                    .catch((error) => {
                        toast.error(error.message)
                    })
            }
        },
        async setUserPassword(password: string, newPassword: string) {
            this.error = undefined
            this.userReauthenticated = false
            if (this.userData?.providerData) {
                this.userData.providerData.forEach((data) => {
                    if (data.providerId !== 'password') {
                        toast.error(i18n.global.t('componentsEPU.user.personalData.noProvider'))
                        return
                    }
                })
            }
            if (!this.userData?.email) {
                toast.error(i18n.global.t('componentsEPU.user.personalData.noEmail'))
                return
            }
            // get user credential and reauthenticate
            const credentials = await firebase.auth.EmailAuthProvider.credential(this.userData.email, password)
            const user = firebase.auth().currentUser
            if (user !== null) {
                await user
                    .reauthenticateWithCredential(credentials)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .then(async (_) => {
                        this.userReauthenticated = true
                        toast.success(i18n.global.t('componentsEPU.user.personalData.userAuthenticated'))
                        await user.updatePassword(newPassword)
                        toast.success(i18n.global.t('componentsEPU.user.personalData.passwordChanged'))
                    })
                    .catch((error) => {
                        this.error = error.message
                        if (error.code.includes('auth/invalid-email')) {
                            toast.error(i18n.global.t('firebase_auth_invalid_user_exception_message'))
                        } else if (error.code.includes('auth/internal-error')) {
                            toast.error(i18n.global.t('firebase_auth_weak_password_exception_message'))
                        } else if (error.code.includes('auth/wrong-password')) {
                            toast.error(i18n.global.t('firebase_auth_invalid_credentials_exception_message'))
                        } else {
                            toast.error(error.message)
                        }
                    })
            }
        },
        async reAuthUser(password: string) {
            this.userReauthenticated = false
            // if (this.userData?.providerData) {
            //     this.userData.providerData.forEach((data) => {
            //         if (data.providerId !== 'password') {
            //             toast.error(i18n.global.t('componentsEPU.user.personalData.noProvider'))
            //             return
            //         }
            //     })
            // }
            // if (!this.userData?.email) {
            //     toast.error(i18n.global.t('componentsEPU.user.personalData.noEmail'))
            //     return
            // }
            // get user credential and reauthenticate
            const credentials = await firebase.auth.EmailAuthProvider.credential(this.userData.email, password)
            const user = firebase.auth().currentUser
            if (user !== null) {
                await user
                    .reauthenticateWithCredential(credentials)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .then(async (_) => {
                        this.userReauthenticated = true
                        toast.success(i18n.global.t('componentsEPU.user.personalData.userAuthenticated'))
                    })
                    .catch((error) => {
                        this.userReauthenticated = false

                        toast.error(error.message)
                    })
            }
        },
        async deleteUserAccount() {
            if (!this.userData) return
            const idToken = await this.userData.getIdToken()
            try {
                const response = await axios.post(
                    'users/delete_account',
                    {
                        user_id: this.userData.uid,
                    },
                    {
                        headers: {
                            idToken: idToken,
                        },
                    }
                )
                if (response.status === 200) {
                    await this.signOut()
                    toast.success(i18n.global.t('generic.response.deleteUser'))
                    this.isLoggedIn = false
                }
            } catch (error) {
                toast.error(error.message)
            }
        },
        async searchUserByName(name: string) {
            const idToken = await this.userData.getIdToken()
            try {
                const response = await axios.get('users/search_by_name', {
                    headers: {
                        idToken: idToken,
                    },
                    params: {
                        name: name,
                    },
                })
                // console.log(response.data)
                this.searchedUsers = response.data
            } catch (error) {
                toast.error(error.message)
            }
        },
        async getUserFolloweesQ(q = '') {
            this.loadingUsersQuery = true
            const idToken = await this.userData.getIdToken()
            try {
                const response = await axios.get(`users/followees`, {
                    headers: {
                        idToken,
                    },
                    params: {
                        q,
                    },
                })
                console.log(response.data)
                this.searchedUsers = response.data.users
                this.searchAreas = response.data.areas
            } catch (error) {
                toast.error(error.message)
            }
            this.loadingUsersQuery = false
        },
        async invitingUser(invitingUserId: string) {
            this.error = undefined
            const idToken = await this.userData.getIdToken()
            try {
                await axios.post(
                    `users/${invitingUserId}/invite`,
                    {},
                    {
                        headers: {
                            idToken,
                        },
                    }
                )
                toast.success(i18n.global.t('generic.response.invitationSent'))
            } catch (error) {
                this.error = error.message
                toast.error(error.message)
            }
        },
    },
})
