<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M9.91012 15.12C9.35678 15.12 8.84345 15 8.37012 14.76C7.90345 14.5133 7.52678 14.1433 7.24012 13.65C6.95345 13.1567 6.81012 12.54 6.81012 11.8C6.81012 11.2533 6.89345 10.77 7.06012 10.35C7.23345 9.92333 7.46678 9.56333 7.76012 9.27C8.06012 8.97667 8.39678 8.75667 8.77012 8.61C9.15012 8.45667 9.54345 8.38 9.95012 8.38C10.3835 8.38 10.7701 8.46667 11.1101 8.64C11.4568 8.80667 11.7435 9.00667 11.9701 9.24L11.0701 10.34C10.9101 10.2 10.7435 10.0867 10.5701 10C10.4035 9.90667 10.2101 9.86 9.99012 9.86C9.73678 9.86 9.50012 9.93667 9.28012 10.09C9.06678 10.2367 8.89345 10.45 8.76012 10.73C8.63345 11.01 8.57012 11.3467 8.57012 11.74C8.57012 12.3467 8.69678 12.8167 8.95012 13.15C9.21012 13.4767 9.55012 13.64 9.97012 13.64C10.2235 13.64 10.4468 13.5833 10.6401 13.47C10.8401 13.3567 11.0101 13.2267 11.1501 13.08L12.0501 14.16C11.7768 14.48 11.4568 14.72 11.0901 14.88C10.7235 15.04 10.3301 15.12 9.91012 15.12ZM12.8895 15V13.62H14.2495V10.34H13.0495V9.28C13.4095 9.20667 13.7162 9.12333 13.9695 9.03C14.2228 8.93 14.4695 8.80667 14.7095 8.66H15.9695V13.62H17.1095V15H12.8895Z"
            :fill="props.color"
        />
    </svg>
</template>
