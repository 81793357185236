import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { i18n } from '../../i18n'
import type { MapStore } from '@/types/storesInterfaces/mapStoreInterface'

const toast = useToast()

// TODO add types for state redneredTrips are temp
export const mapStoreEPU = defineStore({
    id: 'mapStoreEPU',
    state: (): MapStore => ({
        renderedTrips: undefined,
        latitude: 0,
        longitude: 0,
        isLoading: false,
        drawerClickedTrip: undefined,
        mapDataFirstLoaded: false,
    }),
    actions: {
        async getCurrentPosition() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.latitude = position.coords.latitude
                        this.longitude = position.coords.longitude
                    },
                    () => {
                        this.latitude = 50.073658
                        this.longitude = 14.41854
                        toast.error(i18n.global.t('router.location.unable'))
                    }
                )
            }
            if (!navigator.geolocation) {
                this.latitude = 50.073658
                this.longitude = 14.41854
                toast.error(i18n.global.t('router.location.notSupported'))
            }
        },
    },
})
