<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>
<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M11.3047 13.9453V15H8.27344V13.9453H11.3047ZM8.76172 9.3125V15H7.39062V9.3125H8.76172ZM10.9141 11.5703V12.5898H8.27344V11.5703H10.9141ZM11.3164 9.3125V10.3711H8.27344V9.3125H11.3164Z"
            :fill="props.color"
        />
        <path
            d="M13.5586 9.3125L14.4883 11.2031L15.418 9.3125H16.9805L15.3945 12.1328L17.0234 15H15.4453L14.4883 13.0742L13.5312 15H11.9492L13.582 12.1328L11.9922 9.3125H13.5586Z"
            :fill="props.color"
        />
    </svg>
</template>

<style scoped></style>
