<template>
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3632_38447)">
            <path
                d="M54.9987 99C75.2468 99 91.6654 82.6401 91.6654 62.4643C71.4172 62.4643 54.9987 78.8242 54.9987 99ZM28.9246 51.3006C28.9246 56.9027 33.4876 61.4494 39.1098 61.4494C41.2691 61.4494 43.2246 60.7999 44.895 59.6632L44.8135 60.4345C44.8135 66.0367 49.3765 70.5833 54.9987 70.5833C60.6209 70.5833 65.1839 66.0367 65.1839 60.4345L65.1024 59.6632C66.732 60.7999 68.7283 61.4494 70.8876 61.4494C76.5098 61.4494 81.0728 56.9027 81.0728 51.3006C81.0728 47.2411 78.6691 43.7905 75.2468 42.1667C78.6691 40.5429 81.0728 37.0923 81.0728 33.0327C81.0728 27.4306 76.5098 22.8839 70.8876 22.8839C68.7283 22.8839 66.7728 23.5335 65.1024 24.6701L65.1839 23.8988C65.1839 18.2967 60.6209 13.75 54.9987 13.75C49.3765 13.75 44.8135 18.2967 44.8135 23.8988L44.895 24.6701C43.2654 23.5335 41.2691 22.8839 39.1098 22.8839C33.4876 22.8839 28.9246 27.4306 28.9246 33.0327C28.9246 37.0923 31.3283 40.5429 34.7505 42.1667C31.3283 43.7905 28.9246 47.2411 28.9246 51.3006ZM54.9987 32.0179C60.6209 32.0179 65.1839 36.5645 65.1839 42.1667C65.1839 47.7688 60.6209 52.3155 54.9987 52.3155C49.3765 52.3155 44.8135 47.7688 44.8135 42.1667C44.8135 36.5645 49.3765 32.0179 54.9987 32.0179ZM18.332 62.4643C18.332 82.6401 34.7505 99 54.9987 99C54.9987 78.8242 38.5802 62.4643 18.332 62.4643Z"
                fill="#022D05"
            />
        </g>
        <defs>
            <clipPath id="clip0_3632_38447">
                <rect width="110" height="110" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
