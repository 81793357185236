<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="10.6562"
            y="14.7578"
            width="4"
            height="2"
            transform="rotate(135 10.6562 14.7578)"
            fill="#022D05"
        />
        <path d="M5 19L5 13.6967L10.3033 19L5 19Z" fill="#022D05" />
        <rect
            x="9.24219"
            y="10.6582"
            width="4"
            height="2"
            transform="rotate(-135 9.24219 10.6582)"
            fill="#022D05"
        />
        <path d="M5 5L10.3033 5L5 10.3033L5 5Z" fill="#022D05" />
        <rect
            x="13.3438"
            y="9.24219"
            width="4"
            height="2"
            transform="rotate(-45 13.3438 9.24219)"
            fill="#022D05"
        />
        <path d="M19 5L19 10.3033L13.6967 5L19 5Z" fill="#022D05" />
        <rect
            x="14.7578"
            y="13.3418"
            width="4"
            height="2"
            transform="rotate(45 14.7578 13.3418)"
            fill="#022D05"
        />
        <path d="M19 19L13.6967 19L19 13.6967L19 19Z" fill="#022D05" />
    </svg>
</template>
