<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_459)">
            <path
                d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                fill="#ADBCAA"
            />
            <mask
                id="mask0_448_459"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="9"
                width="18"
                height="15"
            >
                <path d="M0 9H18V24H0V9Z" fill="white" />
            </mask>
            <g mask="url(#mask0_448_459)">
                <mask
                    id="mask1_448_459"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_448_459)">
                    <path
                        d="M-3.48047 18.3867C-3.48047 18.3867 -1.27734 13.2891 -1.16015 13.0859C-1.04687 12.8867 -0.847654 12.8359 -0.562498 12.832C-0.281248 12.8281 9.98438 12.6602 9.98438 12.6602C10.5664 12.6133 11.6602 11.1641 12.6797 10.5781C12.8047 10.5039 13.2852 10.2383 13.4453 10.1836C14.2734 9.89062 15.7734 10.043 15.7734 10.043L17.3867 9.9375V10.0977L16.4609 10.6758C16.4609 10.6758 17.0156 15.1289 16.3125 15.1445C16.0117 15.1523 15.7148 15.1406 15.7148 15.1406C15.418 15.1172 15.3633 14.7539 15.3633 14.7539C14.9375 14.8398 15 14.543 14.8906 14.3672L13.1953 14.2227L13 16.9844L15.0898 17.7305L13.6055 21.0508L13.5469 21.1836L13.543 21.1797L13.3438 21.6016C13.1992 21.9063 12.8242 22.0469 12.5039 21.9219C12.1875 21.793 12.0469 21.4414 12.1875 21.1406L12.8398 19.7617L13.4453 18.418L11.8633 18.0859L8.6211 23.1094V23.1055L8.39063 23.4805C8.19141 23.8047 7.7461 23.9258 7.40235 23.7461C7.0586 23.5703 6.94141 23.1563 7.14453 22.832L7.56641 22.1406L9.53516 18.293H3.29688L4 22.4414L4.15235 23.1328C4.22656 23.4844 3.98828 23.8242 3.625 23.8945C3.25781 23.9648 2.89844 23.7344 2.82031 23.3867L2.69141 22.7812H2.6875L1.38672 17.9766L-1.625 22.6875H-1.6289L-2.11719 23.4805C-2.3125 23.8008 -2.75 23.9219 -3.08594 23.7461C-3.42187 23.5703 -3.53515 23.168 -3.33984 22.8477L-2.84375 22.0391L-0.343748 17.2891C-1.08984 15.9531 -0.269529 13.8711 -0.269529 13.8711L-2.46484 18.9141C-2.6289 19.1797 -2.99219 19.2813 -3.26953 19.1328C-3.54687 18.9883 -3.64453 18.6523 -3.48047 18.3867ZM13.2422 13.6953C13.5078 13.7187 14.6523 13.8164 14.6523 13.8164C14.6523 13.8164 14.4141 12.8672 14.3438 12.6133C14.2227 12.3828 12.9805 13.6602 13.2422 13.6953Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask2_448_459"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="7"
                y="4"
                width="3"
                height="3"
            >
                <path d="M7 4H10V7H7V4Z" fill="white" />
            </mask>
            <g mask="url(#mask2_448_459)">
                <mask
                    id="mask3_448_459"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask3_448_459)">
                    <path
                        d="M8.36327 6.7461C8.93749 6.89844 9.53124 6.5586 9.68359 5.98047C9.83984 5.40625 9.49609 4.8125 8.92187 4.66016C8.34374 4.50391 7.7539 4.84766 7.59765 5.42188C7.4453 6 7.78515 6.58985 8.36327 6.7461Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask4_448_459"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="4"
                y="6"
                width="8"
                height="13"
            >
                <path d="M4 6H12V19H4V6Z" fill="white" />
            </mask>
            <g mask="url(#mask4_448_459)">
                <mask
                    id="mask5_448_459"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask5_448_459)">
                    <path
                        d="M11.375 11.4062C11.4844 10.9922 11.2305 10.5625 10.8047 10.4492L8.8672 9.92968L8.15236 7.42187C8.10939 7.27343 8.02345 7.13671 7.90626 7.03515C7.72267 6.87499 7.44533 6.80468 7.20704 6.78515C6.98829 6.76953 6.76173 6.81249 6.56642 6.92187C6.45704 6.98046 6.35548 7.06249 6.27345 7.15624C6.19533 7.24609 6.12892 7.35156 6.08204 7.46484C6.08204 7.46484 4.47658 11.3516 4.47658 11.3555C4.34376 11.6758 4.38283 12.0273 4.54298 12.3281C4.6133 12.4648 4.6797 12.6133 4.76173 12.7461C4.78517 12.7891 4.80861 12.8281 4.83204 12.8672C4.91798 13.0039 5.00392 13.1406 5.08986 13.2773C5.19533 13.4492 5.30079 13.6172 5.40626 13.7891C5.50001 13.9375 5.59376 14.0859 5.68361 14.2344C5.73048 14.3047 5.77345 14.3789 5.82033 14.4492C5.82033 14.4531 5.82033 14.4531 5.82033 14.4531L5.87111 17.3711C5.87892 17.8086 6.23048 18.1641 6.65626 18.1562C7.08205 18.1484 7.4258 17.7812 7.41798 17.3398L7.3672 14.3164C7.3672 14.2305 7.34767 14.1523 7.32423 14.0742C7.30861 13.9648 7.27345 13.8516 7.21095 13.75L6.38283 12.4219C6.43361 12.3555 6.47658 12.2812 6.51173 12.1992L7.33595 10.207L7.49611 10.7617C7.58205 11.0625 7.82814 11.2734 8.10939 11.3281C8.12501 11.332 8.14064 11.3398 8.15626 11.3437L10.4024 11.9492C10.8281 12.0625 11.2656 11.8164 11.375 11.4062Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <path
                d="M16.8164 8.75391C18.7149 8.75391 20.2539 7.21484 20.2539 5.3164C20.2539 3.41796 18.7149 1.8789 16.8164 1.8789C14.9219 1.8789 13.3828 3.41796 13.3828 5.3164C13.3828 7.21484 14.9219 8.75391 16.8164 8.75391Z"
                stroke="#3F674D"
                stroke-width="0.587756"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17.1406 6.80469C16.9492 6.69922 16.1172 6.23828 16.1172 6.23828C16.1172 6.23828 16.7187 5.69922 16.8867 5.55859C17.0742 5.45313 17.3359 6.90625 17.1406 6.80469ZM19.3164 5.62891C19.0195 4.96875 19.293 5.625 18.8672 4.75391C18.8125 4.64453 18.3242 4.07422 18.2422 3.96094C17.8086 3.37891 17.0937 3.13281 17.0937 3.13281L16.0391 2.31641L15.9648 2.42578L16.3281 3.25C16.3281 3.25 13.8906 6.04297 14.3672 6.37891C14.5664 6.52344 14.7773 6.65234 14.7773 6.65234C14.9922 6.77734 15.1953 6.55078 15.1953 6.55078C15.4492 6.80859 15.543 6.57422 15.6992 6.50391L17.6289 7.57422"
                fill="#3F674D"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_459">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
