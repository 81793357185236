<script setup lang="ts">
export interface Props {
    color?: string
}

const props = withDefaults(defineProps<Props>(), {
    color: '#022D05',
})
</script>
<template>
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.5 27.498C3.675 27.498 2.96875 27.2043 2.38125 26.6168C1.79375 26.0293 1.5 25.323 1.5 24.498V4.99805H0V1.99805H7.5V0.498047H16.5V1.99805H24V4.99805H22.5V24.498C22.5 25.323 22.2063 26.0293 21.6188 26.6168C21.0313 27.2043 20.325 27.498 19.5 27.498H4.5ZM19.5 4.99805H4.5V24.498H19.5V4.99805ZM7.5 21.498H10.5V7.99805H7.5V21.498ZM13.5 21.498H16.5V7.99805H13.5V21.498Z"
            :fill="props.color"
        />
    </svg>
</template>

<style scoped></style>
