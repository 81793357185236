<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_491)">
            <path
                d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                fill="#ADBCAA"
            />
            <mask
                id="mask0_448_491"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="9"
                y="1"
                width="4"
                height="4"
            >
                <path d="M9 1H13V5H9V1Z" fill="white" />
            </mask>
            <g mask="url(#mask0_448_491)">
                <mask
                    id="mask1_448_491"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_448_491)">
                    <path
                        d="M10.4805 4.26171C11.1133 4.48046 11.8008 4.14452 12.0195 3.51171C12.2383 2.8789 11.9023 2.18749 11.2695 1.96874C10.6367 1.74999 9.9453 2.08593 9.72655 2.72265C9.5078 3.35546 9.84374 4.04296 10.4805 4.26171Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask2_448_491"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="6"
                y="4"
                width="9"
                height="14"
            >
                <path d="M6 4H15V18H6V4Z" fill="white" />
            </mask>
            <g mask="url(#mask2_448_491)">
                <mask
                    id="mask3_448_491"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask3_448_491)">
                    <path
                        d="M14.2617 9.92577C14.418 9.47265 14.0898 9.12499 13.625 8.96484L11.4922 8.23046L10.9609 5.21093C10.9258 5.03905 10.8398 4.8789 10.7148 4.7539C10.5234 4.56249 10.2148 4.46484 9.95311 4.42187C9.70702 4.38671 9.4492 4.41796 9.22264 4.52343C9.09374 4.58593 8.97655 4.66796 8.87499 4.76562C8.78124 4.86327 8.6992 4.97265 8.64061 5.09374L6.53124 9.32812C6.35545 9.67577 6.37499 10.0742 6.53124 10.4258C6.60155 10.5859 6.66014 10.7578 6.74217 10.9141C6.76561 10.9609 6.78905 11.0078 6.81249 11.0508C6.89842 11.2109 6.98436 11.375 7.0703 11.5351C7.17577 11.7344 7.28124 11.9297 7.3867 12.1289C7.47655 12.3047 7.74999 12.8203 7.79686 12.9062C7.79686 12.9062 7.79686 12.9062 7.79686 12.9101L8.16795 13.5703L7.55858 16.6875C7.53124 17.1836 7.90233 17.6055 8.37889 17.6328C8.85545 17.6562 9.26952 17.2734 9.29686 16.7773L9.91405 13.5351C9.91795 13.4414 9.90233 13.3516 9.8828 13.2617C9.87499 13.1367 9.84374 13.0078 9.78124 12.8906L8.58592 10.6758C8.64842 10.6055 8.70311 10.5234 8.74999 10.4336L9.82811 8.26562L9.96092 8.90234C10.0351 9.24609 10.293 9.49999 10.6055 9.58593C10.625 9.58984 10.6406 9.60155 10.6601 9.60937L13.1289 10.4609C13.5976 10.6211 14.1055 10.3789 14.2617 9.92577Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask4_448_491"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="12"
                y="9"
                width="5"
                height="9"
            >
                <path d="M12 9H17V18H12V9Z" fill="white" />
            </mask>
            <g mask="url(#mask4_448_491)">
                <mask
                    id="mask5_448_491"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask5_448_491)">
                    <path
                        d="M15.4844 17.3164L13.3438 9.76171H15.8828C15.8828 9.76171 17.5547 10.7656 15.2148 11.7695"
                        stroke="#3F674D"
                        stroke-width="0.741225"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>
            <mask
                id="mask6_448_491"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="2"
                y="11"
                width="7"
                height="7"
            >
                <path d="M2 11H9V18H2V11Z" fill="white" />
            </mask>
            <g mask="url(#mask6_448_491)">
                <mask
                    id="mask7_448_491"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask7_448_491)">
                    <path
                        d="M8.26563 16.3789L6.59375 11.6992L2.98438 17.1797L7.79688 17.25"
                        stroke="#3F674D"
                        stroke-width="0.741225"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>
            <mask
                id="mask8_448_491"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="7"
                y="15"
                width="3"
                height="4"
            >
                <path d="M7 15H10V19H7V15Z" fill="white" />
            </mask>
            <g mask="url(#mask8_448_491)">
                <mask
                    id="mask9_448_491"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask9_448_491)">
                    <path
                        d="M8.63281 18.5508C9.37109 18.5508 9.96875 17.9531 9.96875 17.2148C9.96875 16.4766 9.37109 15.8789 8.63281 15.8789C7.89453 15.8789 7.29688 16.4766 7.29688 17.2148C7.29688 17.9531 7.89453 18.5508 8.63281 18.5508Z"
                        fill="#3F674D"
                    />
                </g>
            </g>
            <mask
                id="mask10_448_491"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="6"
                y="11"
                width="9"
                height="6"
            >
                <path d="M6 11H15V17H6V11Z" fill="white" />
            </mask>
            <g mask="url(#mask10_448_491)">
                <mask
                    id="mask11_448_491"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask11_448_491)">
                    <path
                        d="M6.59375 11.6992H13.8789L9.40235 16.5117"
                        stroke="#3F674D"
                        stroke-width="0.741225"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>
            <mask
                id="mask12_448_491"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="4"
                y="10"
                width="5"
                height="3"
            >
                <path d="M4 10H9V13H4V10Z" fill="white" />
            </mask>
            <g mask="url(#mask12_448_491)">
                <mask
                    id="mask13_448_491"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask13_448_491)">
                    <path
                        d="M6.66016 11.9688V10.5H4.78906H7.94532"
                        stroke="#3F674D"
                        stroke-width="0.741225"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>
            <mask
                id="mask14_448_491"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="11"
                y="12"
                width="9"
                height="9"
            >
                <path d="M11 12H20V21H11V12Z" fill="white" />
            </mask>
            <g mask="url(#mask14_448_491)">
                <mask
                    id="mask15_448_491"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask15_448_491)">
                    <path
                        d="M15.3828 20.3711C17.3594 20.3711 18.957 18.7695 18.957 16.7969C18.957 14.8203 17.3594 13.2226 15.3828 13.2226C13.4063 13.2226 11.8086 14.8203 11.8086 16.7969C11.8086 18.7695 13.4063 20.3711 15.3828 20.3711Z"
                        stroke="#3F674D"
                        stroke-width="0.741225"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>
            <mask
                id="mask16_448_491"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="12"
                width="7"
                height="9"
            >
                <path d="M0 12H7V21H0V12Z" fill="white" />
            </mask>
            <g mask="url(#mask16_448_491)">
                <mask
                    id="mask17_448_491"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask17_448_491)">
                    <path
                        d="M2.6836 20.3711C4.66016 20.3711 6.26173 18.7695 6.26173 16.7969C6.26173 14.8203 4.66016 13.2226 2.6836 13.2226C0.71094 13.2226 -0.890625 14.8203 -0.890625 16.7969C-0.890625 18.7695 0.71094 20.3711 2.6836 20.3711Z"
                        stroke="#3F674D"
                        stroke-width="0.741225"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>
            <mask
                id="mask18_448_491"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="18"
                y="5"
                width="6"
                height="7"
            >
                <path d="M18 5H24V12H18V5Z" fill="white" />
            </mask>
            <g mask="url(#mask18_448_491)">
                <mask
                    id="mask19_448_491"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="25"
                    height="25"
                >
                    <path
                        d="M0.0703125 12.0781C0.0703125 18.6992 5.4375 24.0703 12.0625 24.0703C18.6836 24.0703 24.0508 18.6992 24.0508 12.0781C24.0508 5.45703 18.6836 0.0898438 12.0625 0.0898438C5.4375 0.0898438 0.0703125 5.45703 0.0703125 12.0781Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask19_448_491)">
                    <path
                        d="M25.5508 5.73046H18.5938V10.9258H25.5508V5.73046Z"
                        stroke="#3F674D"
                        stroke-width="0.741225"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_448_491">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
