props.
<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16" cy="16" r="12" stroke="#798877" stroke-width="2" />
        <path
            d="M9.86458 20V12.828H11.8886C12.6219 12.828 13.2562 12.9527 13.7916 13.202C14.3269 13.4513 14.7412 13.84 15.0346 14.368C15.3352 14.8887 15.4856 15.5597 15.4856 16.381C15.4856 17.2023 15.3389 17.8807 15.0456 18.416C14.7522 18.9513 14.3416 19.351 13.8136 19.615C13.2929 19.8717 12.6806 20 11.9766 20H9.86458ZM11.4816 18.691H11.7896C12.2002 18.691 12.5559 18.6177 12.8566 18.471C13.1646 18.3243 13.4029 18.0823 13.5716 17.745C13.7402 17.4077 13.8246 16.953 13.8246 16.381C13.8246 15.809 13.7402 15.3617 13.5716 15.039C13.4029 14.709 13.1646 14.478 12.8566 14.346C12.5559 14.2067 12.2002 14.137 11.7896 14.137H11.4816V18.691ZM16.847 20V12.828H18.871C19.6043 12.828 20.2387 12.9527 20.774 13.202C21.3093 13.4513 21.7237 13.84 22.017 14.368C22.3177 14.8887 22.468 15.5597 22.468 16.381C22.468 17.2023 22.3213 17.8807 22.028 18.416C21.7347 18.9513 21.324 19.351 20.796 19.615C20.2753 19.8717 19.663 20 18.959 20H16.847ZM18.464 18.691H18.772C19.1827 18.691 19.5383 18.6177 19.839 18.471C20.147 18.3243 20.3853 18.0823 20.554 17.745C20.7227 17.4077 20.807 16.953 20.807 16.381C20.807 15.809 20.7227 15.3617 20.554 15.039C20.3853 14.709 20.147 14.478 19.839 14.346C19.5383 14.2067 19.1827 14.137 18.772 14.137H18.464V18.691Z"
            fill="#798877"
        />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
