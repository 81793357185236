<template>
    <svg
        class="time-icon"
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.9841 3.04297C8.62406 3.04297 2.66406 9.0163 2.66406 16.3763C2.66406 23.7363 8.62406 29.7096 15.9841 29.7096C23.3574 29.7096 29.3307 23.7363 29.3307 16.3763C29.3307 9.0163 23.3574 3.04297 15.9841 3.04297ZM15.9974 27.043C10.1041 27.043 5.33073 22.2696 5.33073 16.3763C5.33073 10.483 10.1041 5.70964 15.9974 5.70964C21.8907 5.70964 26.6641 10.483 26.6641 16.3763C26.6641 22.2696 21.8907 27.043 15.9974 27.043Z"
            fill="#798877"
        />
        <rect x="14.3359" y="9.70898" width="2.66667" height="8" fill="#798877" />
        <rect
            x="14.3359"
            y="17.709"
            width="2.66667"
            height="8"
            transform="rotate(-60 14.3359 17.709)"
            fill="#798877"
        />
    </svg>
</template>
