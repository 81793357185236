// import { format } from 'date-fns'
import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/users/UserStore'
import { storeToRefs } from 'pinia'
import axios from 'axios'
import { useToast } from 'vue-toastification'
import type { PhotoStoreInterface } from '../../types/storesInterfaces/photoStoreInterace'
import type { PhotoInterface } from '../../types/photos/photoInterface'
import { i18n } from '../../i18n'
import { changePhotoDescAncher } from '../../hooks/photoDescription'
import { basePhotoServerUrl } from '@/data/constants/urls'

const toast = useToast()

export const usePhotoServerStore = defineStore({
    id: 'photoserverStore',
    state: (): PhotoStoreInterface => ({
        photo: undefined,
        photoUrl: undefined,
        photos: undefined,
        userPhotos: undefined,
        photosPoi: undefined,
        photosSpecies: undefined,
        photosArea: undefined,
        photosNews: undefined,
        loading: false,
        tripPhotos: undefined,
        photoRandomFacts: undefined,
        isWebpSupported: false,
        photosAreaRulesGroup: undefined,
        loadingAreaPhotos: false,
        loadingTripPhotos: false,
    }),
    actions: {
        async getPhoto(id: number) {
            const format = this.isWebpSupported ? 'webp' : 'jpg'
            try {
                const response = await axios.get(`${basePhotoServerUrl}/photos/${id}`, {
                    params: {
                        format,
                    },
                })
                this.photo = response.data
                this.photoUrl = response.data.url.big
            } catch (error) {
                toast.error(error.message)
            }
        },
        async getPhotos(id: string) {
            console.log('id', id)
            const format = this.isWebpSupported ? 'webp' : 'jpg'
            try {
                const response = await axios.get(`${basePhotoServerUrl}/photos?group_ids[]=area-3`, {
                    params: {
                        format,
                    },
                })
                this.photo = response.data
            } catch (error) {
                toast.error(error.message)
            }
        },
        async getPhotosbyGroupId(id: string) {
            this.loading = true
            const format = this.isWebpSupported ? 'webp' : 'jpg'
            // id must be in string wchich has prepended type as articles- or area- ..... etc
            try {
                const response = await axios.get(`${basePhotoServerUrl}/photos?group_ids[]=${id}`, {
                    params: {
                        format,
                    },
                })
                this.photos = response.data
                // loop this.photos.description, get keys find ankers and assign target _blank
                if (this.photos) {
                    changePhotoDescAncher(this.photos)
                }
            } catch (error) {
                // toast.error(error)
                toast.error(error.message)
            }
            this.loading = false
        },
        async getPhotosRandomFactsbyGroupId(id: string, limit = 1) {
            this.loading = true
            const format = this.isWebpSupported ? 'webp' : 'jpg'
            try {
                const response = await axios.get(`${basePhotoServerUrl}/photos?group_ids[]=${id}`, {
                    params: {
                        limit: limit,
                        format,
                    },
                })
                this.photoRandomFacts = response.data
                // loop this.photoRandomFacts.description, get keys find ankers and assign target _blank
                if (this.photoRandomFacts) {
                    changePhotoDescAncher(this.photoRandomFacts)
                }
            } catch (error) {
                toast.error(error.message)
            }
            this.loading = false
        },
        async getPhotosPoibyGroupId(id: string) {
            this.loading = true
            const format = this.isWebpSupported ? 'webp' : 'jpg'
            // id must be in string wchich has prepended type as articles- or area- ..... etc
            try {
                const response = await axios.get(`${basePhotoServerUrl}/photos?group_ids[]=${id}`, {
                    params: {
                        format,
                    },
                })
                this.photosPoi = response.data
                // loop this.photosPoi.description, get keys find ankers and assign target _blank
                if (this.photosPoi) {
                    changePhotoDescAncher(this.photosPoi)
                }
            } catch (error) {
                toast.error(error.message)
            }
            this.loading = false
        },
        async getPhotosSpeciesbyGroupId(id: string) {
            this.loading = true
            const format = this.isWebpSupported ? 'webp' : 'jpg'
            // id must be in string wchich has prepended type as articles- or area- ..... etc
            try {
                const response = await axios.get(`${basePhotoServerUrl}/photos?group_ids[]=${id}`, {
                    params: {
                        format,
                    },
                })
                this.photosSpecies = response.data
                // loop this.photosSpecies.description, get keys find ankers and assign target _blank
                if (this.photosSpecies) {
                    changePhotoDescAncher(this.photosSpecies)
                }
            } catch (error) {
                // toast.error(error)
                toast.error(error.message)
            }
            this.loading = false
        },
        async getPhotosAreabyGroupId(id: string) {
            this.loadingAreaPhotos = true
            const format = this.isWebpSupported ? 'webp' : 'jpg'
            // id must be in string wchich has prepended type as articles- or area- ..... etc
            try {
                const response = await axios.get(`${basePhotoServerUrl}/photos?group_ids[]=${id}`, {
                    params: {
                        format,
                    },
                })
                this.photosArea = response.data
                // loop this.photosArea.description, get keys find ankers and assign target _blank
                if (this.photosArea) {
                    changePhotoDescAncher(this.photosArea)
                }
            } catch (error) {
                // toast.error(error)
                toast.error(error.message)
            }
            this.loadingAreaPhotos = false
        },
        async detelePhoto(id: number) {
            try {
                await axios.delete(`${basePhotoServerUrl}/photos/${id}`)
                toast.success(`image has been deleted successfully'`)
            } catch (error) {
                toast.error(error.message)
            }
        },
        async uploadPhoto(groupVariant: string, groupId: string, selectedImage: Blob, status: number) {
            const { userData } = storeToRefs(useUserStore())
            const form = new FormData()
            if (!userData.value) {
                toast.error('An error occured Please update your profile with a display name and avatar')
                return
            }
            form.append('photo[user_id]', userData.value.uid)
            form.append('photo[user_name]', userData.value.displayName)
            form.append('photo[user_avatar_url]', userData.value.photoURL)
            form.append('photo[variant_group]', groupVariant)
            form.append('photo[status]', status)
            form.append('photo[group_id]', `${groupVariant}-${groupId}`)
            form.append('photo[image]', selectedImage)
            try {
                await axios.post(`${basePhotoServerUrl}/photos/`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                toast.success(i18n.global.t('imageUploaded'))
                // console.log('response', response.data)
            } catch (error) {
                toast.error(error.message)
            }
        },
        async uploadTripPhotoFromUser(
            selectedImg: Blob,
            groupVariant: string,
            groupId: string,
            sourceType = 'user',
            entityName: string | undefined = undefined
        ) {
            // console.log('triggered', selectedImg, groupVariant, groupId)
            const { userData } = storeToRefs(useUserStore())
            if (!userData.value) {
                toast.error('An error occured Please update your profile with a display name')
                return
            }
            // check if there is entity
            const entity_name = entityName ? entityName : undefined

            const form = new FormData()
            form.append('photo[user_id]', userData.value.uid)
            form.append('photo[user_name]', userData.value.displayName)
            form.append('photo[author]', userData.value.displayName)
            form.append('photo[variant_group]', groupVariant)
            form.append('photo[group_id]', `${groupVariant}-${groupId}`)
            form.append('photo[status]', 2)
            form.append('photo[image]', selectedImg)
            form.append('photo[source_type]', sourceType)
            if (entity_name) {
                form.append('photo[entity_name]', entity_name)
            }

            console.log('form', form)
            try {
                await axios.post(`${basePhotoServerUrl}/photos/`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                // i18n.global.t('tagsTypes.notice')
                toast.success(i18n.global.t('generic.photo.upload', { name: `${groupVariant}-${groupId}` }))
                // console.log('response', response.data)
            } catch (error) {
                toast.error(error.message)
            }
        },
        async GetUserPhotosbyGroupId(id: string) {
            this.loading = true
            const format = this.isWebpSupported ? 'webp' : 'jpg'
            // id must be in string wchich has prepended type as articles- or area- ..... etc
            try {
                const response = await axios.get(`${basePhotoServerUrl}/photos?group_ids[]=${id}`, {
                    params: {
                        format,
                    },
                })
                this.userPhotos = response.data
            } catch (error) {
                // toast.error(error)
                toast.error(error.message)
            }
            this.loading = false
        },
        async updateUserProfilePhoto() {
            const { userData } = storeToRefs(useUserStore())
            await this.GetUserPhotosbyGroupId(`profile-${userData.value.uid}`)
            // if (this.photos) {
            //     const updatedPhoto = this.photos.at(-1)
            //     console.log('updatedPhoto', updatedPhoto)
            // }
            // loop throw this,photos and find last item in array
            // userData.value.updateProfile({
            //     photoURL: 'https://example.com/jane-q-user/profile.jpg',
            // })
        },
        async updatePhotoStatus(id: number) {
            console.log('id photo update', id)
            try {
                await axios.put(`${basePhotoServerUrl}/photos/${id}`, {
                    status: 4,
                })
                toast.success('image has been updated successfully')
            } catch (error) {
                toast.error(error.message)
            }
        },
        async getTripPhotos(tripId: string) {
            this.loadingTripPhotos = true
            const format = this.isWebpSupported ? 'webp' : 'jpg'

            try {
                const response = await axios.get(`/trips/${tripId}/photos`, {
                    params: {
                        format,
                    },
                })
                // filter response.data to get only photos with variant_group === trip  || poi
                this.tripPhotos = response.data.filter((photo: PhotoInterface) => {
                    return photo.variant_group === 'trip' || photo.variant_group === 'poi'
                })
                if (this.tripPhotos) {
                    changePhotoDescAncher(this.tripPhotos)
                }
            } catch (error) {
                toast.error(error.message)
            }
            this.loadingTripPhotos = false
        },
        async areaRulesGroupPhoto(id: string) {
            this.loading = true
            const format = this.isWebpSupported ? 'webp' : 'jpg'
            // id must be in string wchich has prepended type as articles- or area- ..... etc
            try {
                const response = await axios.get(`${basePhotoServerUrl}/photos?group_ids[]=${id}`, {
                    params: {
                        format,
                    },
                })
                this.photosAreaRulesGroup = response.data
                // loop this.photosAreaRulesGroup.description, get keys find ankers and assign target _blank
                if (this.photosAreaRulesGroup) {
                    changePhotoDescAncher(this.photosAreaRulesGroup)
                }
            } catch (error) {
                toast.error(error.message)
            } finally {
                this.loading = false
            }
        },
    },
})
