<script setup lang="ts">
export interface Props {
    color?: string
}

const props = withDefaults(defineProps<Props>(), {
    color: '#022D05',
})
</script>

<template>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.5 24.498H5.6375L20.3 9.83555L18.1625 7.69805L3.5 22.3605V24.498ZM0.5 27.498V21.123L20.3 1.36055C20.6 1.08555 20.9313 0.873047 21.2938 0.723047C21.6563 0.573047 22.0375 0.498047 22.4375 0.498047C22.8375 0.498047 23.225 0.573047 23.6 0.723047C23.975 0.873047 24.3 1.09805 24.575 1.39805L26.6375 3.49805C26.9375 3.77305 27.1563 4.09805 27.2938 4.47305C27.4313 4.84805 27.5 5.22305 27.5 5.59805C27.5 5.99805 27.4313 6.3793 27.2938 6.7418C27.1563 7.1043 26.9375 7.43555 26.6375 7.73555L6.875 27.498H0.5ZM19.2125 8.78555L18.1625 7.69805L20.3 9.83555L19.2125 8.78555Z"
            :fill="props.color"
        />
    </svg>
</template>

<style scoped></style>
