<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_784)">
            <path
                d="M18.8164 23.0586H5.19531C2.85547 23.0586 0.960938 21.1641 0.960938 18.8242V5.20313C0.960938 2.86719 2.85547 0.972656 5.19531 0.972656H18.8164C21.1524 0.972656 23.0508 2.86719 23.0508 5.20313V18.8242C23.0508 21.1641 21.1524 23.0586 18.8164 23.0586Z"
                stroke="#E41F25"
                stroke-width="1.88883"
                stroke-miterlimit="22.926"
            />
            <path
                d="M2.04688 21.9727L21.9649 2.05469"
                stroke="#E41F25"
                stroke-width="1.84064"
                stroke-miterlimit="22.926"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8867 16.5977C10.9766 16.3242 10.0664 16.0547 9.16015 15.7852C8.95702 15.4805 8.66015 15.0273 8.55859 14.875C7.71484 13.5938 7.08593 11.6445 7.80859 10.1914C8.1953 9.40625 9.35937 8.50781 10.1601 7.41797C10.3047 7.22266 10.4297 7.01953 10.5508 6.81641C10.8516 6.30469 11.2226 5.3125 11.1914 4.53516C11.1641 3.84766 11 3.93359 10.8008 3.54688C13.7187 4.60156 14.9648 7.19531 14.2109 10.0977C14.1367 10.3906 13.7226 11.2031 13.2734 11.9336C11.8711 14.207 11.543 13.9297 11.8867 16.5977Z"
                fill="#2B2B2A"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5898 14.7812C12.9844 13.2734 14.0898 13.0703 14.7813 11.0742C14.9609 10.5625 14.9922 9.74609 15.0313 9.18359C17.4648 11.2656 16.4258 13.5156 15.1094 15.7852C14.2734 16.0625 13.4336 16.3398 12.5977 16.6133C12.5039 16.043 12.4453 15.3438 12.5898 14.7812Z"
                fill="#2B2B2A"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.793 18.2148L7.17578 15.8594V17.9219L16.793 20.2773V18.2148Z"
                fill="#2B2B2A"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.1172 19.3555L8.34766 18.4336L7.19141 18.7148V20.5625L12.1172 19.3555Z"
                fill="#2B2B2A"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.7617 16.9648L16.5977 17.9023L16.793 17.8555V15.9766L12.7617 16.9648Z"
                fill="#2B2B2A"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_784">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
