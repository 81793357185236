import firebase from 'firebase/app'

export function getUserCollection(userId: string) {
    const db = firebase.firestore()
    const userCollection = db.collection('users').doc(userId)

    userCollection
        .get()
        .then((snapshot) => {
            if (snapshot.exists) {
                const data = snapshot.data()
                if (data?.isCommunityVisited) {
                    localStorage.setItem('isCommunityVisited', data?.isCommunityVisited)
                } else {
                    localStorage.setItem('isCommunityVisited', 'false')
                }
            } else {
                console.log('No such document!')
            }
        })
        .catch((error) => {
            console.error('Error getting document:', error)
        })
}
