<script setup lang="ts">
interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 32,
    width: 32,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16" cy="16" r="12" stroke="#798877" stroke-width="2" />
        <path
            d="M16.0087 13V19.6015H14.2792L11.7344 15.4937V19.6015H10V13H11.7344L14.2792 17.1078V13H16.0087Z"
            fill="#798877"
        />
        <path
            d="M22.2452 18.3774V19.6015H18.4107V18.3774H22.2452ZM19.0283 13V19.6015H17.2939V13H19.0283ZM21.751 15.6207V16.804H18.4107V15.6207H21.751ZM22.26 13V14.2287H18.4107V13H22.26Z"
            fill="#798877"
        />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
