// import _ from 'lodash'
// import { upperFirst } from 'lodash'
// import { camelCase } from 'lodash'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

export default {
    install(app) {
        const componentFiles = import.meta.globEager('../components/global/icons/*.vue')
        Object.entries(componentFiles).forEach(([path, m]) => {
            const componentName = upperFirst(
                camelCase(
                    path
                        .split('/')
                        .pop()
                        .replace(/\.\w+$/, '')
                )
            )

            app.component(`${componentName}`, m.default)
        })
    },
}
