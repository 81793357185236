import axios, { AxiosResponse } from 'axios'

const etags = new Map()
const cache = new Map()

// Response interceptor to get Etag and cache responses
axios.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response?.config?.url?.includes('photoserver')) {
            // If the URL includes 'photoserver', return the response without storing it in the cache
            return response
        }
        if (response.status === 200 && response.headers['etag'] && response.config.url) {
            etags.set(response.config.url, response.headers['etag'])
            // Remove the previous cached response
            cache.delete(response.config.url)
            cache.set(response.config.url, response)
        }
        return response
    },
    (error) => {
        if (error.response && error.response.status === 304 && error.config.url) {
            const cachedResponse = cache.get(error.config.url)
            if (cachedResponse) {
                cachedResponse.cached = true
                return Promise.resolve(cachedResponse)
            }
        }
        return Promise.reject(error)
    }
)

// Request interceptor to use Etag
axios.interceptors.request.use(
    (config) => {
        if (config.url) {
            const etag = etags.get(config.url)
            if (etag) {
                config.headers['If-None-Match'] = etag
            }
        }
        config.headers['Cache-Control'] = 'no-cache, must-revalidate'
        return config
    },
    (error) => Promise.reject(error)
)

export default axios
