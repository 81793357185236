<template>
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.7425 1.5C5.6025 1.5 2.25 4.86 2.25 9C2.25 13.14 5.6025 16.5 9.7425 16.5C13.89 16.5 17.25 13.14 17.25 9C17.25 4.86 13.89 1.5 9.7425 1.5ZM9.75 15C6.435 15 3.75 12.315 3.75 9C3.75 5.685 6.435 3 9.75 3C13.065 3 15.75 5.685 15.75 9C15.75 12.315 13.065 15 9.75 15Z"
            fill="#A1C413"
        />
        <rect x="8.8125" y="5.25" width="1.5" height="4.5" fill="#A1C413" />
        <rect
            x="8.8125"
            y="9.75"
            width="1.5"
            height="4.5"
            transform="rotate(-60 8.8125 9.75)"
            fill="#A1C413"
        />
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
