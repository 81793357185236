<script setup lang="ts">
export interface Props {
    stroke: string
}

const props = withDefaults(defineProps<Props>(), {
    stroke: 'black',
})
</script>
<template>
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5.25535L4.33333 8.29883L12 1.29883" :stroke="props.stroke" stroke-width="1.5" />
    </svg>
</template>

<style scoped lang="scss">
path {
    transition: all 0.2s ease-in-out;
}
</style>
