<script setup lang="ts">
export interface Props {
    strokeColor?: string
}

const props = withDefaults(defineProps<Props>(), {
    strokeColor: '#022D05',
})
</script>
<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_10713_123572)">
            <path
                d="M18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z"
                :fill="props.strokeColor"
            />
            <circle cx="12" cy="10" r="2.6" :stroke="strokeColor" stroke-width="1.8" />
        </g>
        <defs>
            <clipPath id="clip0_10713_123572">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<style scoped></style>
