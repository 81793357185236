<script setup lang="ts">
export interface Props {
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    height: 24,
    width: 24,
})
</script>

<template>
    <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_2566_14746)">
            <path
                d="M12 18H6L2 22V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V11H20V4H4V16H12V18ZM23 14.34L21.59 12.93L17.35 17.17L15.23 15.05L13.82 16.46L17.34 20L23 14.34Z"
                fill="#A1C413"
            />
        </g>
        <defs>
            <clipPath id="clip0_2566_14746">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
