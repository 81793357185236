<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_448_400)">
            <path
                d="M0.0546875 12.0352C0.0546875 18.6563 5.42187 24.0234 12.043 24.0234C18.6641 24.0234 24.0352 18.6563 24.0352 12.0352C24.0352 5.41406 18.6641 0.0429688 12.043 0.0429688C5.42187 0.0429688 0.0546875 5.41406 0.0546875 12.0352Z"
                fill="#ADBCAA"
            />
            <path
                d="M6.34766 7.25781C6.34766 7.25781 4.0625 7.25781 4.0625 9.54297V13.1211C4.0625 13.1211 4.0625 15.8984 6.83984 15.8984H18C18 15.8984 19.6328 15.8984 19.5859 14.2656L19.5117 11.7266L18.8477 9.49219C18.8477 9.49219 18.1836 7.25781 15.4062 7.25781H6.34766Z"
                fill="#3F674D"
            />
            <path d="M13.8125 15.1797H16.1055V9.59766H13.8125V15.1797Z" fill="#ADBCAA" />
            <path
                d="M11.3008 17.5391C12.1367 17.5391 12.8164 16.8633 12.8164 16.0234C12.8164 15.1875 12.1367 14.5078 11.3008 14.5078C10.4609 14.5078 9.78125 15.1875 9.78125 16.0234C9.78125 16.8633 10.4609 17.5391 11.3008 17.5391Z"
                fill="#3F674D"
            />
            <path d="M6.86328 11.1719H11.1602V9.33984H6.86328V11.1719Z" fill="#ADBCAA" />
            <path
                d="M3.94922 14.5469C3.94922 14.8242 3.72656 15.0547 3.45703 15.0547C3.19141 15.0547 2.96875 14.8281 2.97266 14.5508L2.96875 11.8555C2.96875 11.5781 3.19141 11.3516 3.45703 11.3516C3.72656 11.3476 3.94922 11.5781 3.94531 11.8555L3.94922 14.5469Z"
                fill="#3F674D"
            />
            <path
                d="M18.6211 15.9141C18.3437 15.9141 18.1133 15.6953 18.1133 15.4258C18.1133 15.1563 18.3398 14.9375 18.6172 14.9375L21.3125 14.9336C21.5898 14.9336 21.8164 15.1563 21.8164 15.4258C21.8203 15.6953 21.5938 15.9141 21.3125 15.9141H18.6211Z"
                fill="#3F674D"
            />
        </g>
        <defs>
            <clipPath id="clip0_448_400">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
