<script setup lang="ts">
export interface Props {
    color?: string
    height?: number
    width?: number
}
const props = withDefaults(defineProps<Props>(), {
    color: '#D3D3D3',
    height: 24,
    width: 24,
})
</script>
<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="9" :stroke="props.color" stroke-width="2" />
        <path
            d="M11.2734 13.9453V15H8.39844V13.9453H11.2734ZM8.88672 9.3125V15H7.51562V9.3125H8.88672Z"
            :fill="props.color"
        />
        <path
            d="M15.3359 13.0742H16.7031C16.6849 13.4701 16.5781 13.819 16.3828 14.1211C16.1875 14.4232 15.9167 14.6589 15.5703 14.8281C15.2266 14.9948 14.8203 15.0781 14.3516 15.0781C13.9714 15.0781 13.6315 15.0143 13.332 14.8867C13.0352 14.7591 12.7826 14.5755 12.5742 14.3359C12.3685 14.0964 12.2109 13.806 12.1016 13.4648C11.9948 13.1237 11.9414 12.7396 11.9414 12.3125V12.0039C11.9414 11.5768 11.9974 11.1927 12.1094 10.8516C12.224 10.5078 12.3854 10.2161 12.5938 9.97656C12.8047 9.73438 13.0573 9.54948 13.3516 9.42188C13.6458 9.29427 13.9727 9.23047 14.332 9.23047C14.8268 9.23047 15.2448 9.31901 15.5859 9.49609C15.9271 9.67318 16.1914 9.91667 16.3789 10.2266C16.5664 10.5339 16.6771 10.8841 16.7109 11.2773H15.3438C15.3385 11.0638 15.3021 10.8854 15.2344 10.7422C15.1667 10.5964 15.0599 10.487 14.9141 10.4141C14.7682 10.3411 14.5742 10.3047 14.332 10.3047C14.1628 10.3047 14.0156 10.3359 13.8906 10.3984C13.7656 10.4609 13.6615 10.5599 13.5781 10.6953C13.4948 10.8307 13.4323 11.0065 13.3906 11.2227C13.3516 11.4362 13.332 11.694 13.332 11.9961V12.3125C13.332 12.6146 13.3503 12.8724 13.3867 13.0859C13.4232 13.2995 13.4818 13.474 13.5625 13.6094C13.6432 13.7448 13.7474 13.8451 13.875 13.9102C14.0052 13.9727 14.1641 14.0039 14.3516 14.0039C14.5599 14.0039 14.7357 13.9714 14.8789 13.9062C15.0221 13.8385 15.1328 13.737 15.2109 13.6016C15.2891 13.4635 15.3307 13.2878 15.3359 13.0742Z"
            :fill="props.color"
        />
    </svg>
</template>

<style scoped></style>
