<template>
    <div class="app-banner-wrapper" id="app-banner-wrapper" :class="{ visible: visible }">
        <div class="app-banner">
            <img
                class="app-banner-butterflies no-image-select"
                src="@/assets/app-banner/background.svg"
            />

            <img class="app-banner-icon no-image-select" src="@/assets/app-banner/app-icon.svg" />
            <div class="app-banner-text">
                <h2>Aplikace EPU</h2>
                <p>
                    Pro plnohodnotný zážitek doporučujeme stáhnout mobilní verzi aplikace EPU do
                    Vašeho telefonu
                </p>
            </div>

            <div class="app-banner-buttons">
                <button class="btn button-primary" @click="continueWithApp()">
                    Stáhnout aplikaci
                </button>
                <button class="btn button-secondary" @click="continueWithWeb()">
                    POUŽÍT WEBOVOU VERZI
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { appstoreDirectLink, googlePlayDirectLink } from '@/data/constants/links'
import { getAppBannerInteracted, setAppBannerInteracted } from '@/helpers/appbanner.helper'
import { sendGoogleTagManagerEvent } from '@/helpers/gtm.helper'
import Detector from '@/helpers/platform-detector'
import { tryDisplaySmartbanner } from '@/helpers/smartbanner.helper'
import { onMounted, ref, watch } from 'vue'

const visible = ref(false)

// ! -- disable body scroll -- !
watch(
    () => visible.value,
    (value) => {
        if (value) {
            document.body.classList.add('no-scroll')
        } else {
            document.body.classList.remove('no-scroll')
        }
    }
)

onMounted(() => {
    const appBannerInteracted = getAppBannerInteracted()

    // if app banner was not interacted + platform is iOS | Android -> show app banner
    if (appBannerInteracted == false) {
        const platform = Detector.platform()
        if (platform == 'ios' || platform == 'android') {
            visible.value = true
        }
    }

    // if app banner was interacted -> try display smart banner
    if (appBannerInteracted == true) {
        setTimeout(() => {
            tryDisplaySmartbanner()
        }, 1000)
    }
})

function continueWithApp() {
    const platform = Detector.platform()

    try {
        sendGoogleTagManagerEvent('app_banner_interacted', `app (${platform})`)
    } catch {
        console.warn('Could not send app banner interaction event to GTM')
    }

    switch (platform) {
        case 'ios':
            window.open(appstoreDirectLink, '_blank')
            break
        case 'android':
            window.open(googlePlayDirectLink, '_blank')
            break
    }

    visible.value = false
    setAppBannerInteracted(true)
}

function continueWithWeb() {
    try {
        sendGoogleTagManagerEvent('app_banner_interacted', 'web')
    } catch {
        console.warn('Could not send app banner interaction event to GTM')
    }

    visible.value = false
    setAppBannerInteracted(true)
}
</script>

<style lang="scss" scoped>
.app-banner {
    width: 100%;
    min-height: 400px;

    background-color: $epu-base-background;
    position: relative;

    display: flex;
    flex-flow: column;

    align-items: center;
    gap: 46px;

    padding: 24px 18px;
    box-shadow: 0px 4px 12px -6px rgba(0, 0, 0, 0.5);

    &-butterflies {
        width: 200px;
        height: 200px;
        object-fit: cover;

        position: absolute;
        right: 0;
        top: 0;

        pointer-events: none;
    }

    &-text {
        display: flex;
        flex-flow: column;
        text-align: center;

        gap: 12px;

        & > h2 {
            font-size: 30px;
        }

        & > p {
            font-size: 18px;
            line-height: 24px;

            font-family: 'Source Sans Pro', sans-serif;
        }
    }

    &-buttons {
        display: flex;
        flex-flow: column;

        gap: 8px;
        width: 100%;

        & > button {
            width: 100%;
            height: 56px;
        }
    }

    &-icon {
        width: 120px;
        height: 120px;
        margin: 12px;
        object-fit: contain;
    }

    &-wrapper {
        z-index: 999999995;

        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;

        background-color: rgba(0, 0, 0, 0.65);

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px;
        box-sizing: border-box;

        opacity: 0;
        pointer-events: none;

        &.visible {
            opacity: 1 !important;
            pointer-events: all !important;
        }
    }
}
</style>
